import React from 'react'
import OTPVerificationSignup from '../../components/OTPVerification/OTPVerificationSignup'
import PasswordReset from '../../components/PasswordReset/PasswordReset'

export default function PasswordResetScreen() {
    return (
        <div className="flex flex-col justify-center h-screen items-center bg-gradient-to-br from-blue-900 via-primary_color to-gray-900 text-white">
            {/* <div className="flex text-2xl m-4">
                <img
                    className="h-6 w-auto sm:h-10 mr-2"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt=""
                />
                <span className="font-extrabold">IVR</span>
            </div> */}
            <PasswordReset />
        </div>
    )
}
