import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { NodeTypes } from "../IVRDesignerScreen";
import MenuForm from "./MenuForm";
import InformationForm from "./InformationForm";
import ChangeLanguageForm from "./ChangeLanguageForm";
import ConfirmationForm from "./ConfirmationForm";
import GreetingForm from "./GreetingForm";
import RecordForm from "./RecordForm";
import TransferForm from "./TransferForm";
import ListenMenuAgain from "./ListenMenuAgain";
import ConferenceForm from "./ConferenceForm";
import BackForm from "./BackForm";

const { Option } = Select;

const IVRDesignForm = (props) => {
  const [type, setType] = useState();

  useEffect(() => {
    if (props.edit) {
      setType(props.type);
    }
    if (props.viewOnly) {
      console.log(props.currentNode);
      setType(props.currentNode.value.type);
    }
  }, [props.edit, props.viewOnly]);

  const displayTypeMenus = () => {
    switch (props.type) {
      case NodeTypes.greeting: {
        return (
          <>
            <Option value={NodeTypes.menu}>Menu</Option>
            <Option value={NodeTypes.info}>Information</Option>
            {props.isMultiLingual && (
              <Option value={NodeTypes.changeLanguage}>Change Language</Option>
            )}
            <Option value={NodeTypes.confirmation}>Confirmation</Option>
            <Option value={NodeTypes.conference}>Conference</Option>
          </>
        );
      }
      case NodeTypes.menu: {
        return (
          <>
            <Option value={NodeTypes.menu}>Menu</Option>
            <Option value={NodeTypes.info}>Information</Option>
            {props.isMultiLingual && (
              <Option value={NodeTypes.changeLanguage}>Change Language</Option>
            )}
            {/* <Option value={NodeTypes.confirmation}>Confirmation</Option> */}
            <Option value={NodeTypes.record}>Record</Option>
            <Option value={NodeTypes.transfer}>Transfer</Option>
            <Option value={NodeTypes.listenMenuAgain}>Listen Menu Again</Option>
            <Option value={NodeTypes.conference}>Conference</Option>
            <Option value={NodeTypes.back}>Back</Option>
          </>
        );
      }
      case NodeTypes.changeLanguage:
        return <Option value={NodeTypes.confirmation}>Confirmation</Option>;
    }

    return (
      <>
        <Option value={NodeTypes.menu}>Menu</Option>
        <Option value={NodeTypes.greeting}>Greetings</Option>
        <Option value={NodeTypes.record}>Record</Option>
        <Option value={NodeTypes.transfer}>Transfer</Option>
        <Option value={NodeTypes.conference}>Conference</Option>
      </>
    );
  };

  const displayForm = () => {
    switch (type) {
      case NodeTypes.greeting:
        return (
          <GreetingForm
            viewOnly={props.viewOnly}
            isMultiLingual={props.isMultiLingual}
            setIsMultilingual={props.setIsMultilingual}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
      case NodeTypes.menu:
        return (
          <MenuForm
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
      case NodeTypes.info:
        return (
          <InformationForm
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
      case NodeTypes.changeLanguage:
        return (
          <ChangeLanguageForm
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
      case NodeTypes.confirmation:
        return (
          <ConfirmationForm
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );

      case NodeTypes.record:
        return (
          <RecordForm
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
      case NodeTypes.transfer:
        return (
          <TransferForm
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
      case NodeTypes.listenMenuAgain:
        return (
          <ListenMenuAgain
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
      case NodeTypes.conference:
        return (
          <ConferenceForm
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
      case NodeTypes.back:
        return (
          <BackForm
            viewOnly={props.viewOnly}
            edit={props.edit}
            parentNode={props.parentNode}
            currentNode={props.currentNode}
            onSubmitHandler={props.onSubmitHandler}
          />
        );
    }
    return null;
  };
  return (
    <div>
      {!props.edit && (
        <Select
          disabled={props.viewOnly}
          className="w-full"
          value={type}
          placeholder="Select Option"
          onChange={(value) => {
            setType(value);
          }}
        >
          {displayTypeMenus()}
        </Select>
      )}
      {displayForm()}
    </div>
  );
};

export default IVRDesignForm;
