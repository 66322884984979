import React from "react";
import { WeekDays } from "./common";

const WeekDaySelector = ({ selectedDays, onToggleSelect, disabled }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {WeekDays.map((day) => {
        return (
          <div
            onClick={() => {
              if (disabled) return;
              onToggleSelect(day);
            }}
            className={`text-sm hover:cursor-pointer  border px-3 py-1 ${
              selectedDays.includes(day) && "bg-gray-200"
            }`}
          >
            {day}
          </div>
        );
      })}
    </div>
  );
};

export default WeekDaySelector;
