import { Modal } from 'antd'
import React from 'react'

const TaskDisbaledModal = ({ open, setOpen }) => {
    return (
        <div>
            <Modal  open={open} centered footer={false} onCancel={() => setOpen(false)} >
                <div className="flex items-center justify-center py-20 w-[100%]">
                    <div className="flex flex-col items-center">
                        <div className="font-bold text-xl">This Feature Is Disbaled. </div>
                        <div>Please contact support</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default TaskDisbaledModal