// import { UserOutlined } from '@ant-design/icons'
import { Button, Divider, Dropdown, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
// import PropTypes from 'prop-types'
import { useHistory } from "react-router";
import {
  FaChartBar,
  FaCodepen,
  // FaPoll,
  FaPowerOff,
  // FaTree,
  FaUserCircle,
  FaUsers,
  FaBars,
  FaTimesCircle,
  FaMoon,
  FaFileAudio,
} from "react-icons/fa";
import { Link,  useLocation } from "react-router-dom";
import { Switch } from 'antd';  
import NavContext from "../../contexts/NavContext";
import ListBorder from "../ListBorder/ListBorder";
import ThemeContext from "../../contexts/ThemeContext";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { RiLockPasswordLine } from "react-icons/ri";

function AdminHeader(props) {
  const location = useLocation();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const [activeMenu, setActiveMenu] = useState(location.pathname);
  const { navValue, setNavValue } = useContext(NavContext);
  const { theme, setTheme } = useContext(ThemeContext);

  // console.log("user name ; ", user)

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location.pathname]);

  const mainMenu = (
    <Menu
      defaultSelectedKeys={[activeMenu]}
      mode="vertical"
      theme="light"
      className="w-screen my-6 px-4  bg-blue-100 text-md"
    >
      <Menu.Item
        className="text-lg"
        key="1"
        icon={<FaChartBar className="inline mr-4" />}
      >
        <Link to="/admin"> Dashboard </Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="3"
        icon={<FaUsers className="inline mr-4" />}
      >
        <Link to="/admin/users">Companies</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="4"
        icon={<BsFillPersonLinesFill className="inline mr-4" />}
      >
        <Link to="/admin/waiting-users">Waiting Companies</Link>
      </Menu.Item>
      <Menu.Item
        className="text-lg"
        key="4"
        icon={<BsFillPersonLinesFill className="inline mr-4" />}
      >
        <Link to="/admin/Deactivate-users">Deactivated Companies</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="2"
        icon={<FaCodepen className="inline mr-4" />}
      >
        <Link to="/admin/short_codes"> Short Codes </Link>
      </Menu.Item>
      {/* 
      <Menu.Item className="text-lg" key="3" icon={<FaTree className="inline mr-4" />}>
            <Link to="/rating">IVR Trees</Link>
        </Menu.Item> 
      */}

      {/*
      <Menu.Item className="text-lg" key="/admin/poll" icon={<FaPoll className="inline mr-4" />}>
        <Link to="/admin/poll">Manage Poll</Link>
      </Menu.Item> 
      */}

      <Menu.Item
        className="text-lg"
        onClick={() => {
          history.push("/admin/change_password");
        }}
        key="2"
      >
        Change Password
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        onClick={() => {
          localStorage.clear("user");
          history.push("/login");
        }}
        key="1"
      >
        Log Out
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu>
      <Menu.Item
        className="text-md"
        onClick={() => {
          history.push("/admin/change_password");
          console.log("clicked");
        }}
        key="2"
        icon={<RiLockPasswordLine className="inline mr-4" />}
      >
        Change Password
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          localStorage.clear("user");
          history.push("/login");
        }}
        key="1"
        icon={<FaPowerOff className="inline mr-4" />}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={`h-full self-stretch`}>
      <div
        className={`dark:bg-primary_color  w-full px-4 sm:px-6 ${
          theme === "dark" ? "border-b border-gray-600" : "shadow-xl"
        } bg-gradient-to-l`}
      >
        <div className="flex justify-between items-center  py-6 md:justify-start md:space-x-10">
          <div className="flex items-center justify-start lg:w-0 lg:flex-1">
            <a href="/">
              {/* <span className="sr-only">Workflow</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                alt=""
              /> */}
            </a>{" "}
            <h1 className="text-primary_color dark:text-white text-xl uppercase ml-4 font-bold">
              YEGNATELE
            </h1>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Dropdown trigger={["click"]} overlay={mainMenu}>
              <Button
                className="rounded-md p-2 inline-flex items-center justify-center text-primary_color hover:text-blueGray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded="false"
              >
                <span className="sr-only">Open menu</span>
                <FaBars className="text-xl" />
              </Button>
            </Dropdown>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                aria-pressed={true}
                aria-haspopup="menu"
                type="text"
                className="flex focus:border-primary_color dark:border-primary_color dark:focus:border-white focus:border whitespace-nowrap text-base font-medium text-primary_color dark:text-white hover:text-blueGray-500 dark:hover:text-blueGray-300"
              >
                {user.user.name} <FaUserCircle className="text-2xl ml-4" />
              </Button>
            </Dropdown>

            <div className="flex items-center p-1 ">
              <FaMoon className="mr-1 text-lg dark:text-white" />
              {/* <Switch
                onClick={(value) => {
                  if (value) setTheme('dark')
                  else setTheme('light')
                }}
                checkedChildren="On"
                unCheckedChildren="Off"
                defaultChecked={theme === 'dark' ? true : false}
              /> */}
              <Switch
                onClick={(value) => {
                  if (value) setTheme("dark");
                  else setTheme("light");
                }}
                checkedChildren="On"
                unCheckedChildren="Off"
                defaultChecked={theme === "dark"}
                style={{ border: "1px solid blue" }} // Temporary border for debugging
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`bg-white dark:bg-primary_color absolute h-screenmd hidden md:block ${
          theme === "dark" ? "shadow-xlw" : "shadow-xl"
        }`}
      >
        <ul
          className={`${
            navValue.navOpen ? "w-64" : "w-16"
          } self-stretch  h-full min-h-screenlg py-4  text-sm text-primary_color dark:text-white transition-all duration-150`}
        >
          <div className="mx-6 h-8 pt-2 text-xl uppercase font-bold">
            {navValue.navOpen ? (
              <span className="flex items-center">
                Dashboard{" "}
                <FaTimesCircle
                  onClick={() => setNavValue({ navOpen: false })}
                  className="ml-4 cursor-pointer hover:text-blueGray-500"
                />
              </span>
            ) : (
              <FaBars
                title="Open Side Bar"
                className="cursor-pointer hover:text-blueGray-500"
                onClick={() => setNavValue({ navOpen: true })}
              />
            )}
          </div>

          <Divider className="bg-gray-300 h mx-auto" />
          <Link
            to="/admin"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === "/admin"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
            }`}
          >
            {activeMenu === "/admin" && <ListBorder theme={theme} type="top" />}
            <div className="flex items-center">
              <FaChartBar title="Dashboard" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Dashboard"}
            </div>
            {activeMenu === "/admin" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/admin/users"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === "/admin/users"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
            }`}
          >
            {activeMenu === "/admin/users" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaUsers title="Users" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Companies"}
            </div>
            {activeMenu === "/admin/users" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/admin/waiting-users"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === "/admin/waiting-users"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
            }`}
          >
            {activeMenu === "/admin/waiting-users" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaUsers title="Users" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Waiting Companies"}
            </div>
            {activeMenu === "/admin/waiting-users" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/admin/Deactivate-users"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === "/admin/Deactivate-users"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
            }`}
          >
            {activeMenu === "/admin/Deactivate-users" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaUsers title="Users" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Deactivated Companies"}
            </div>
            {activeMenu === "/admin/Deactivate-users" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/admin/short_codes"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === "/admin/short_codes"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
            }`}
          >
            {activeMenu === "/admin/short_codes" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaCodepen title="Short Codes" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Short Code"}
            </div>
            {activeMenu === "/admin/short_codes" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          {/* 
            <Link
              to="/admin/users"
              className={`list_item relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
                activeMenu === '/admin/users'
                  ? 'bg-white hover:text-gray-600 text-gray-500'
                  : 'hover:text-gray-200'
              }`}
            >
              {activeMenu === '/admin/users' && <b className="list_before"></b>}
              <div className="flex items-center">
                <FaUsers title="Users" className="mr-4 text-xl" />{' '}
                {navValue.navOpen && 'Users'}
              </div>
              {activeMenu === '/admin/users' && <b className="list_after"></b>}
            </Link> 
          */}
          <Divider className="bg-gray-300 h mx-auto" />
          <Link
            to=""
            onClick={() => {
              localStorage.removeItem("user");
              history.push("/login");
            }}
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === "/login"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
            }`}
          >
            {activeMenu === "/login" && <ListBorder theme={theme} type="top" />}
            <div className="flex items-center">
              <FaPowerOff title="Sign Out" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Sign Out"}
            </div>
            {activeMenu === "/login" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>
          {/* <Link
            to="/admin/audio_files"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === '/admin/audio_files'
                ? 'bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color'
                : 'hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white'
            }`}
          >
            {activeMenu === '/admin/audio_files' && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaFileAudio title="Audio Files" className="mr-4 text-xl" />{' '}
              {navValue.navOpen && 'Audio Files'}
            </div>
            {activeMenu === '/admin/audio_files' && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}
        </ul>
      </div>
    </div>
  );
}

AdminHeader.propTypes = {};

export default AdminHeader;
