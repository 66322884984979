import styled, { css } from 'styled-components'

const ListBorder = styled.b`
  left: 0;
  right: 0;
  position: absolute;
  background-color: #131d34;
  ${(props) =>
    props.theme === 'dark' &&
    css`
      background-color: rgba(212, 212, 216);
    `}
  display: block;
  height: 15px;
  width: 100%;
  transition: width 1.5s;

  ${(props) =>
    props.type === 'top' &&
    css`
      top: -15px;
    `}

  ${(props) =>
    props.type === 'bottom' &&
    css`
      bottom: -15px;
    `}

    ::before {
    content: '';
    height: 15px;
    background-color: white;
    ${(props) =>
      props.theme === 'dark' &&
      css`
        background-color: #131d34;
      `}
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: width 1.5s;
  }

  ${(props) =>
    props.type === 'top' &&
    css`
      ::before {
        border-bottom-right-radius: 15px;
      }
    `}

  ${(props) =>
    props.type === 'bottom' &&
    css`
      ::before {
        border-top-right-radius: 15px;
      }
    `}
`

export default ListBorder
