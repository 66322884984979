import React, { useState,useEffect } from 'react'
import { Button, Input, Form, Spin } from 'antd'
import { login } from '../../services/userService'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import dot from '../../assets/img/dots.svg'
import phone from '../../assets/img/phone.svg'
import { LoadingOutlined } from '@ant-design/icons';
import Particle from "../Particle";
import Footer from '../Footer/Footer'
function Login(props) {

  const history = useHistory()
  const [error, setError] = useState('')
  const [codeVerified, setCodeVerified] = useState(false)
  const [loading, setLoading] = useState(false)


  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;

  useEffect(()=>{
    const user = localStorage.getItem("user")
    if(user){
      const _user = JSON.parse(user)
      console.log("user from login page",_user)
      const _role = _user?.user?.role
      if (_role === 'IVR_ADMIN') {
        console.log("role admin")
        history.push('/admin')
        setLoading(false)
        return;
      }
      if (_role === "IVR_USER") {
        console.log("role user")
        history.push('/dashboard')
        setLoading(false)
        return;
      }
    }
  },[])

  

  const onFinish = (values) => {

    setLoading(true)
    const { email, password } = values

    login({ email, password })
      .then((res) => {

        const _codeVerified = res.data?.user?.isPhoneVerified
        const _phoneNumber = res.data?.user?.phoneNumber
        const _status = res.data?.user?.status
        const userId = res.data?.user?.id
        const success = res.data?.success
        const error = res.data?.error
        const _role = res.data?.user?.role

        console.log('user role: ', _role)

        if (success) {
          localStorage.setItem('user', JSON.stringify(res.data))
          if (_role === 'IVR_ADMIN') {
            console.log("role admin")
            history.push('/admin')
            setLoading(false)
            return;
          }
          if (_role === "IVR_USER") {
            console.log("role user")
            history.push('/dashboard')
            setLoading(false)
            return;
          }

        } else {
          const isUser = Boolean(res.data?.user)
          if(error && !isUser){
            setError(error)
            setLoading(false)
            return
          }
          else if (!_codeVerified && isUser) {
            const params = new URLSearchParams()
            params.append('email', values.email)
            params.append('phoneNumber', _phoneNumber)
            params.append("id", userId)
            history.push({
              pathname: '/signupVerification',
              search: params.toString()
            })
            setLoading(false)
          } else if ((_status==="UNAPPROVED" || _status==="DEACTIVATED")) {
            setError(error)
            setLoading(false)
          } else {
            setError(error)
            setLoading(false)
          }
          setLoading(false)
        }
      })
    // .catch((err) => {
    //   if (err && err.response && err.response.data.message) {
    //     setError(err.response.data.message)
    //   }
    //   setLoading(false)
    // })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    setError(errorInfo)
    setLoading(false)
  }

  return (
    <>
      <div className="flex justify-between dark:bg-primary_color fixed h-screen w-full top-0">
        <div className="relative w-2/3  h-full hidden lg:flex flex-col justify-between p-8 text-white">

          <div className='absolute top-1/3'>
            <Particle />
            <header className="mx-2 my-4">
              <span className="text-2xl text-white opacity-80">YEGNATELE</span>
              <h1 className="text-4xl font-extrabold  text-white">
                Bulk SMS and IVR Service Provider
              </h1>
            </header>
            <div className="absolute top-64 -left-10 opacity-40">
              <img className="w-32" alt="pattern" src={dot} />
            </div>

            {/* <div className='w-full h-48  flex gap-3'>
              <div className='bg-white w-1/2 h-full flex flex-col items-center justify-center gap-4 p-4 rounded-md'>
                   <h1 className='text-2xl text-bold'>Bulk SMS Service</h1>
                   <h1 className='text-md'>Send promotional and transactional SMS to 
                    your customers with our reliable and affordable bulk SMS service.
                    </h1>
              </div>
              <div className='bg-white w-1/2 h-full flex flex-col items-center justify-center gap-4 p-4 rounded-md'>
                   <h1 className='text-2xl text-bold'>IVR Service</h1>
                   <h1 className='text-md'>
                    Create interactive voice response applications for your 
                   business with our easy-to-use and scalable IVR service.
                    </h1>
              </div>
          </div> */}

            <div className="absolute top-80 left-1/2 opacity-40">
              <img className="w-32" alt="pattern" src={dot} />
            </div>


          </div>

          <div className="h-24 w-40 absolute right-6 top-0 -mt-2">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#ADD8E6"
                d="M66.4,-52.7C81.7,-33.7,86.8,-6.3,80.8,17.8C74.7,41.9,57.5,62.7,35.7,72.7C13.9,82.6,-12.5,81.6,-34.4,71.2C-56.4,60.7,-73.9,40.8,-79.9,17.4C-86,-6.1,-80.6,-33,-65.4,-52C-50.1,-70.9,-25.1,-81.8,0.2,-82C25.5,-82.2,51,-71.6,66.4,-52.7Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
          <div className="h-24 w-24 absolute right-40 top-32 ">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="
              #3B82F6
              "
                d="M66.4,-52.7C81.7,-33.7,86.8,-6.3,80.8,17.8C74.7,41.9,57.5,62.7,35.7,72.7C13.9,82.6,-12.5,81.6,-34.4,71.2C-56.4,60.7,-73.9,40.8,-79.9,17.4C-86,-6.1,-80.6,-33,-65.4,-52C-50.1,-70.9,-25.1,-81.8,0.2,-82C25.5,-82.2,51,-71.6,66.4,-52.7Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
          <div className="h-16 w-12 absolute right-1/2 top-2/3 ">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#19BDFA"
                d="M66.4,-52.7C81.7,-33.7,86.8,-6.3,80.8,17.8C74.7,41.9,57.5,62.7,35.7,72.7C13.9,82.6,-12.5,81.6,-34.4,71.2C-56.4,60.7,-73.9,40.8,-79.9,17.4C-86,-6.1,-80.6,-33,-65.4,-52C-50.1,-70.9,-25.1,-81.8,0.2,-82C25.5,-82.2,51,-71.6,66.4,-52.7Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>
        </div>

        <div className="bg-white dark:bg-primary_color w-full min-h-screen flex flex-col max-w-xl  mx-auto ">
        
        
          <div className="pt-16 md:px-16 flex justify-center items-center ">
            <div className="text-2xl text-gray-700 dark:text-gray-300 ">
              <span className='text-gray-700 dark:text-white mr-2'>

              Welcome To 
              </span>
              <span 
              // onClick={()=>{history.push("/home")}}
               className="cursor-pointer text-blue-500 dark:text-gray-300 font-bold ">
                YEGNATELE
              </span>
            </div>



          </div>
          
          {error && <span className='mx-16 px-4 py-1 bg-red-300 text-red-900 font-bold rounded-sm'>{error}</span>}
         <div className='w-full flex justify-center items-center'>

          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className=" md:w-3/4 px-16 py-8 dark:text-white"
          >
            <Form.Item
              label="E-mail Address"
              name="email"
              className="dark:text-white"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder="email@example.com" className=''/>
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              className='pass-input '
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Password" 
              className='pass-input'
              
              />
            </Form.Item>
            <div className="flex justify-between">
              <Form.Item className="text-center">
                <Button className="w-full bg-primary_color border-none p-4  text-center flex items-center justify-center" type="primary" htmlType="submit">
                  <span >
                    {loading && <Spin indicator={antIcon} className="mr-2" />}
                    Login
                  </span>
                </Button>
              </Form.Item>

              <div
                onClick={(e) => history.push('/forgot')}
                className="text-blue-500 cursor-pointer"
              >
                Forgot password?
              </div>
            </div>
          </Form>
         </div>
          <div className="flex p-14 bg-gray-200 dark:bg-primary_color ">
            <h1 className="dark:text-gray-300 ">Still without account? </h1>
            <Link to={'/signup'} className="text-blue-600  mx-2">
              {' '}
              Create Account
            </Link>
          </div>
        </div>

      </div>
    </>
  )
}

Login.propTypes = {}

export default Login
