import { Button, Checkbox, Divider, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { NodeTypes } from "../IVRDesignerScreen";
import { RemoveIcon } from "./icons";
import KeyAssigningForm from "./KeyAssigningForm";
import {
  InformationMenus,
  KeyValues,
  NavigationKeyOnMenu,
  RenderNavigationKeyOnMenu,
  displayMenuItems,
  getInformationMenuLabel,
} from "./common";
import { validateFileName } from "../../../_helpers/validateFileName";

const { Option } = Select;
const InformationForm = (props) => {
  const [form] = Form.useForm();
  const [informationList, setInformationList] = useState([]);
  const [validateInformation, setValidateInformation] = useState(false);
  const [hasMenuAudio, setHasMenuAudio] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if ((props.edit || props.viewOnly) && props.currentNode) {
      const currentNode = props.currentNode;
      form.setFieldValue("title", currentNode.value.title);
      form.setFieldValue("infoMenuAudio", currentNode.value.infoMenuAudio);
      form.setFieldValue("infoMenuScript", currentNode.value.infoMenuScript);
      console.log("...........");
      if (currentNode?.value?.infoMenuValues?.length > 0) {
        setHasMenuAudio(true);
      }
      form.setFieldValue("infoAudio", currentNode.value.infoAudio);
      form.setFieldValue("infoScript", currentNode.value.infoScript);
      form.setFieldValue("isKeyInterupted", currentNode.value.isKeyInterupted);
      if (currentNode.value.menuKey) {
        form.setFieldValue("menuKey", currentNode.value.menuKey);
      }
      if (currentNode.value.nextId) {
        form.setFieldValue("nextId", currentNode.value.nextId);
      }
      setInformationList(currentNode.value.infoMenuValues);
    }
  }, [props.edit, props.viewOnly]);

  const onAddInformationHandler = async () => {
    try {
      setValidateInformation(true);
      const response = await form.validateFields(["key", "option"]);
      if (response.errorFields && response.errorFields.length > 0) return;

      const key = form.getFieldValue("key");
      const menuOption = form.getFieldValue("option");
      const data = {
        id: Date.now(),
        key,
        value: menuOption,
      };
      //check if key not duplicated
      const index = informationList.findIndex((value) => {
        return value.key == key;
      });
      if (index !== -1) return;
      setInformationList([...informationList, data]);
      form.setFieldValue("key", "");
      form.setFieldValue("option", "");
    } catch (error) {}
  };
  const _validateInfoLength = () => {
    if (hasMenuAudio) {
      if (informationList.length == 0) {
        return "Atleast one menu is required";
      }
    }
  };
  const onInformationSubmited = (values) => {
    if (_validateInfoLength()) return;

    if(props.edit && props.parentNode && props.parentNode.value.type == NodeTypes.menu){
      const childrenValues = props.parentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.id != props.currentNode.key && item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }
    if(props.currentNode && props.currentNode.value.type == NodeTypes.menu){
      const childrenValues = props.currentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }


    let data = {};
    if (props.edit && props.currentNode) {
      data = props.currentNode.value;
    }
    data.title = values.title;
    data.isKeyInterupted = values.isKeyInterupted;
    data.type = NodeTypes.info;
    data.infoAudio = values.infoAudio;
    data.infoScript = values.infoScript;

    data.infoMenu = [];
    data.infoMenuValues = [];
    data.hasMenuOption = hasMenuAudio;

    data.infoMenuAudio = values.infoMenuAudio;
    data.infoMenuScript = values.infoMenuScript;

    informationList.forEach((information) => {
      data.infoMenu.push(information.key);
      data.infoMenuValues.push(information);
    });
    data.infoMenu = `[${data.infoMenu}]`
    data.menuKey = values.menuKey;
    data.menuOption = "NEXT";

    const node = {
      key: Date.now(),
      value: data,
      parent: null,
      children: [],
    };

    if (props.edit && props.currentNode) {
      node.parent = props.currentNode.parent;
      node.children = props.currentNode.children;
      node.key = props.currentNode.key;
    }
    props.onSubmitHandler(node);
  };
  const onReset = () => {
    form.resetFields();
    setInformationList([]);
  };

  const onRemoveInformation = (index) => {
    const _informationList = [...informationList];
    _informationList.splice(index, 1);
    setInformationList(_informationList);
  };

  const displayInformationForm = () => {
    const title = "Add Sub-Menu Options";
    const menus = InformationMenus;
    const menuRules = [
      {
        required: true,
        message: "Menu Option is required",
      },
    ];
    const menuLabel = "Menu Option";
    const menuName = "option";

    const keyRules = [
      {
        required: true,
        message: "Key is required",
      },
    ];
    const applyRules = validateInformation;
    const onAddHandler = onAddInformationHandler;

    return (
      <KeyAssigningForm
        title={title}
        menus={menus}
        menuRules={menuRules}
        menuLabel={menuLabel}
        menuName={menuName}
        keyRules={keyRules}
        applyRules={applyRules}
        onAddHandler={onAddHandler}
        viewOnly={props.viewOnly}
      />
    );
  };

  const renderMenuForm = () => {
    if (props.viewOnly) {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    if (!props.edit) {
      if (props.currentNode && props.currentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    } else {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    return null;
  };

  return (
    <div className="mt-5">
      <Form
        className=""
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onInformationSubmited}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              type: "string",
              message: "Title is required",
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>

        <Form.Item
          name="infoAudio"
          label="Info Audio"
          rules={[
            {
              required: true,
              type: "string",
              message: "Info audio is required",
            },
            {
              validator: validateFileName,
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item name="infoScript" label="Info Script (optional)">
          <Input.TextArea disabled={props.viewOnly} rows={5} />
        </Form.Item>
        {renderMenuForm()}
        <Form.Item name="nextId" label="Next ID (optional)">
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <div className="flex justify-between">
          <Form.Item
            name="isKeyInterupted"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox disabled={props.viewOnly}>Is Key Interupted ?</Checkbox>
          </Form.Item>
        </div>
        <div className="flex justify-between">
          <Form.Item>
            <Checkbox
              disabled={props.viewOnly}
              checked={hasMenuAudio}
              onChange={(e) => {
                if (!e.target.checked) {
                  form.setFieldValue("infoMenuAudio", "");
                  form.setFieldValue("infoMenuScript", "");
                  setInformationList([]);
                }
                setHasMenuAudio(e.target.checked);
              }}
            >
              Do you want to add menu audio ?
            </Checkbox>
          </Form.Item>
        </div>
        {hasMenuAudio && (
          <>
            {/* <div className="flex justify-between items-center mb-5">
              <div className="font-bold uppercase">Manage Info Menu Audios</div>
            </div>
            <Divider /> */}
            <Form.Item
              name="infoMenuAudio"
              label="Info Menu Audio"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Info menu audio is required",
                },
                {
                  validator: validateFileName,
                },
              ]}
            >
              <Input disabled={props.viewOnly} />
            </Form.Item>
            <Form.Item
              name="infoMenuScript"
              label="Info Menu Script (optional)"
            >
              <Input.TextArea disabled={props.viewOnly} rows={5} />
            </Form.Item>
            {displayInformationForm()}
            {displayMenuItems(
              informationList,
              onRemoveInformation,
              getInformationMenuLabel,
              props.viewOnly
            )}
            {isTouched && (
              <div className="text-red-500 my-3">{_validateInfoLength()}</div>
            )}
          </>
        )}
        <Form.Item className="flex justify-end mt-5">
          {!props.viewOnly && (
            <Button
              onClick={() => {
                setIsTouched(true);
                setValidateInformation(false);
              }}
              className="bg-slate-700 text-white"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default InformationForm;
