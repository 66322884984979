import { BrowserRouter, Route, Switch } from "react-router-dom";

import React, { Suspense, useEffect, useMemo, useState } from "react";
import LoginScreen from './screens/LoginScreen/LoginScreen';
import SignUpScreen from './screens/SignUpScreen/SignUpScreen';
import UserContext from "./contexts/UserContext";
import NavContext from "./contexts/NavContext";
import ThemeContext from "./contexts/ThemeContext";
import Footer from "./components/Footer/Footer";
import { getUserFromStorage } from "./services/userService";
import Routing from "./Routing";
import RoutingLayout from "./RoutingLayout";
import PageNotFoundScreen from "./screens/404/PageNotFoundScreen";
import OTPVerificationEmailScreen from "./screens/OTPVerificationScreen/OTPVerificationEmailScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen/ForgotPasswordScreen";
import UserApprovementScreen from "./screens/UserApprovementScreen/UserApprovementScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen/PasswordResetScreen";
import PasswordResetVerficationScreen from "./screens/PasswordResetVerficationScreen/PasswordResetVerficationScreen";
import OTPVerficationSignUpScreen from "./screens/OTPVerficationSignUpScreen/OTPVerficationSignUpScreen";
import RoutingLayoutAdmin from "./RoutingLayoutAdmin";
const App = () => {
  useEffect(() => {
    setUser(getUserFromStorage());
  }, []);
  const [user, setUser] = useState(null);
  const [navValue, setNavValue] = useState({
    navOpen:
      JSON.parse(localStorage.getItem("nav_open")) !== null
        ? JSON.parse(localStorage.getItem("nav_open"))
        : true,
  });
  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || null);
  const providerNavValue = useMemo(
    () => ({ navValue, setNavValue }),
    [navValue, setNavValue]
  );

  const providerThemeValue = useMemo(
    () => ({ theme, setTheme }),
    [theme, setTheme]
  );


  useEffect(() => {
    setThemeValue(theme);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem("nav_open", navValue.navOpen);
  }, [navValue]);

  const setThemeValue = (value = null) => {
    if (value) localStorage.setItem("theme", value);

    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  return (
    <div>
         {/* footer */}
         <Footer />
      {/* navigation */}
      <BrowserRouter>
        <UserContext.Provider value={providerValue}>
          <NavContext.Provider value={providerNavValue}>
            <ThemeContext.Provider value={providerThemeValue}>

              <Switch className="overflow-y-auto">
                <Route exact path="/login" component={LoginScreen} />
                <Route exact path="/signup" component={SignUpScreen} />
                <Route exact path="/" component={LoginScreen} />
                {/* <Routing/> */}
                <Route path="/forgot" exact component={ForgotPasswordScreen} />
                <Route
                    path="/verificationPhone"
                    exact
                    component={OTPVerificationEmailScreen}
                  />
                {/* <RoutingLayoutAdmin exact/> */}
                <Route
                    path="/userApprovement"
                    exact
                    component={UserApprovementScreen}
                  />

                  <Route
                    path="/new-password"
                    exact
                    component={PasswordResetScreen}
                  />

                  <Route
                    path="/verification"
                    exact
                    component={PasswordResetVerficationScreen}
                  />
                 <Route
                    path="/signupVerification"
                    exact
                    component={OTPVerficationSignUpScreen}
                  />
                <RoutingLayout />

                <Route path="*" exact component={PageNotFoundScreen} />

                <Footer />
              </Switch>

            </ThemeContext.Provider>
          </NavContext.Provider>
        </UserContext.Provider>
      </BrowserRouter>
      {theme === "dark" && (
        <div>
          <link rel="stylesheet" href="dark_theme_styles.css" />
        </div>
      )}
    </div>
  )
}

export default App