import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const addProfile = (body) => {
  return axios.post(API_URL + '/profile', body)
}

export const getProfile = (id) => {
  return axios.get(API_URL + `/profile`)
}

export const getProfiles = (pageIndex) => {
  return axios.get(API_URL + `/profile?pageIndex=${pageIndex}`)
}

export const approveComapnyAdmin = (body) => {
  return axios.put(API_URL + `/profile/approvement-company`,body)
}

export const changeCompanyChargeMethod = (body) => {
  return axios.put(API_URL + `/profile/change-charge-method`,body)
}

export const updateProfile = ( body) => {
  return axios.put(API_URL + `/profile`, body)
}

export const updateUserProfile = ( body) => {
  return axios.put(API_URL + `/profile/user`, body)
}


export const updateAPIKeyStatus = ( body) => {
  return axios.put(API_URL + `/profile/api-status`, body)
}

export const updateChangeTask = ( body) => {
  return axios.put(API_URL + `/profile/change-task`, body)
}
export const updateProfileAddress = (id, body) => {
  return axios.patch(API_URL + `/profile/address/${id}`, body)
}


