export const validatePhone = (phoneNumber) => {
    var phoneData = ""
    var errorMsg = ""
    if(phoneNumber.startsWith('0') && phoneNumber.length === 10){
        // eslint-disable-next-line no-unused-vars
        phoneData = phoneNumber
    }else if(phoneNumber.startsWith('251') ){
        phoneData = "0" + phoneNumber.slice(3, phoneNumber.length)
    }else if(phoneNumber.startsWith('9') && phoneNumber.length === 9){
        phoneData = "0" + phoneNumber
    }else{
        errorMsg = "Invalid Phone Number"
    }
    return [phoneData, errorMsg]
}