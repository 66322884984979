import React from 'react'
import ForgotPasswrod from '../../components/ForgotPassword/ForgotPassword'

export default function ForgotPasswordScreen() {
    return (
        <div className="flex flex-col justify-center h-screen items-center bg-gradient-to-r from-cyan-600 to-blue-600 text-white">
            <div className="flex text-2xl m-4">
                {/* <img
                    className="h-6 w-auto sm:h-10 mr-2"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt=""
                />
                <span className="font-extrabold">IVR</span> */}
            </div>
            <ForgotPasswrod />
        </div>
    )
}
