import React from 'react'

const Footer = () => {
  return (
    <div className='w-screen h-12 bg-[#131D34] flex justify-center items-center fixed bottom-0 z-50'> 
         <p className='text-white text-xs self-center text-center md:text-md'>@2018  YEGNATELE Bulk SMS and IVR Service Provider. All rights reserved.  | Phone:+251114701590 </p>

    </div>
  )
}

export default Footer