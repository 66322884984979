import React, { useState } from "react";
import { NodeTypes } from "../IVRDesignerScreen";
import { clone } from "lodash";

const useNode = (initialNode) => {
  const [data, setData] = useState(initialNode);
  const _addNode = (parents, node, parentId) => {
    if (parents.key == parentId) {
      if (!parents.children) {
        parents.children = [];
      }
      if (parents) {
        node.parent = parents.key;
      }
      if (parents.value.type == NodeTypes.greeting) {
        parents.value.nextNodeKey = node.key;
      }
      if (parents.value.type === NodeTypes.menu) {
        const key = node.value.menuKey;
        const option = node.value.menuOption;
        const id = node.key;
        if (!parents.value.childrenValues) {
          parents.value.childrenValues = [];
        }
        parents.value.childrenMenu = [];

        parents.value.childrenValues.push({ id, key, value: option });
        parents.value.childrenValues.forEach(value => {
          parents.value.childrenMenu.push(value.key);
        })
        parents.value.childrenMenu = `[${parents.value.childrenMenu}]`

      }

      parents.children.push(node);
      return;
    }
    if (parents.children) {
      for (let parent of parents.children) {
        _addNode(parent, node, parentId);
      }
    }
  };

  const _removeNode = (parent, nodeId) => {
    if (parent.children) {
      for (let i = 0; i < parent.children.length; i++) {
        const child = parent.children[i];

        if (child.key == nodeId) {
          if (parent.value.type === NodeTypes.menu) {
            //remove child menus from parent
           
            parent.value.childrenValues = parent.value.childrenValues.filter(
              (value) => {
                return value.key != child.value.menuKey;
              }
            );
            parent.value.childrenMenu = [];
            parent.value.childrenValues.forEach(value => {
              parent.value.childrenMenu.push(value.key);
            })
            parent.value.childrenMenu = `[${parent.value.childrenMenu}]`
            console.log("deleted", parent)
          }

          parent.children.splice(i, 1);
          return;
        }
        _removeNode(child, nodeId);
      }
    }
  };

  const _checkIfMenuKeyExist = (parent, menuKey, parentId) => {
    if (parent.key === parentId) {
      if (!parent.value.childrenMenu) {
        return false;
      } else {
        return parent.value.childrenMenu.includes(menuKey);
      }
    }
    if (parent.children) {
      for (let child of parent.children) {
        return _checkIfMenuKeyExist(child, menuKey, parentId);
      }
    }
    return false;
  };

  const _updateNode = (parents, node) => {
    if (parents.key === node.key) {
      parents.value = node.value;
      return;
    }
    if (parents.children) {
      for (let child of parents.children) {
        _updateNode(child, node);
      }
    }
  };
  const _getParentNode = (parents, nodeId, setParent) => {
    if (parents.children) {
      for (let child of parents.children) {
        if (child.key == nodeId) {
          setParent(parents);
          return parents;
        }
        _getParentNode(child, nodeId, setParent);
      }
    }
  };

  const addNode = (node, parentId) => {
    const newData = clone(data);
    _addNode(newData, node, parentId);
    setData(newData);
  };
  const removeNode = (nodeId) => {
    const newData = clone(data);
    _removeNode(newData, nodeId);
    setData(newData);
  };
  const updateNode = (node) => {
    const newData = clone(data);
    _updateNode(newData, node);
    setData(newData);
  };
  const getParentNode = (nodeId) => {
    const newData = clone(data);
    let _parent = null;
    _getParentNode(newData, nodeId, (parent) => {
      console.log("parent", parent);
      _parent = parent;
    });
    console.log(_parent);
    return _parent;
  };

  const checkIfMenuKeyExist = (menuKey, parentId) => {
    const newData = clone(data);
    return _checkIfMenuKeyExist(newData, menuKey, parentId);
  };

  return {
    data,
    updateNode,
    addNode,
    removeNode,
    getParentNode,
    checkIfMenuKeyExist,
    setData,
  };
};

export default useNode;
