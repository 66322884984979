import React from 'react'
import Routing from './Routing'
import AuthHeader from './components/AuthHeader/AuthHeader'
import AdminHeader from './components/AdminHeader/AdminHeader'
import AdminRouting from './AdminRouting'

const RoutingLayoutAdmin = () => {
    return (
        <div>
            {/* <AuthHeader/>
        <Routing /> */}
            <AdminHeader />
            <AdminRouting />
            {/* <AdminHeader>
        <AdminRouting />
      </AdminHeader> */}


        </div>
    )
}

export default RoutingLayoutAdmin