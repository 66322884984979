import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const GuardedRoute = ({ component: Component, ...rest }) => {
  let _user = JSON.parse(localStorage.getItem('user'))
  const user = _user?.user
  console.log("user for ",user)
  

  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.role === "IVR_USER" ? (
          <Component {...props} />
        ) : user && user.role === 'IVR_ADMIN' ? (
          <Redirect to="/admin" />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default GuardedRoute