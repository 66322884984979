import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Select,
  Space,
  Table,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserMessages,
  getUserMessagesForAdmin,
} from "../../services/messageService";

import { validatePhone } from "../../_helpers/validatePhone.js";
import Loader from "../Loader/Loader";

const AdminSentMessage = () => {
  const [shortCode, setShortCode] = useState([]);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [autoReply, setAutoReply] = useState(false);
  const [keyBased, setKeyBased] = useState(false);
  const [codeModalVisible, setCodeModalVisible] = useState(false);
  const [shortCodeData, setShortCodeData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);

  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  // const [searchDate, setSearchDate] = useState('')
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [messageStatus, setMessageStatus] = useState("");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 6,
  });
  const msgStatus = [
    {
      id: 1,
      status: "PENDING",
    },
    {
      id: 2,
      status: "DELIVERED",
    },
    {
      id: 3,
      status: "QUEUED",
    },
    {
      id: 4,
      status: "FAILED",
    },
    {
      id: 5,
      status: "UNDELIVERED",
    },
    {
      id: 6,
      status: "REJECTED",
    },
  ];

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { userId } = useParams();

  useEffect(() => {
    const params = {
      messageStatus,
      searchStartDate,
      searchEndDate,
      phoneNumber: searchPhoneNumber,
      current: pagination.current,
      pageSize: pagination.pageSize,
    };
    getMessage(params);
  }, [refresh, pagination.current, pagination.pageSize]);

  const getMessage = (params) => {
    // const status = ["ACTIVE", "PENDING", "INACTIVE"]
    setLoading(true);
    getUserMessagesForAdmin(params, userId).then((resp) => {
      console.log("resp", resp);
      setdata(resp?.data?.msgs);
      setPagination({ ...params, total: resp.data?.total });
      setLoading(false);
    });
  };

  const onSearch = (e) => {
    e.preventDefault();
    const params = {
      messageStatus,
      searchStartDate,
      searchEndDate,
      phoneNumber: searchPhoneNumber,
      current: pagination.current,
      pageSize: pagination.pageSize,
    };
    getMessage(params);
  };

  // const handleTableChange = (pagination) => {
  //   setTableParams({
  //     pagination
  //   })
  // }

  const handlePhoneChange = (e) => {
    const phoneNum = e.target.value;
    const [phoneData, errorMsg] = validatePhone(phoneNum);
    setSearchPhoneNumber(phoneData);
  };

  const handleTableChange = (pagination) => {
    setPagination((params) => ({
      ...params,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const handleDateChange = (value) => {
    setMessageStatus(value);
  };

  const columns = [
    {
      title: "PhoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Short Code",
      dataIndex: "shortCode",
      key: "shortCode",
    },
    {
      title: "Key Name",
      dataIndex: "keyName",
      key: "keyName",
    },
    {
      title: "Message Size",
      dataIndex: "msgLen",
      key: "msgLen",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          className={
            text === "PENDING"
              ? "text-yellow-500"
              : text === "DELIVERED"
              ? "text-green-500 font-bold"
              : "text-red-600"
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <span>{text && moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
      ),
    },
  ];

  // const columns = [
  //   {
  //     title: 'ShortCode',
  //     dataIndex: 'value',
  //     key: 'value',
  //     render: (text) => <a>{text}</a>,
  //   },
  //   {
  //     title: 'Key Name',
  //     dataIndex: 'key',
  //     key: 'key',
  //     render: (text) => <a>{text}</a>,
  //   },
  //   {
  //     title: 'Code Id',
  //     dataIndex: 'codeId',
  //     key: 'codeId',
  //     render: (text) => <a>{text}</a>,
  //   },

  //   // {
  //   //   title: 'status',
  //   //   dataIndex: 'status',
  //   //   key: 'status',
  //   //   render: (status) => (
  //   //     <span className='uppercase flex justify-center'>

  //   //       {status === "WAITING" ? "WATING FOR APPROVAL" : status}
  //   //     </span>
  //   //   ),
  //   // },
  //   {
  //     title: 'status',
  //     dataIndex: ['isActive', 'status'],
  //     key: 'isActive',
  //     render: (text, row) =>
  //       <a title={row["status"]}>
  //         {
  //           row["isActive"] === 1 ? (row["status"] === "WAITING" ? "WAITING FOR APPROVAL" : row["status"])
  //             : "SUSPEND"

  //         }
  //         {/* {row["status"]}  {String(row["isActive"])} */}

  //       </a>,
  //   },

  // ];

  return (
    <div className="w-full h-full flex flex-col  p-4">
      <h2 className="text-2xl text-gray-700 capitalize ml-10 mt-5 dark:text-white">
        Sent Message{" "}
      </h2>

      <div className="mt-8  ">
        <form
          onSubmit={onSearch}
          className=" flex md:flex-row flex-col justify-center md:items-center md:w-full  "
        >
          <div className="mr-2 w-full md:w-64">
            <div className="flex ml-1">
              <div className=" relative dark:bg-primary_color ">
                <div className="absolute inset-y-0 left-0 pl-1 flex items-center justify-center pointer-events-none">
                  <span className="text-sm pl-0.5 text-gray-600">+251</span>
                </div>
                <input
                  name="phoneNumber"
                  type="text"
                  placeholder="Phone Number"
                  onChange={handlePhoneChange}
                  className="bg-gray-50 border dark:bg-primary_color  border-gray-300 text-gray-900 sm:text-sm rounded-xl w-full pl-10 p-2 "
                />
              </div>
            </div>
          </div>

          <div className="mr-2 rounded-xl  w-full md:w-64 dark:bg-primary_color ">
            <Select
              label="Short Code"
              placeholder="Select Status"
              name="shortCode"
              className="border-1 rounded-xl  w-full md:w-64 py-4 dark:bg-primary_color "
              onChange={handleDateChange}
            >
              <Option value="" disabled className="text-red-200 ">
                Select Status
              </Option>
              {msgStatus.map((d, index) => (
                <Option key={index} value={d.status}>
                  {d.status}
                </Option>
              ))}
            </Select>
          </div>

          {/* <div className="mr-2  w-full md:w-64">
    <DatePicker
      className="border-2 rounded-xl p-1.5 w-full md:w-64"
      placeholderText="Start Date"
      selected={searchDate}
      onChange={(date) => setSearchDate(date)}
    />
  </div> */}
          <div className="md:mr-2">
            <DatePicker
              className="border-2 rounded-xl p-1.5 md:w-56 w-full dark:bg-primary_color "
              placeholder="Start Date"
              selected={searchStartDate}
              onChange={(date) => setSearchStartDate(date)}
            />
          </div>
          <div className="my-2">
            <DatePicker
              className="border-2 rounded-xl p-1.5 md:w-56 w-full dark:bg-primary_color "
              placeholder="End Date"
              selected={searchEndDate}
              onChange={(date) => setSearchEndDate(date)}
            />
          </div>

          <div className="m-2 mr-10">
            <button
              type="submit"
              className="text-white py-2 px-5 rounded-2xl outline-none w-full md:w-32"
              style={{
                backgroundColor: "#131D34",
                borderColor: "#131D34",
                borderRadius: "10px",
              }}
            >
              Search
            </button>
          </div>
        </form>
      </div>

      <div className="w-full h-full my-5 p-3">
        <Table
          columns={columns}
          dataSource={data}
          bordered
          loading={loading}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </div>
    </div>
  );
};

export default AdminSentMessage;
