import React, { useContext, useEffect, useState } from "react";
import NavContext from "../../contexts/NavContext";
import {
  Button,
  Checkbox,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Table,
  message,
} from "antd";
import {
  CheckIcon,
  MoreVertIcon,
  XMarkIcon,
} from "../IVRDesignerScreen/forms/icons";
import { useHistory, useParams } from "react-router-dom";
import {
  approveIVR,
  cloneIVR,
  closeIVR,
  deleteIVRHandler,
  getIVRsHandler,
} from "../../services/ivrService";
import { clone } from "lodash";
import { DeleteOutlined, EditOutlined, EyeFilled } from "@ant-design/icons";
import { MdOutlineFileUpload } from "react-icons/md";
import UploadAudios from "./UploadAudios/UploadAudios";

const IVRHomeScreen = (props) => {
  const { navValue } = useContext(NavContext);
  const { companyId } = useParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState(null);

  const navigator = useHistory();

  useEffect(() => {
    getIVRData();
  }, [page]);

  const getIVRData = () => {
    setLoading(true);
    getIVRsHandler(companyId, page, 10)
      .then((response) => {
        if (response.data.success) {
          const _data = response.data.data;
          console.log(_data.treeList);
          setTotal(_data.total);
          setData(_data.treeList);
        } else {
          message.error(response.data.error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openIVR = (id, action) => {
    approveIVR(id, action).then((response) => {
      const _response = response.data;
      if (_response.success) {
        // const _data = clone(data);
        // _data.forEach((element) => {
        //   if (element._id == id) {
        //     console.log(element)
        //     element.status = "CLOSE";
        //   }
        // });
        // setData(_data);
        getIVRData();
        message.success(_response.message);
      } else {
        message.error(_response.error);
      }
    });
  };

  const closeIVRHandler = (id) => {
    closeIVR(id).then((response) => {
      const _response = response.data;
      if (_response.success) {
        // const _data = clone(data);
        // _data.forEach((element) => {
        //   if (element._id == id) {
        //     element.status = "OPEN";
        //   }
        // });
        // setData(_data);
        getIVRData();
        message.success(_response.message);
      } else {
        message.error(_response.error);
      }
    });
  };

  const onDeleteHandler = (id) => {
    deleteIVRHandler(id)
      .then((response) => {
        if (response.data.success) {
          setData(data.filter((_data) => _data._id != id));
          setTotal(total - 1);
          message.success("Successfully Deleted");
        } else {
          message.error(response.data.error);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };
  const onCloneIVR = (id) => {
    cloneIVR(id)
      .then((response) => {
        if (response.data.success) {
          getIVRData();
        } else {
          message.error(response.data.error);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const columns = [
    {
      title: "Short Code",
      dataIndex: "incomingNumber",
      key: "incomingNumber",
    },
    {
      title: "Default Language",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "Is Multilingual",
      dataIndex: "isMultilingual",
      key: "isMultilingual",
      render: (_, record) => {
        return <div>{record.isMultilingual === "true" ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        const statusItem = () => {
          if (record.status == "PENDING") {
            return (
              <div className="w-20 ">
                <Popconfirm
                  title="Action Required"
                  description="Do you want to replace actives ivr with same short code or open a new a new one ?"
                  okText="Replace"
                  cancelText="Open New"
                  showCancel={true}
                  onConfirm={() => {
                    openIVR(record._id, "REPLACE");
                  }}
                  onCancel={() => {
                    openIVR(record._id, "NEW");
                  }}
                >
                  Approve
                </Popconfirm>
              </div>
            );
          }
          if (record.status == "OPEN") {
            return (
              <div
                className="w-20 "
                onClick={() => closeIVRHandler(record._id)}
              >
                Close
              </div>
            );
          }
        };
        const items = [];

        items.push({
          label: statusItem(),
          key: "status",
        });
        items.push({
          label: (
            <div className="w-20 " onClick={() => onCloneIVR(record._id)}>
              Clone
            </div>
          ),
          key: "clone",
        });
        if (record.status !== "PENDING") {
          items.push({
            label: (
              <div
                className="w-20 "
                onClick={() => {
                  navigator.push(`/ivr/${record._id}/ivr-design/view`);
                }}
              >
                View
              </div>
            ),
            key: "clone",
            icon: <EyeFilled size={16} />,
          });
        }
        if (record.status == "PENDING" || record.status == "OPEN") {
          items.push({
            label: (
              <div
                className="w-20 "
                onClick={() =>
                  navigator.push(`/admin/ivr/${record._id}/ivr-designer/edit`)
                }
              >
                Edit
              </div>
            ),
            key: "edit",
            icon: <EditOutlined size={16} />,
          });
          items.push({
            label: (
              <Popconfirm
                title="Delete the IVR"
                description="Are you sure to delete this ivr?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  onDeleteHandler(record._id);
                }}
              >
                <div className="w-20 ">Delete</div>
              </Popconfirm>
            ),
            key: "delete",
            icon: <DeleteOutlined size={16} />,
          });
        }

        items.push({
          label: (
            // <Popconfirm
            //   title="Delete the IVR"
            //   description="Are you sure to delete this ivr?"
            //   okText="Yes"
            //   cancelText="No"
            //   onConfirm={() => {
            //     onDeleteHandler(record._id);
            //   }}
            // >
            // </Popconfirm>
            <div className="w-36">Upload Audio</div>
          ),
          key: "delete",
          icon: <MdOutlineFileUpload size={16} />,
          onClick: () => {
            setRecord(record);
            setOpen(true);
          },
        });
        return (
          <div>
            <Dropdown
              trigger="click"
              arrow={{ pointAtCenter: true }}
              menu={{ items }}
              placement="bottomCenter"
            >
              <button>{MoreVertIcon}</button>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  // const data = [
  //   {
  //     key: "1",
  //     shortCode: "2060",
  //     isMultilingual: false,
  //     status: "PENDING",
  //   },
  // ];

  console.log(data);

  const onCancelHandler = () => {
    setRecord(null);
    setOpen(false);
  };
  return (
    <div className=" dark:bg-primary_color h-[105vh]"  >
      {open && (
        <Modal
          centered
          open={open}
          footer={false}
          title="Upload Audio"
          onCancel={onCancelHandler}
          width={"70%"}
        >
          <UploadAudios record={record} onCancelHandler={onCancelHandler} />
        </Modal>
      )}
      <div
        className={`  p-10 ${
          navValue.navOpen ? "md:ml-64" : "md:ml-16 "
        } overflow-x-auto`}
      >
        <h2 className="text-2xl text-gray-700 capitalize  mt-5 dark:text-white">IVR detail </h2>
        <div className="flex justify-end">
          <Button
            onClick={() => {
              if (!companyId) return;
              navigator.push(`/admin/ivr/${companyId}/ivr-designer/add`);
            }}
            className="dark:bg-primary_color dark:text-white"
          >
            Add IVR
          </Button>
        </div>
        <Table
          className="mt-5"
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            current: page,
            total: total,
            onChange: (page) => {
              setPage(page);
            },
          }}
          bordered
        />
      </div>
    </div>
  );
};

export default IVRHomeScreen;
