import React from 'react'
import { FaCentercode, FaPoll } from 'react-icons/fa'
import { BsStarHalf } from 'react-icons/bs'
import { AiOutlineUsergroupAdd, AiTwotoneProfile } from 'react-icons/ai'
import { FiLink2 } from 'react-icons/fi'
import Text from 'antd/lib/typography/Text'
import { Link } from 'react-router-dom'

function AuthHome(props) {
  return (
    <div className="flex flex-wrap p-4" style={{ color: '#4F46E5' }}>
      <Link
        to="/short_codes"
        className="w-1/5 p-8 m-2 border-purple-200 border-2 hover:border-purple-300 cursor-pointer flex flex-col items-center justify-center"
      >
        <FaCentercode className="text-5xl" />
        <Text className="text-gray-400 dark:text-gray-300 text-sm mt-2">
          Short Codes
        </Text>
      </Link>
      <Link
        to="/rating"
        className="w-1/5 p-8 m-2 border-purple-200 border-2 hover:border-purple-300 cursor-pointer flex flex-col items-center justify-center"
      >
        <BsStarHalf className="text-5xl" />
        <Text className="text-gray-400 dark:text-gray-300 text-sm mt-2">
          Ratings
        </Text>
      </Link>
     

      <Link
        to="/redirect"
        className="w-1/5 p-8 m-2 border-purple-200 border-2 hover:border-purple-300 cursor-pointer flex flex-col items-center justify-center"
      >
        <FiLink2 className="text-5xl" />
        <Text className="text-gray-400 dark:text-gray-300 text-sm mt-2">
          Redirect
        </Text>
      </Link>
      <Link
        to="/conference"
        className="w-1/5 p-8 m-2 border-purple-200 border-2 hover:border-purple-300 cursor-pointer flex flex-col items-center justify-center"
      >
        <AiOutlineUsergroupAdd className="text-5xl" />
        <Text className="text-gray-400 dark:text-gray-300 text-sm mt-2">
          Conference
        </Text>
      </Link>
      <Link
        to="/poll"
        className="w-1/5 p-8 m-2 border-purple-200 border-2 hover:border-purple-300 cursor-pointer flex flex-col items-center justify-center"
      >
        <FaPoll className="text-5xl" />
        <Text className="text-gray-400 dark:text-gray-300 text-sm mt-2">
          Poll
        </Text>
      </Link>
      <Link
        to="/profile"
        className="w-1/5 p-8 m-2 border-purple-200 border-2 hover:border-purple-300 cursor-pointer flex flex-col items-center justify-center"
      >
        <AiTwotoneProfile className="text-5xl" />
        <Text className="text-gray-400 dark:text-gray-300 text-sm mt-2">
          Profile
        </Text>
      </Link>
      <div className="flex-1"></div>
      
    </div>
  )
}

export default AuthHome
