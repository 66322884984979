import axiosDefault from './axiosDefault'

const API_URL = process.env.REACT_APP_API_URL

export const addMessage = (body) => {
    return axiosDefault.post(API_URL + '/message', body)
}


export const sendMessageByCategory = (body) => {
    return axiosDefault.post(API_URL + '/message/ctgrysend', body)
}


export const getMessage = (id) => {
    return axiosDefault.get(API_URL + `/message/${id}`)
}

export const getMessages = (pageIndex = 0) => {
    return axiosDefault.get(API_URL + `/message?pageIndex=${pageIndex}`)
}

export const getUserMessages = (params) => {
    return axiosDefault.get(API_URL + `/message/user?pageIndex=${params.current}&pageSize=${params.pageSize}&status=${params.messageStatus}&phoneNumber=${params.phoneNumber}&searchEndDate=${params.searchEndDate}&searchStartDate=${params.searchStartDate}`)
}

export const getUserMessagesForAdmin = async(params, userId) => {
    return await axiosDefault.get(API_URL + `/message/admin/${userId}/sentMessage?pageIndex=${params.current}&pageSize=${params.pageSize}&status=${params.messageStatus}&phoneNumber=${params.phoneNumber}&searchEndDate=${params.searchEndDate}&searchStartDate=${params.searchStartDate}`)
  }

  export const getWaitingMessagesForAdmin = async(pagination, userId) => {
    return await axiosDefault.get(API_URL + `/message/admin/${userId}/waitingMessage?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
  }

export const getWatingMessages = (params) => {
    return axiosDefault.get(API_URL + `/message/waitingmessage?pageIndex=${params.current}&pageSize=${params.pageSize}&status=${params.messageStatus}&phoneNumber=${params.phoneNumber}&date=${params.searchDate}`)
}


export const getUserIncomingMessages = (params) => {
    return axiosDefault.get(API_URL + `/message/incoming?pageIndex=${params.current}&pageSize=${params.pageSize}&phoneNumber=${params.searchPhoneNumber}&startDate=${params.searchStartDate}&endDate=${params.searchEndDate}`)
}

export const uploadBulkSMS = (body) => {
    
    const {shortCode, message, csvFile,scheduleDate,isScheduled,title} = body
    const form = new FormData()
    
    form.append('csvFile', csvFile)
    form.set('content', message)
    form.set('shortcode', shortCode)
    form.set('scheduleDate', scheduleDate)
    form.set('isScheduled', isScheduled)
    form.set('title', title)
    return axiosDefault.post(API_URL + `/message/bulksend`, form)
}

export const queryBuilder = (query) => {
    if (!query) return
    let q = ''
    for (const [key, value] of Object.entries(query)) {
        if (value) q = q + `${key}=${value}&`
    }
    return q
}

export const getSmsSent = (params) => {
    return axiosDefault.get(API_URL + `/message/report?&startDate=${params.startDate}&endDate=${params.endDate}`)
  }


  export const getSmsSentMessageForAdmin = (params,userId) => {
    let query = [];
    if (params.startDate !== "") {
      query.push(`startDate=${params.startDate}`);
    }
    if (params.endDate !== "") {
      query.push(`endDate=${params.endDate}`);
    }
    return axiosDefault.get(API_URL + `/message/admin/${userId}/report?${query.join("&")}`)
  }