import React, { useEffect, useState } from "react";
import { NavigationKeyOnMenu, RenderNavigationKeyOnMenu } from "./common";
import { Button, Checkbox, Form, Input, TimePicker, message } from "antd";
import { NodeTypes } from "../IVRDesignerScreen";
import WeekDaySelector from "./WeekDaySelector";
import dayjs from 'dayjs';
import moment from "moment";

const TransferForm = (props) => {
  const format = 'HH:mm';

  const [form] = Form.useForm();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [errors, setErrors] = useState({
    selectedDays: "",
  });

  const [isTouched, setIsTouched] = useState(false);

  const validateFields = () => {
    setIsTouched(true);
    const _errors = {};

    if (selectedDays.length == 0) {
      _errors.selectedDays = "Work Days are not selected";
    }
    console.log(_errors);
    setErrors(_errors);

    return Object.keys(_errors).length > 0;
  };

  const onToggleSelectDay = (day) => {
    setIsTouched(true);
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((_day) => _day !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  useEffect(() => {
    if (isTouched) {
      validateFields();
    }
  }, [selectedDays]);

  useEffect(() => {
    console.log(props.currentNode, "currentN")

    if ((props.edit || props.viewOnly) && props.currentNode) {
      const currentNode = props.currentNode;
      form.setFieldValue("title", currentNode.value.title);
      form.setFieldValue("phoneNumber", currentNode.value.phoneNumber);
      setSelectedDays(currentNode.value.workDays);
      form.setFieldValue("time", [moment(`12-25-1995 ${currentNode.value.startTime}`), moment(`12-25-1995 ${currentNode.value.endTime}`)])
      form.setFieldValue("isKeyInterupted", currentNode.value.isKeyInterupted);
      form.setFieldValue("availableLine", currentNode.value.availableLine);
      if (currentNode.value.menuKey) {
        form.setFieldValue("menuKey", currentNode.value.menuKey);
      }
      if (currentNode.value.nextId) {
        form.setFieldValue("nextId", currentNode.value.nextId);
      }
    }
  }, [props.edit, props.viewOnly]);

  const onTransferSubmitted = (values) => {
    if (validateFields()) return;

    if (
      props.edit &&
      props.parentNode &&
      props.parentNode.value.type == NodeTypes.menu
    ) {
      const childrenValues = props.parentNode.value.childrenValues;
      for (let item of childrenValues) {
        if (item.id != props.currentNode.key && item.key == values.menuKey) {
          message.error(`menu key ${values.menuKey} is already in use`);
          return;
        }
      }
    }
    if (props.currentNode && props.currentNode.value.type == NodeTypes.menu) {
      const childrenValues = props.currentNode.value.childrenValues;
      for (let item of childrenValues) {
        if (item.key == values.menuKey) {
          message.error(`menu key ${values.menuKey} is already in use`);
          return;
        }
      }
    }
    let data = {};
    if (props.edit && props.currentNode) {
      data = props.currentNode.value;
    }
    data.title = values.title;
    data.phoneNumber = values.phoneNumber;
    data.workDays = selectedDays;
    data.type = NodeTypes.transfer;
    
    data.startTime = values.time[0].format('HH:mm');
    data.endTime = values.time[1].format('HH:mm');

    data.isKeyInterupted = values.isKeyInterupted;

    data.menuKey = values.menuKey;
    data.menuOption = "NEXT";
    data.nextId = values.nextId;
    data.availableLine = values.availableLine;
    const node = {
      key: Date.now(),
      value: data,
      parent: null,
      children: [],
    };

    if (props.edit && props.currentNode) {
      node.parent = props.currentNode.parent;
      node.children = props.currentNode.children;
      node.key = props.currentNode.key;
    }
    props.onSubmitHandler(node);
  };

  const onReset = () => {
    form.resetFields();
  };

  const renderMenuForm = () => {
    if (props.viewOnly) {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    if (!props.edit) {
      if (props.currentNode && props.currentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    } else {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    return null;
  };

  return (
    <div className="mt-5">
      <Form
        className=""
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onTransferSubmitted}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              type: "string",
              message: "Title is required",
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              type: "string",
              message: "Phone number is required",
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item
          name="availableLine"
          label="Available Line"
          rules={[
            {
              required: true,
              type: "string",
              message: "Available line is required",
            },
          ]}
        >
          <Input defaultValue={1} disabled={props.viewOnly} />
        </Form.Item>
        <div className="flex items-center gap-1">
          <span className="text-red-500">*</span>
          <div>Work Days</div>
        </div>
        <div className="my-4">
          <WeekDaySelector
            disabled={props.viewOnly}
            onToggleSelect={onToggleSelectDay}
            selectedDays={selectedDays}
          />
          <div className="text-red-500 mt-1">{errors.selectedDays}</div>
        </div>

        <div className=" w-full gap-2 ">
          <Form.Item rules={[
              {
                required: true,
                message: "Time is required",
              },
            ]}
            name="time"
            label="Time" >
          <TimePicker.RangePicker format={format} />

            </Form.Item>
          {/* <Form.Item
            rules={[
              {
                required: true,
                message: "Start Time is required",
              },
            ]}
            name="startTime"
            label="Start Time"
          >
            <TimePicker
              disabled={props.viewOnly}
              format="HH:mm"
              aria-label="Start Time"
              placeholder="Select Start Time"
              aria-required
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "End Time is required",
              },
            ]}
            name="endTime"
            label="End Time"
          >
            <TimePicker
              disabled={props.viewOnly}
              format="HH:mm"
              aria-label="End Time"
              placeholder="Select End Time"
              aria-required
              allowClear={false}
            />
          </Form.Item> */}
        </div>
        {renderMenuForm()}
        <Form.Item name="nextId" label="Next ID (optional)">
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <div className="flex justify-between">
          <Form.Item
            name="isKeyInterupted"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox disabled={props.viewOnly}>Is Key Interupted ?</Checkbox>
          </Form.Item>
        </div>
        <Form.Item className="flex justify-end">
          {!props.viewOnly && (
            <Button
              disabled={props.viewOnly}
              onClick={validateFields}
              className="bg-slate-700 text-white"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default TransferForm;
