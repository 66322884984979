import React from 'react'
import OTPVerificationSignup from '../../components/OTPVerification/OTPVerificationSignup'

export default function OTPVerificationEmailScreen() {
    return (
        <div className="flex flex-col justify-center h-screen items-center bg-gradient-to-br from-blue-900 via-primary_color to-gray-900 text-white">
            <div className="flex text-2xl m-4">
            
            </div>
            <OTPVerificationSignup />
        </div>
    )
}
