import React from 'react'
import Footer from '../../components/Footer/Footer'
import Login from '../../components/Login/Login'
function LoginScreen(props) {
  return (
    <div>
      {/* <Header></Header> */}
      <Login></Login>
      {/* <Footer /> */}
    </div>
  )
}

LoginScreen.propTypes = {}

export default LoginScreen
