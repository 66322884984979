import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Select,
  Space,
  Table,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserMessages,
  getUserMessagesForAdmin,
  getWaitingMessagesForAdmin,
} from "../../services/messageService";

import { validatePhone } from "../../_helpers/validatePhone.js";
import Loader from "../Loader/Loader";

const AdminWaitingMessage = () => {
  const [shortCode, setShortCode] = useState([]);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [autoReply, setAutoReply] = useState(false);
  const [keyBased, setKeyBased] = useState(false);
  const [codeModalVisible, setCodeModalVisible] = useState(false);
  const [shortCodeData, setShortCodeData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 6,
    },
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { userId } = useParams();

  useEffect(() => {
    getUserShortCodes();
  }, [
    refresh,
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
  ]);

  const getUserShortCodes = () => {
    const status = ["ACTIVE", "PENDING", "INACTIVE"];

    getWaitingMessagesForAdmin(tableParams.pagination, userId).then((resp) => {
      const _message = resp.data.msgs;
      console.log("resp", resp);
      const _total = resp.data.total;
      setShortCode(_message);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: _total,
        },
      });
    });
  };

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  const columns = [
    {
      title: "PhoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Short Code",
      dataIndex: "shortCode",
      key: "shortCode",
    },
    {
      title: "Key Name",
      dataIndex: "keyName",
      key: "keyName",
    },
    {
      title: "Message Size",
      dataIndex: "msgLen",
      key: "msgLen",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          className={
            text === "PENDING"
              ? "text-yellow-500"
              : text === "DELIVERED"
              ? "text-green-500 font-bold"
              : "text-red-600"
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <span>{text && moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
      ),
    },
  ];

  // const columns = [
  //   {
  //     title: 'ShortCode',
  //     dataIndex: 'value',
  //     key: 'value',
  //     render: (text) => <a>{text}</a>,
  //   },
  //   {
  //     title: 'Key Name',
  //     dataIndex: 'key',
  //     key: 'key',
  //     render: (text) => <a>{text}</a>,
  //   },
  //   {
  //     title: 'Code Id',
  //     dataIndex: 'codeId',
  //     key: 'codeId',
  //     render: (text) => <a>{text}</a>,
  //   },

  //   // {
  //   //   title: 'status',
  //   //   dataIndex: 'status',
  //   //   key: 'status',
  //   //   render: (status) => (
  //   //     <span className='uppercase flex justify-center'>

  //   //       {status === "WAITING" ? "WATING FOR APPROVAL" : status}
  //   //     </span>
  //   //   ),
  //   // },
  //   {
  //     title: 'status',
  //     dataIndex: ['isActive', 'status'],
  //     key: 'isActive',
  //     render: (text, row) =>
  //       <a title={row["status"]}>
  //         {
  //           row["isActive"] === 1 ? (row["status"] === "WAITING" ? "WAITING FOR APPROVAL" : row["status"])
  //             : "SUSPEND"

  //         }
  //         {/* {row["status"]}  {String(row["isActive"])} */}

  //       </a>,
  //   },

  // ];

  return (
    <div className="w-full h-full flex flex-col  p-4">
      <h2 className="text-2xl text-gray-700 capitalize ml-2 mt-5 dark:text-white">
        waiting Message{" "}
      </h2>

      <div className="w-full h-full my-5 p-3">
        <Table
          columns={columns}
          dataSource={shortCode}
          bordered
          onChange={handleTableChange}
          pagination={tableParams.pagination}
        />
      </div>
    </div>
  );
};

export default AdminWaitingMessage;
