import { Form, Select } from "antd";
import { RemoveIcon } from "./icons";
const { Option } = Select;

export const getLanguageLabel = (language) => {
  switch (language) {
    case "AMHARIC":
      return "Amharic";
    case "ORROMIFFA":
      return "Orromiffa";
    case "SIDAMIGNA":
      return "Sidamigna";
    case "LISTEN_MENU_AGAIN":
      return "Listen Menu Again";
  }
};

export const getInformationMenuLabel = (informationMenu) => {
  switch (informationMenu) {
    case "NEXT":
      return "Next";
    case "LISTEN_INFO_AGAIN":
      return "Listen Info Again";
    case "LISTEN_MENU_AGAIN":
      return "Listen Menu Again";
  }
};

export const getRecordMenuLabel = (recordMenu) => {
  switch (recordMenu) {
    case "NEXT":
      return "Next";
    case "SAVE_RECORD":
      return "Save Record";
    case "RE_RECORD":
      return "Re-record";
    case "LISTEN_RECORD_AGAIN":
      return "Listen Record Again";
    case "LISTEN_MENU_AGAIN":
      return "Listen Menu Again";
  }
};

export const LanguageMenus = [
  { value: "AMHARIC", label: "Amharic" },
  { value: "ORROMIFFA", label: "Orromiffa" },
  { value: "SIDAMIGNA", label: "Sidamigna" },
  { value: "LISTEN_MENU_AGAIN", label: "Listen Again" },
];

export const OnlyLanguageMenus = [
  { value: "AMHARIC", label: "Amharic" },
  { value: "ORROMIFFA", label: "Orromiffa" },
  { value: "SIDAMIGNA", label: "Sidamigna" },
];

export const InformationMenus = [
  { value: "NEXT", label: "Next" },
  { value: "LISTEN_INFO_AGAIN", label: "Listen Info Again" },
  { value: "LISTEN_MENU_AGAIN", label: "Listen Menu Again" },
];

export const RecordMenus = [
  { value: "SAVE_RECORD", label: "Save Record" },
  { value: "LISTEN_RECORD_AGAIN", label: "Listen Record Again" },
  { value: "RE_RECORD", label: "Re-Record" },
  { value: "NEXT", label: "Next" },
  { value: "LISTEN_MENU_AGAIN", label: "Listen Menu Again" },
];

export const KeyValues = ["1", "2", "3", "4", "5", "6", "7", "9", "0", "*", "#"];

export const displayMenuItems = (
  items,
  onRemoveItemHandler,
  formatLabel,
  readOnly
) => {
  return (
    <div className="">
      {items.map((item, index) => {
        return (
          <div className="grid grid-cols-5 gap-1 mt-3">
            <div className="col-span-2">{item.key}</div>
            <div className="col-span-2">
              {formatLabel ? formatLabel(item.value) : item.value}
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <button
                disabled={readOnly}
                type="button"
                onClick={() => {
                  onRemoveItemHandler(index);
                }}
                className="text-red-600"
              >
                {RemoveIcon}
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const RenderNavigationKeyOnMenu = (props) => {
  return (
    <div className="">
      <Form.Item
        name="menuKey"
        label="Menu Key"
        rules={[{ required: true, message: "Key is required" }]}
      >
        <Select disabled={props.viewOnly} placeholder="Select Key">
          {KeyValues.map((key) => {
            return <Option value={key}>{key}</Option>;
          })}
        </Select>
      </Form.Item>
    </div>
  );
};

export const WeekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
