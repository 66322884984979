import { Button, Checkbox, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { NodeTypes } from "../IVRDesignerScreen";
import { RemoveIcon } from "./icons";
import KeyAssigningForm from "./KeyAssigningForm";
import {
  KeyValues,
  LanguageMenus,
  NavigationKeyOnMenu,
  RenderNavigationKeyOnMenu,
  displayMenuItems,
  getLanguageLabel,
} from "./common";

const ChangeLanguageForm = (props) => {
  const [form] = Form.useForm();
  const [languages, setLanguages] = useState([]);
  const [validateLanguge, setValidateLanguage] = useState(true);
  const [isTouched, setIsTouched] = useState(false);

  const getLanguageLengthError = () => {
    if (languages.length <= 1) return "Atleast two languages are required";
  };
  useEffect(() => {
    if ((props.edit || props.viewOnly) && props.currentNode) {
      const currentNode = props.currentNode;
      console.log(props.currentNode);
      form.setFieldValue("title", currentNode.value.title);
      form.setFieldValue("menuAudio", currentNode.value.menuAudio);
      form.setFieldValue("menuAudioScript", currentNode.value.menuAudioScript);
      form.setFieldValue("isKeyInterupted", currentNode.value.isKeyInterupted);
      form.setFieldValue(
        "confirmLanguageChangeAudio",
        currentNode.value.confirmLanguageChangeAudio
      );
      form.setFieldValue(
        "confirmLanguageChangeScript",
        currentNode.value.confirmLanguageChangeScript
      );

      if (currentNode.value.menuKey) {
        form.setFieldValue("menuKey", currentNode.value.menuKey);
      }
      if (currentNode.value.nextId) {
        form.setFieldValue("nextId", currentNode.value.nextId);
      }

      setLanguages(currentNode.value.childrenValues);
    }
  }, [props.edit, props.viewOnly]);

  const onChangeLanguageSubmitHandler = (values) => {
    if(getLanguageLengthError()) return;
    if(props.edit && props.parentNode && props.parentNode.value.type == NodeTypes.menu){
      const childrenValues = props.parentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.id != props.currentNode.key && item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }
    if(props.currentNode && props.currentNode.value.type == NodeTypes.menu){
      const childrenValues = props.currentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }
    let data = {};
    let _keyId = Date.now();

    if (props.edit && props.currentNode) {
      data = props.currentNode.value;
      _keyId = props.currentNode.key;
    }
    data.type = NodeTypes.changeLanguage;
    data.title = values.title;
    data.isKeyInterupted = values.isKeyInterupted;
    data.menuAudio = values.menuAudio;
    data.menuAudioScript = values.menuAudioScript;

    data.confirmLanguageChangeAudio = values.confirmLanguageChangeAudio;
    data.confirmLanguageChangeScript = values.confirmLanguageChangeScript;

    data.childrenMenu = [];
    data.childrenValues = [];

    languages.forEach((languge) => {
      data.childrenMenu.push(languge.key);
      data.childrenValues.push({
        ...languge,
        id: _keyId,
      });
    });

    data.menuKey = values.menuKey;
    data.menuOption = "NEXT";

    data.childrenMenu = `[${data.childrenMenu}]`
    const node = {
      key: _keyId,
      value: data,
      parent: null,
      children: [],
    };

    if (props.edit && props.currentNode) {
      node.parent = props.currentNode.parent;
      node.children = props.currentNode.children;
      node.key = props.currentNode.key;
    }
    props.onSubmitHandler(node);
  };

  const onAddLanguageHandler = async () => {
    try {
      setValidateLanguage(true);
      const response = await form.validateFields(["key", "language"]);
      if (response.errorFields && response.errorFields.length > 0) return;
      const key = form.getFieldValue("key");
      const language = form.getFieldValue("language");
      const data = {
        id: Date.now(),
        key,
        value: language,
      };

      //check if key not duplicated
      const index = languages.findIndex((value) => {
        return value.key == key;
      });
      if (index !== -1) return;
      //check if value not duplicated
      const _index = languages.findIndex((value) => {
        return value.value == language;
      });
      if (_index !== -1) return;
      setLanguages([...languages, data]);
      form.setFieldValue("key", "");
      form.setFieldValue("language", "");
    } catch (error) {}
  };

  const onRemoveLanguageHandler = (index) => {
    const _languages = [...languages];
    _languages.splice(index, 1);
    setLanguages(_languages);
  };
  const onReset = () => {
    form.resetFields();
    setLanguages([]);
  };

  const displayLanguageForm = () => {
    const title = "Add Languages";
    const menus = LanguageMenus;
    const menuRules = [
      {
        required: true,
        message: "Language is required",
      },
    ];
    const menuLabel = "Language";
    const menuName = "language";

    const keyRules = [
      {
        required: true,
        message: "Key is required",
      },
    ];
    const applyRules = validateLanguge;
    const onAddHandler = onAddLanguageHandler;

    return (
      <KeyAssigningForm
        title={title}
        menus={menus}
        menuRules={menuRules}
        menuLabel={menuLabel}
        menuName={menuName}
        keyRules={keyRules}
        applyRules={applyRules}
        onAddHandler={onAddHandler}
        viewOnly={props.viewOnly}
      />
    );
  };

  const renderMenuForm = () => {
    if (props.viewOnly) {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    if (!props.edit) {
      if (props.currentNode && props.currentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    } else {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    return null;
  };

  return (
    <div className="mt-5">
      <Form
        className=""
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onChangeLanguageSubmitHandler}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              type: "string",
              message: "Title is required",
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>

        <Form.Item
          name="menuAudio"
          label="Menu Audio"
          rules={[
            {
              required: true,
              type: "string",
              message: "Menu audio is required",
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item name="menuAudioScript" label="Menu Audio Script (optional)">
          <Input.TextArea disabled={props.viewOnly} rows={4} />
        </Form.Item>
        {renderMenuForm()}
        <div className="flex justify-between">
          <Form.Item
            name="isKeyInterupted"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox disabled={props.viewOnly}>Is Key Interupted ?</Checkbox>
          </Form.Item>
        </div>
        <Form.Item
          name="confirmLanguageChangeAudio"
          label="Confirm Language Change Audio"
          rules={[
            {
              required: true,
              type: "string",
              message: "Menu audio is required",
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item
          name="confirmLanguageChangeScript"
          label="Confirm Language Change Script (optional)"
        >
          <Input.TextArea disabled={props.viewOnly} rows={4} />
        </Form.Item>
        {displayLanguageForm()}
        {displayMenuItems(
          languages,
          onRemoveLanguageHandler,
          getLanguageLabel,
          props.viewOnly
        )}
        {isTouched && (
          <div className="text-red-500 my-3 ">{getLanguageLengthError()}</div>
        )}

        <Form.Item className="flex justify-end mt-5">
          {!props.viewOnly && (
            <Button
              onClick={() => {
                setValidateLanguage(false);
                setIsTouched(true);
              }}
              className="bg-slate-700 text-white"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangeLanguageForm;
