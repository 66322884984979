import { Form, Input, Button, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import dot from '../../assets/img/dots.svg'
import Text from 'antd/lib/typography/Text'
import { resendOTPByEmail, resendOTPCode, resetPassword, updatedPassword } from '../../services/userService'
import { CheckOutlined, CloseOutlined, ConsoleSqlOutlined, LockOutlined } from '@ant-design/icons'

export default function PasswordReset() {

  const [errorMessage, setErrorMessage] = useState('')
  const [passwordStrength, setPasswordStrength] = useState('')
  const [focused, setFocused] = useState(false)
  const history = useHistory()
  const location = useLocation()


  const [lenghtError, setLengthError] = useState(true)
  const [specialCharError, setSpecialCharError] = useState(true)
  const [lowerUpperCaseError, setLowerUpperCaseError] = useState(true)
  



  const onFinish = (values) => {
    const query = new URLSearchParams(location.search)
    const email = query.get('eml')
    const password = values.password
    const ComfirmPassword = values.Cpassword

    if (password === ComfirmPassword) {

      updatedPassword({ password, ComfirmPassword, email }).then(resp => {
        const info = resp.data?.message
        const success = resp.data.success
        if (success) {
          message.success(info)
          history.push({
            pathname: '/login',
          })
        } else {
          setErrorMessage(info)
          message.error(info)
        }
      }).catch((err) => {
        if (err && err.response && err.response.data.message) {
          setErrorMessage(err.response.data.message)
          message.error(err.response.data.message)
        }
      })
    } else {
      setErrorMessage("Passwords did not match")
    }


  }



  const onFinishFailed = () => { }
  return (
    <div className="flex  py-8 w-2/3 justify-center">
      <div className="bg-blue-50 w-1/2  max-w-lg  flex flex-col items-center justify-center p-4 shadow-xl rounded-xl z-20 ">
        <h1 className="text-xl font-bold text-gray-800 my-2">New Password.</h1>
        <p className="max-w-sm text-gray-600 my-3 ">
          Enter the new password
        </p>

        {errorMessage && (
          <div>
            <Text className="text-sm mx-16 " type="danger">
              {errorMessage}
            </Text>
          </div>
        )}
        <div className="absolute bottom-0 left-2/3 opacity-30 z-10">
          <img className="w-40 z-0" alt="pattern" src={dot} />
        </div>
        <Form
          className="w-full flex flex-col items-center justify-center"
          name="vertical"
          layout="vertical"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="New Password"
            name="password"

            className='w-full'
            hasFeedback
            rules={[
              { required: true, message: 'Password required!' },
              {
                validator: (_, value) => {
                
                  if (value.length > 8) {
                    setLengthError(false);
                  } else {
                    setLengthError(true)
                  }
                  if (value.match(/[a-z]/) && value.match(/[A-Z]/)) {
                    setLowerUpperCaseError(false)
                  } else {
                    setLowerUpperCaseError(true)
                  }
                  if (value.match(/[^a-zA-Z\d]/) && value.match(/\d/)) {
                    setSpecialCharError(false)

                  } else {
                    setSpecialCharError(true)
                  }
                  return Promise.resolve();
                }
              }
            ]}

          >
            <Input.Password placeholder="Password" onFocus={() => { setFocused(true) }} />
          </Form.Item>
          {
            focused ?
              <div className='flex w-full border-[1px] rounded-md border-red-600 p-2 gap-4 my-2'>
                <div className='flex justify-around '>

                  <LockOutlined
                    style={{
                      color: '#F87171',
                      fontSize: '20px',
                      marginTop: "4px"
                    }}
                  />

                </div>
                <div className='flex flex-col py-1'>
                  <h1 className='text-md text-red'> you password need to :</h1>
                  <div className='flex gap-1'>
                    {
                      lenghtError ? <CloseOutlined
                        style={{
                          color: "#F87171"
                        }}
                      /> : <CheckOutlined
                        style={{
                          color: "#1fc600"
                        }}
                      />
                    }
                    <span className={`text-xs ${lenghtError ? ' text-[#F87171]' : ' text-[#1fc600]'}`}>be at least 8 characters long</span>
                  </div>

                  <div className='flex gap-1'>

                    {lowerUpperCaseError ?
                      <CloseOutlined
                        style={{
                          color: "#F87171"
                        }}
                      />
                      : <CheckOutlined
                        style={{
                          color: "#1fc600"
                        }}
                      />}


                    <h1 className={`text-xs ${lowerUpperCaseError ? 'text-[#F87171]' : 'text-[#1fc600]'}`}>include both lower and upper case characters</h1>
                  </div>

                  <div className='flex gap-1'>
                    {specialCharError ?
                      <CloseOutlined
                        style={{
                          color: "#F87171"
                        }}
                      />
                      :
                      <CheckOutlined
                        style={{
                          color: "#1fc600"
                        }}
                      />
                    }

                    <h1 className={`text-xs ${specialCharError ? 'text-[#F87171]' : 'text-[#1fc600]'}`}>include at least one number and symbol.</h1>
                  </div>
                </div>
              </div>
              :
              ""
          }


          <Form.Item
            label="Confirm Password"
            name="Cpassword"
            className='w-full'
            dependencies={["password"]}
            hasFeedback
            rules={[{ required: true, message: 'Comfirm password required!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log("value", value)
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('password does not match'));
              },
            }),
            ]}
          >
            <Input.Password placeholder="Comfirm Password" />
          </Form.Item>


          <Form.Item >
            <Button
              className="w-44 rounded-xl border-none bg-gradient-to-r from-cyan-600 to-blue-600"
              type="primary"
              htmlType="submit"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="absolute hidden md:block top-64 -left-10 opacity-40">
        <img className="w-32" alt="pattern" src={dot} />
      </div>
    </div>
  )
}
