import React, { useContext, useEffect, useState } from "react";
import AuthHome from "../../components/AuthHome/AuthHome";
import NavContext from "../../contexts/NavContext";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import Tree from "react-d3-tree";
import { clone } from "lodash";
import { Button, Drawer, Form, message } from "antd";
import IVRDesignForm from "./forms/IVRDesignForm";
import { useCenteredTree, useWindowSize } from "./helper";
import {
  AddIcon,
  ArrowLeft,
  CopyIcon,
  EditIcon,
  RecordIcon,
  RemoveIcon,
} from "./forms/icons";
import { Modal } from "antd";
import { ArrowLeftOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import StartDesignModal from "./StartDesignModal";
import NodeUI from "./NodeUI";
import useNode from "./hooks/useNode";
import * as ivrService from "../../services/ivrService";
import { useParams, useHistory } from "react-router-dom";

export const NodeTypes = {
  menu: "MENU",
  greeting: "WELCOME_IVR",
  info: "INFO",
  changeLanguage: "CHANGE_LANGUAGE",
  confirmation: "CONFIRMATION",
  record: "RECORD",
  transfer: "TRANSFER",
  listenMenuAgain: "LISTEN_MENU_AGAIN",
  conference: "CONFERENCE",
  back: "BACK_NODE",
};

const IVRDesignerScreen = (props) => {
  console.log("prop",props)
  const { navValue } = useContext(NavContext);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isMultiLingual, setIsMultilingual] = useState(false);
  const [shortCode, setShortCode] = useState();
  const [defaultLanguage, setDefaultLanguage] = useState();
  // const [audioFilePath, setAudioFilePath] = useState();
  const [_companyId, setCompanyId] = useState();
  const { companyId, ivrId } = useParams();
  const navigator = useHistory();

  const [openStartDesignForm, setOpenStartDesignForm] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [parentNode, setParentNode] = useState(null);

  const [edit, setEdit] = useState(false);
  const { height, width } = useWindowSize();

  useEffect(() => {
    if (companyId) {
      setCompanyId(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if ((props.edit || props.viewOnly) && ivrId) {
      ivrService.getSingleIVRHAndler(ivrId).then((response) => {
        const _data = response.data;
        console.log("check ",_data);

        if (_data.success && _data.data) {
          const _tree = JSON.parse(_data.data.value);
          setCompanyId(_data.data.userId);
          setIsMultilingual(_data.data.isMultilingual);
          setDefaultLanguage(_data.data.language);
          setShortCode(_data.data.incomingNumber);
          setData(_tree.root);
        }
      });
    }
  }, [props.edit]);
  const {
    data,
    setData,
    addNode,
    getParentNode,
    removeNode,
    updateNode,
    checkIfMenuKeyExist,
  } = useNode();

  const onSubmitStartDesign = (language, multiLingual, shortCode) => {
    setDefaultLanguage(language);
    setIsMultilingual(multiLingual);
    setShortCode(shortCode);
    // setAudioFilePath(audioFilePath);
    setOpenStartDesignForm(false);
    onStartDesign();
  };

  const onCancelStartDesign = () => {
    setOpenStartDesignForm(false);
  };

  const onSubmitHandler = (node) => {
    const parentNode = currentNode;

    if (edit) {
      updateNode(node);
      setCurrentNode(null);
      setEdit(false);
      setOpen(false);
      return;
    }

    if (parentNode && parentNode.value.type === NodeTypes.menu) {
      const menuKey = node.value.menuKey;
      const exist = checkIfMenuKeyExist(menuKey, parentNode.key);
      if (exist) return;
    }

    switch (node.value.type) {
      case NodeTypes.info:
      case NodeTypes.record:
      case NodeTypes.confirmation:
      case NodeTypes.listenMenuAgain:
      case NodeTypes.transfer:
      case NodeTypes.changeLanguage: {
        node.value.nextId = node.value.nextId
          ? node.value.nextId
          : parentNode.key;
      }
    }
    switch (node.value.type) {
      case NodeTypes.greeting:
      case NodeTypes.menu:
      case NodeTypes.transfer:
      case NodeTypes.listenMenuAgain:
      case NodeTypes.confirmation:
      case NodeTypes.conference:
      case NodeTypes.back:
      case NodeTypes.changeLanguage: {
        if (!data) {
          setData(node);
          break;
        }
        addNode(node, parentNode.key);
        break;
      }
      case NodeTypes.record: {
        node.value.recordMenuValues = node.value.recordMenuValues.map(
          (recordMenuValues) => {
            return {
              ...recordMenuValues,
              id: parentNode.key,
            };
          }
        );

        addNode(node, parentNode.key);
        break;
      }
      case NodeTypes.info: {
        node.value.infoMenuValues = node.value.infoMenuValues.map(
          (information) => {
            return {
              ...information,
              id: parentNode.key,
            };
          }
        );
        addNode(node, parentNode.key);
        break;
      }
    }
    setOpen(false);
    setCurrentNode(null);
  };

  const renderNodeWithCustomEvents = ({ nodeDatum, handleNodeClick }) => {
    return (
      <NodeUI
        viewOnly={props.viewOnly}
        nodeDatum={nodeDatum}
        handleNodeClick={handleNodeClick}
      />
    );
  };

  const handleNodeClick = (action, nodeDatum) => {
    setEdit(false);
    setCurrentNode(null);

    let newData = clone(data);
    if (action === "ADD") {
      setCurrentNode(nodeDatum);
      showDrawer();
    } else if (action == "REMOVE") {
      if (nodeDatum.key === newData.key) {
        newData = null;
        setCurrentNode(null);
      } else {
        removeNode(nodeDatum.key);
      }
    } else if (action == "EDIT") {
      setEdit(true);
      const parentNode = getParentNode(nodeDatum.key);
      setParentNode(parentNode);
      setCurrentNode(nodeDatum);
      showDrawer();
    } else {
      const parentNode = getParentNode(nodeDatum.key);
      setParentNode(parentNode);
      setCurrentNode(nodeDatum);
      showDrawer();
    }
    setData(newData);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setEdit(false);
    setCurrentNode(undefined);
  };
  const onStartDesign = () => {
    showDrawer();
  };
  const [translate, containerRef] = useCenteredTree();

  const onSaveHandler = () => {
    const _data = {
      userId: _companyId,
      incomingNumber: shortCode,
      language: defaultLanguage,
      isMultilingual: isMultiLingual,
      value: JSON.stringify({
        root: data,
      }),
    };
    setLoading(true);
    if (props.edit && ivrId) {
      return ivrService
        .updateIVRHandler(ivrId, _data)
        .then((response) => {
          message.success("Successfully Saved");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    ivrService
      .createIVRHandler(_data)
      .then((response) => {
        navigator.goBack();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      {openStartDesignForm && (
        <StartDesignModal
          companyId={_companyId}
          open={openStartDesignForm}
          onCancel={onCancelStartDesign}
          onFinish={onSubmitStartDesign}
        />
      )}
      {open && (
        <Drawer
          title={`${edit ? "Edit" : props.viewOnly ? "" : "Add"} Node`}
          placement="right"
          onClose={onClose}
          open={open}
        >
          <IVRDesignForm
            viewOnly={props.viewOnly}
            isMultiLingual={isMultiLingual}
            setIsMultilingual={setIsMultilingual}
            edit={edit}
            currentNode={currentNode}
            parentNode={parentNode}
            type={currentNode?.value?.type}
            onSubmitHandler={onSubmitHandler}
          />
        </Drawer>
      )}

      <div className=" dark:bg-primary_color">
        <div
          className={`  p-10 ${
            navValue.navOpen ? "md:ml-64" : "md:ml-16 "
          } overflow-x-auto`}
        >
          <div className="flex justify-end gap-2">
            <Button
              className="flex justify-center items-center"
              onClick={() => {
                navigator.goBack();
              }}
            >
              <ArrowLeftOutlined />
            </Button>
            {!data && !props.viewOnly && (
              <Button onClick={() => setOpenStartDesignForm(true)}>
                Start Design
              </Button>
            )}
            {data && !props.viewOnly && (
              <Button onClick={onSaveHandler}>
                {isLoading ? "Saving ... " : "Save Design"}
              </Button>
            )}
          </div>
          <div
            id="treeWrapper"
            ref={containerRef}
            style={{ height: `${height}px`, width: `${width}px` }}
            className="w-full  "
          >
            {data && (
              <Tree
                data={data}
                translate={translate}
                collapsible={true}
                enableLegacyTransitions
                orientation="vertical"
                nodeSize={{
                  x: 400,
                  y: 150,
                }}
                renderCustomNodeElement={(rd3tProps) =>
                  renderNodeWithCustomEvents({ ...rd3tProps, handleNodeClick })
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IVRDesignerScreen;
