import { Checkbox, Form, Input, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { OnlyLanguageMenus } from "./forms/common";
import { getUserShortCodes } from "../../services/ivrService";
const { Option } = Select;

const StartDesignModal = ({ open, onFinish, onCancel, companyId }) => {
  const [form] = Form.useForm();
  const [shortCodes, setShortCodes] = useState([]);
  useEffect(() => {
    if (companyId) {
      //get user codes
      getUserShortCodes(companyId).then((response) => {
        const _data = response.data;
        if (_data.success) {
          setShortCodes(
            _data.data.map((item) => {
              return item.value;
            })
          );
        }
      });
    }
  }, [companyId]);
  return (
    <Modal
      title="Start Design IVR"
      open={open}
      onOk={async () => {
        try {
          await form.validateFields();
          const values = form.getFieldsValue();
          onFinish(
            values.defaultLanguge,
            values.multiLingual,
            values.shortCode
          );
        } catch (e) {}
      }}
      onCancel={onCancel}
    >
      <Form
        className=""
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
      >
        <Form.Item
          name="shortCode"
          label="Short Code"
          rules={[{ required: true, message: "Short code is required" }]}
        >
          <Select placeholder="Select short code">
            {shortCodes.map((shortCOde) => {
              return <Option value={shortCOde}>{shortCOde}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="defaultLanguge"
          label="Default Language"
          rules={[{ required: true, message: "Default language is required" }]}
        >
          <Select placeholder="Select Lanugage">
            {OnlyLanguageMenus.map((language) => {
              return <Option value={language.value}>{language.label}</Option>;
            })}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="audioFilePath"
          label="Audio File Path"
          rules={[{ required: true, message: "Audio file path is required" }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          name="multiLingual"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>Multi Lingual ?</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StartDesignModal;
