import { Form, Input, Button, message } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import dot from '../../assets/img/dots.svg'
import { resendOTPByEmail, resendOTPCode, resetPassword } from '../../services/userService'

export default function ForgotPassword() {

  const [errorMessage,setErrorMessage] = useState('')
  const history = useHistory()
  const onFinish = (values) => {
    const params = new URLSearchParams()
    const email =  values.email
    

    resetPassword({ email }).then(resp => {
          const info = resp.data?.message
          const success = resp.data.success
          console.log("response ", resp)
          if(success){
            const phoneNumber = resp.data?.phoneNumber
            message.success(info)
            params.append('eml', values.email)
            params.append('pnb', phoneNumber)
            history.push({
              pathname: '/verification',
              search: params.toString()
            })
          }else{
            setErrorMessage(info)
            message.error(info)
          }
          


      }).catch((err) => {
          if (err && err.response && err.response.data.message) {
              setErrorMessage(err.response.data.message)
              message.error(err.response.data.message)
          }
      })
  
  }

  const onFinishFailed = () => { }
  return (
    <div className="min-h-screen py-8">
      <div className="bg-blue-50 w-full max-w-lg  flex flex-col items-center justify-center p-4 shadow-xl rounded-xl z-20">
        <h1 className="text-xl font-bold text-gray-800 my-2">Forgot Password.</h1>
        <p className="max-w-sm text-gray-600 my-3 ">
          Enter the email associated with your account and we will send an email
          with instruction to reset password
        </p>
        <div className="absolute bottom-0 left-2/3 opacity-30 z-10">
          <img className="w-40 z-0" alt="pattern" src={dot} />
        </div>
        <Form
          className="w-3/4 flex flex-col items-center justify-center"
          name="vertical"
          layout="vertical"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="E-mail Address"
            type="email"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input your email!'
              }
            ]}
          >
            <Input className="rounded-md" placeholder="E-mail Address" />
          </Form.Item>

          <Form.Item >
            <Button
              className="w-44 rounded-xl border-none bg-gradient-to-r from-cyan-600 to-blue-600"
              type="primary"
              htmlType="submit"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="absolute hidden md:block top-64 -left-10 opacity-40">
        <img className="w-32" alt="pattern" src={dot} />
      </div>
    </div>
  )
}
