import axiosDefault from './axiosDefault'

const API_URL = process.env.REACT_APP_API_URL

export const createincommingKey = async (body) => {
  return await axiosDefault.post(API_URL + '/incomming_key', body)
}

export const createOutgoingKey = async (body) => {
    return await axiosDefault.post(API_URL + '/outgoing_key', body)
}

export const getIncomingKeysByUserId = async (pagination) => {
  return await axiosDefault.get(API_URL + `/incomming_key/user?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
}

export const getSMSBulkCompaign = async (pagination) => {
  return await axiosDefault.get(API_URL + `/outgoing_key/sms_compaign?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
}

  
  export const getOutgoingKeysByUserId = async (pagination) => {
    return await axiosDefault.get(API_URL + `/outgoing_key/user?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
  }

  export const getOutgoingkeyHistoryLogByUserId = async (pagination) => {
    return await axiosDefault.get(API_URL + `/outgoing_key/history_log?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
  }


  export const getOutgoingkeyHistoryLogByUserIdForAdmin = async (userId,pagination) => {
    return await axiosDefault.get(API_URL + `/outgoing_key/history_log/${userId}?&pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
  }


  // export const getAllUserShortCodes = async(userId) => {
  //   return await axiosDefault.get(API_URL + `/short_codes?userId=${userId}`)
  // }

export const deleteSingleIncKey = (id) => {
    return axiosDefault.delete(API_URL + `/incomming_key/${id}`)
}

export const deleteSingleOutKey = (id) => {
  return axiosDefault.delete(API_URL + `/outgoing_key/${id}`)
}

export const updateSingleIncKey = (id,body) => {

  return axiosDefault.put(API_URL + `/incomming_key/${id}`,body)
}

export const updateSingleOutKey = (id,body) => {

  return axiosDefault.put(API_URL + `/outgoing_key/${id}`,body)
}


export const getIncSmsKey = (id) => {
  return axiosDefault.get(API_URL + `/incomming_key/${id}`)
}

export const getOutSmsKey = (id) => {
  return axiosDefault.get(API_URL + `/outgoing_key/${id}`)
}