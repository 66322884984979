import { Divider } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getUserByUserId } from '../../services/userService';

const BillingMenu = () => {
  
  const _user = JSON.parse(localStorage.getItem("user"));
  const user = _user?.user
  const [balance,setBalance] = useState(0)

  useEffect(()=>{
    getUser()
  },[])

  console.log("user ",user)
   
    

      const getUser = ()=>{
        getUserByUserId().then((resp)=>{
          console.log("resp",resp)
          const blnc =  resp.data?.balance
          var convertedValue = parseFloat(blnc).toFixed(2);
          console.log("balanced",convertedValue)
          setBalance(convertedValue)
          // intToFloat(blnc,2)
        })
      }
      
const intToFloat = (num,decPlaces) =>{
  return setBalance(num.toFixed(decPlaces)); 
}

      const items = [
   
        {
          title: 'Manage Billing',
          items:[
            {
              // name:`Balance ${balance.toFixed(2)} ETB`,
              name:`Balance ${parseFloat(balance).toFixed(2)} ETB`,
              
            
            },
          ]          
        },
        {
            title: 'Usage ',
            items:[
              {
                 link:"/user/summary",
                  name:"Transaction Logs",
              }
            
            ]          
          },
      ];    
    
  return (
    <div className='flex flex-col items-center w-56 p-2 bg-white fixed right-64 top-16 h-56 border-[1px] rounded-md z-50 shadow-2xl '>
           {items.map((data,index)=>(
            
                <div key={index} className="w-full flex flex-col items-start justify-center p-2">
                    <h2 className='font-bold'>{data.title}</h2>
                    <Divider className='my-3'/>
                    {data.items.map((item,index)=>(
                        <Link key={index}  to={item.link} className="ml-3 p-1 ">{item.name}</Link>
                    ))}
                </div>
           ))}
    </div>
  )
}

export default BillingMenu