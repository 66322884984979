import { Divider, Form, Input, Select } from "antd";
import React, { useState } from "react";
import { AddIcon } from "./icons";
import { KeyValues } from "./common";
const { Option } = Select;

const KeyAssigningForm = (props) => {
  const title = props.title;
  const menus = props.menus; // [ { value: "" , label: "" }]
  const menuLabel = props.menuLabel;
  const menuName = props.menuName;
  const menuRules = props.menuRules;
  const keyRules = props.keyRules;
  const applyRules = props.applyRules;
  const onAddHandler = props.onAddHandler;
  return (
    <>
      {/* <div className="flex justify-between items-center mb-5">
        <div className="font-bold uppercase">{title}</div>
      </div>
      <Divider /> */}
      <div>
        <div className="grid grid-cols-5 gap-1">
          <Form.Item
            name="key"
            label="Key"
            className="col-span-2"
            rules={applyRules && keyRules}
          >
            <Select disabled={props.viewOnly} placeholder="Select Key">
              {KeyValues.map((key) => {
                return <Option value={key}>{key}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={menuName}
            label={menuLabel}
            className="col-span-2"
            rules={applyRules && menuRules}
          >
            <Select disabled={props.viewOnly} placeholder="Select Option">
              {menus.map((menu) => {
                return <Option value={menu.value}>{menu.label}</Option>;
              })}
            </Select>
          </Form.Item>
          <div className="col-span-1 flex items-center justify-center">
            <button
              disabled={props.viewOnly}
              type="button"
              className="col-span-1"
              onClick={onAddHandler}
            >
              {AddIcon}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeyAssigningForm;
