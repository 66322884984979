import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.less";
// import Graph from "./components/Graph/Graph";
import { getUserFromStorage } from "./services/userService";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import UserContext from "./contexts/UserContext";
import NavContext from "./contexts/NavContext";
import ThemeContext from "./contexts/ThemeContext";
import ReactLoading from "react-loading";
import GuardedAdminRoute from "./components/GuardedAdminRoute/GuardAdminRoute";
import GuardedRoute from "./components/GuardRoute/GuardRoute";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import AdminWaitingMessageScreen from "./screens/AdminWaitingMessageScreen/AdminWaitingMessageScreen";
import IVRDesignerScreen from "./screens/IVRDesignerScreen/IVRDesignerScreen";
import IVRHomeScreen from "./screens/IVRHomeScreen/IVRHomeScreen";

const AdminChangePasswordScreen = React.lazy(() =>
  import("./screens/AdminChangePasswordScreen/AdminChangePasswordScreen")
);
// const GuardedAdminRoute = React.lazy(() => import('./components/GuardedAdminRoute/GuardAdminRoute'));
// const GuardedRoute = React.lazy(() => import('./components/GuardRoute/GuardRoute'));
const AdminSentMessageScreen = React.lazy(() =>
  import("./screens/AdminSentMessageScreen/AdminSentMessageScreen")
);
const AdminCodesScreen = React.lazy(() =>
  import("./screens/AdminCodesScreen/AdminCodesScreen")
);
const AdminHomeScreen = React.lazy(() =>
  import("./screens/AdminHomeScreen/AdminHomeScreen")
);
const AdminUsersScreen = React.lazy(() =>
  import("./screens/AdminUsersScreen/AdminUsersScreen")
);
const ConferenceScreen = React.lazy(() =>
  import("./screens/ConferenceScreen/ConferenceScreen")
);
const AddConferenceScreen = React.lazy(() =>
  import("./screens/ConferenceScreen/AddConferenceScreen")
);
const AuthHomeScreen = React.lazy(() =>
  import("./screens/HomeScreen/AuthHomeScreen")
);
// const LoginScreen = React.lazy(() => import('./screens/LoginScreen/LoginScreen'));
const RatingScreen = React.lazy(() =>
  import("./screens/RatingScreen/RatingScreen")
);
const ShortCodesGraphsScreen = React.lazy(() =>
  import("./screens/ShortCodesGraphsScreen/ShortCodesGraphsScreen")
);
// const SignUpScreen = React.lazy(() => import('./screens/SignUpScreen/SignUpScreen'));
const ProfileScreen = React.lazy(() =>
  import("./screens/ProfileScreen/ProfileScreen")
);
const RedirectScreen = React.lazy(() =>
  import("./screens/RedirectScreen/RedirectScreen")
);
const PollAddScreen = React.lazy(() =>
  import("./screens/PollScreen/PollAddScreen")
);
const PollListScreen = React.lazy(() =>
  import("./screens/PollScreen/PollListScreen")
);
const AdminPollManage = React.lazy(() =>
  import("./screens/AdminPollManage/AdminPollManage")
);
const AdminPollDetail = React.lazy(() =>
  import("./components/AdminPoll/AdminPollDetail")
);
const PollResultScreen = React.lazy(() =>
  import("./screens/PollScreen/PollResultScreen")
);
const AdminRatingScreen = React.lazy(() =>
  import("./screens/AdminRatingScreen/AdminRatingScreen")
);
const AdminPollAudio = React.lazy(() =>
  import("./components/AdminPoll/AdminPollAudio")
);
const LandingScreen = React.lazy(() =>
  import("./screens/LandingScreen/LandingScreen")
);
const ForgotPasswordScreen = React.lazy(() =>
  import("./screens/ForgotPasswordScreen/ForgotPasswordScreen")
);
const MessageAllScreen = React.lazy(() =>
  import("./screens/MessageScreen/MessageAllScreen")
);
const IncomingMessageScreen = React.lazy(() =>
  import("./screens/MessageScreen/IncomingMessageScreen")
);
const OTPVerificationScreen = React.lazy(() =>
  import("./screens/OTPVerificationScreen/OTPVerificationScreen")
);
const AudioScriptsScreen = React.lazy(() =>
  import("./screens/AudioScriptsScreen/AudioScriptsScreen")
);
const AdminAudioScriptsScreen = React.lazy(() =>
  import("./screens/AdminAudioScriptScreen/AdminAudioScriptScreen")
);
const UserResponseScreen = React.lazy(() =>
  import("./screens/UserCommentScreen/UserCommentScreen")
);
const ShortCodeConfig = React.lazy(() =>
  import("./components/ShortCodeConfig/ShortCodeConfig")
);
const ShortCodeConfigScreen = React.lazy(() =>
  import("./screens/ShortCodeConfigScreen/ShortCodeConfigScreen")
);
const CategoryConfigScreen = React.lazy(() =>
  import("./screens/CategoryConfigScreen/CategoryConfigScreen")
);
const CustomerInfoScreen = React.lazy(() =>
  import("./screens/CustomerInfoScreen/CustomerInfoScreen")
);
const MessageScreen = React.lazy(() =>
  import("./screens/MessageScreen/MessageScreen")
);
const BulkMessageScreen = React.lazy(() =>
  import("./screens/BulkMessageScreen/BulkMessageScreen")
);
const AddPhoneToCategoryScreen = React.lazy(() =>
  import("./screens/AddPhoneToCategoryScreen/AddPhoneToCategoryScreen")
);
const ApiTokenScreen = React.lazy(() =>
  import("./screens/ApiTokenScreen/ApiTokenScreen")
);
const OTPVerificationEmailScreen = React.lazy(() =>
  import("./screens/OTPVerificationScreen/OTPVerificationEmailScreen")
);
const PageNotFoundScreen = React.lazy(() =>
  import("./screens/404/PageNotFoundScreen")
);
const CompanyDashboardScreen = React.lazy(() =>
  import("./screens/CompanyDashboardScreen/CompanyDashboardScreen")
);
const CompanyReportScreen = React.lazy(() =>
  import("./screens/CompanyReportScreen/CompanyReportScreen")
);
const ManageKeyScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/ManageKeyScreen")
);
const EditIncommingScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/EditIncommingScreen")
);
const ManageShortCodeScreen = React.lazy(() =>
  import("./screens/ManageShortCodeScreen/ManageShortCodeScreen")
);
const EditOutgoingScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/EditOutgoingScreen")
);
const ForgotPasswordVerficationScreen = React.lazy(() =>
  import("./screens/ForgotPasswordScreen/ForgotPasswordScreen")
);
const PasswordResetVerficationScreen = React.lazy(() =>
  import(
    "./screens/PasswordResetVerficationScreen/PasswordResetVerficationScreen"
  )
);
const PasswordResetScreen = React.lazy(() =>
  import("./screens/PasswordResetScreen/PasswordResetScreen")
);
const AdminUserShortcodesScreen = React.lazy(() =>
  import("./screens/AdminCodesScreen/AdminUserShortcodesScreen")
);
const AdminConfigPriceScreen = React.lazy(() =>
  import("./screens/AdminConfigPriceScreen/AdminConfigPriceScreen")
);
const CallLogScreen = React.lazy(() =>
  import("./screens/CallLogScreen/CallLogScreen")
);
const CustomerIvrLanguageScreen = React.lazy(() =>
  import("./screens/CustomerIvrLanguageScreen/CustomerIvrLanguageScreen")
);
const WaitingMessageScreen = React.lazy(() =>
  import("./screens/MessageScreen/WaitingMessageScreen")
);
const AdminManageKeysScreen = React.lazy(() =>
  import("./screens/AdminCodesScreen/AdminManageKeysScreen")
);
const UserCommentScreen = React.lazy(() =>
  import("./screens/UserCommentScreen/UserCommentScreen")
);
const ChangePasswordScreen = React.lazy(() =>
  import("./screens/ChangePasswordScreen/ChangePasswordScreen")
);
const IncomingKeyScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/IncomingKeyScreen")
);
const OutgoingKeyScreen = React.lazy(() =>
  import("./screens/ManageKeyScreen/OutgoingKeyScreen")
);
const UserApprovementScreen = React.lazy(() =>
  import("./screens/UserApprovementScreen/UserApprovementScreen")
);
const OTPVerficationSignUpScreen = React.lazy(() =>
  import("./screens/OTPVerficationSignUpScreen/OTPVerficationSignUpScreen")
);
const WatingApprovalScreen = React.lazy(() =>
  import("./screens/WatingApprovalScreen/WatingApprovalScreen")
);
const AdminBalanceScreen = React.lazy(() =>
  import("./screens/AdminBalanceScreen/AdminBalanceScreen")
);
const TransactionLogScreen = React.lazy(() =>
  import("./screens/TransactionLogScreen/TransactionLogScreen")
);
const AdminShortCodeEditScreen = React.lazy(() =>
  import("./screens/AdminShortCodeEditScreen/AdminShortCodeEditScreen")
);
const Assign_IVR_ShortCodeScreen = React.lazy(() =>
  import("./screens/Assign_IVR_ShortCodeScreen/Assign_IVR_ShortCodeScreen")
);
const DeactivateUserScreen = React.lazy(() =>
  import("./screens/DeactivateUserScreen/DeactivateUserScreen")
);
const HistoryLogScreen = React.lazy(() =>
  import("./screens/HistoryLogScreen/HistoryLogScreen")
);
const AdminHistoryLogScreen = React.lazy(() =>
  import("./screens/AdminHistoryLogScreen/AdminHistoryLogScreen")
);

const AdminReportScreen = React.lazy(() =>
  import("./screens/AdminReportScreen/AdminReportScreen")
);
const SMSCompaignScreen = React.lazy(() =>
  import("./screens/SMSCompaignScreen/SMSCompaignScreen")
);
const Footer = React.lazy(() => import("./components/Footer/Footer"));

// import AdminGraph from "./components/AdminGraph/AdminGraph";

function AdminRouting() {
  return (
    <div>
       <GuardedAdminRoute
          exact
          path="/admin/ivr/:companyId/ivr-designer/add"
          component={IVRDesignerScreen}
        />
        <GuardedAdminRoute
          exact
          viewOnly
          path="/ivr/:ivrId/ivr-design/view"
          component={IVRDesignerScreen}
        />
        <GuardedAdminRoute
          exact
          path="/admin/ivr/:ivrId/ivr-designer/edit"
          edit={true}
          component={IVRDesignerScreen}
        />
        <GuardedAdminRoute
          exact
          path="/admin/ivr/:companyId/detail"
          component={IVRHomeScreen}
        />
      <Suspense
        fallback={
          <div className="fixed top-1/2 w-full flex items-center justify-center">
            <ReactLoading type="cylon" color="black" />
          </div>
        }
      >
       

        <GuardedAdminRoute
          exact
          path="/admin/poll/detail"
          component={AdminPollDetail}
        />
        <GuardedAdminRoute
          exact
          path="/admin/poll/upload"
          component={AdminPollAudio}
        />
        <GuardedAdminRoute
          exact
          path="/admin/poll"
          component={AdminPollManage}
        />
        <GuardedAdminRoute
          exact
          path="/admin/user/:userId/shortcodes"
          component={AdminUserShortcodesScreen}
        />
        <GuardedAdminRoute
          exact
          path="/admin/user/:userId/config-price"
          component={AdminConfigPriceScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/user/:userId/manageKey"
          component={AdminManageKeysScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/user/:userId/sentMessage"
          component={AdminSentMessageScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/user/:userId/waitingMessage"
          component={AdminWaitingMessageScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/user/:userId/history_log"
          component={AdminHistoryLogScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/user/:userId/report"
          component={AdminReportScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/change_password"
          component={AdminChangePasswordScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/user/:userId/add-balance"
          component={AdminBalanceScreen}
        />
        <GuardedAdminRoute
          exact
          path="/admin/rating"
          component={AdminRatingScreen}
        />
        <GuardedAdminRoute
          exact
          path="/admin/short_codes/:id"
          component={AdminShortCodeEditScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/users"
          component={AdminUsersScreen}
        />

        <GuardedAdminRoute
          exact
          path="/admin/waiting-users"
          component={WatingApprovalScreen}
        />
        <GuardedAdminRoute
          exact
          path="/admin/Deactivate-users"
          component={DeactivateUserScreen}
        />
        {/* <GuardedAdminRoute path="/admin/graph" component={AdminGraph} /> */}
        <GuardedAdminRoute
          exact
          path="/admin/short_codes"
          component={AdminCodesScreen}
        />
        <GuardedAdminRoute
          path="/admin/audio_files"
          exact
          component={AdminAudioScriptsScreen}
        />
        <GuardedAdminRoute exact path="/admin" component={AdminHomeScreen} />
        {/* <GuardedAdminRoute path="/dashboard" exact component={CompanyDashboardScreen} /> */}
      </Suspense>
    </div>
  );
}

export default AdminRouting;
