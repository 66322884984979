import Particles from "react-tsparticles"
import { loadFull } from "tsparticles"
export default function Particle() {


    const particlesInit = async (main) => {
        console.log(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };

    const particlesLoaded = (container) => {
        console.log(container);
    };


    return (
        <Particles init={particlesInit} loaded={particlesLoaded} options={{


            "fullScreen": {
                "enable": true,
                "zIndex": -1
            },
            "background": {
                "color": "rgb(2,0,36)",
                "image": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "position": "50% 50%",
                "repeat": "no-repeat",
                "size": "cover"
            },
            "fpsLimit": 120,
            "interactivity": {
                "events": {
                    "onClick": {
                        "enable": true,
                        "mode": "push"
                    },
                    "onHover": {
                        "enable": true,
                        "mode": "repulse"
                    },
                    "resize": true
                },
                "modes": {
                    "push": {
                        "quantity": 2
                    },
                    "repulse": {
                        "distance": 100,
                        "duration": 0.4
                    }
                }
            },
            "particles": {
                "color": {
                    "value": "#ffffff"
                },
                "links": {
                    "color": "#ffffff",
                    "distance": 150,
                    "enable": true,
                    "opacity": 0.5,
                    "width": 1
                },
                "collisions": {
                    "enable": true
                },
                "move": {
                    "direction": "none",
                    "enable": true,
                    "outModes": {
                        "default": "bounce"
                    },
                    "random": false,
                    "speed": 1,
                    "straight": false
                },
                "number": {
                    "density": {
                        "enable": true,
                        "area": 700
                    },
                    "value": 50
                },
                "opacity": {
                    "value": 0.5
                },
                "shape": {
                    "type": "circle"
                },
                "size": {
                    "value": {
                        "min": 3,
                        "max": 5
                    }
                }
            },
            "detectRetina": true



        }}>

        </Particles>
    )
}

