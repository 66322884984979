import React, { useContext } from 'react'
import AdminManageKeys from '../../components/AdminCodes/AdminManageKeys'
import AdminUserShortcodes from '../../components/AdminCodes/AdminUserShortcodes'
import AdminHeader from '../../components/AdminHeader/AdminHeader'
import AdminSentMessage from '../../components/AdminSentMessage/AdminSentMessage'
import AdminWaitingMessage from '../../components/AdminWaitingMessage/AdminWaitingMessage'
import NavContext from '../../contexts/NavContext'

function AdminWaitingMessageScreen(props) {
  const { navValue } = useContext(NavContext)
  return (
    <div>
      {/* <AdminHeader></AdminHeader> */}
      <div
        className={`flex h-[105vh] dark:bg-primary_color ${
          navValue.navOpen ? 'md:ml-64' : 'md:ml-16'
        } overflow-x-auto`}
      >
        {/* <div className="w-64 mx-8"></div> */}
        <AdminWaitingMessage/>
      </div>
    </div>
  )
}

AdminWaitingMessageScreen.propTypes = {}

export default AdminWaitingMessageScreen
