import { Button, Checkbox, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import KeyAssigningForm from "./KeyAssigningForm";
import { RemoveIcon } from "./icons";
import { NodeTypes } from "../IVRDesignerScreen";
import { NavigationKeyOnMenu, RenderNavigationKeyOnMenu } from "./common";
import { validateFileName } from "../../../_helpers/validateFileName";

const MenuForm = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if ((props.edit || props.viewOnly) && props.currentNode) {
      const currentNode = props.currentNode;
      form.setFieldValue("title", currentNode.value.title);
      form.setFieldValue("menuAudio", currentNode.value.menuAudio);
      form.setFieldValue("menuAudioScript", currentNode.value.menuAudioScript);
      form.setFieldValue("isKeyInterupted", currentNode.value.isKeyInterupted);

      console.log(currentNode.value.menuKey);
      if (currentNode.value.menuKey) {
        form.setFieldValue("menuKey", currentNode.value.menuKey);
      }
      // if (currentNode.value.menuOption) {
      //   form.setFieldValue("option", currentNode.value.menuOption);
      // }
    }
  }, [props.edit, props.viewOnly]);

  const onMenuSubmited = (values) => {
    if(props.edit && props.parentNode && props.parentNode.value.type == NodeTypes.menu){
      const childrenValues = props.parentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.id != props.currentNode.key && item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }
    if(props.currentNode && props.currentNode.value.type == NodeTypes.menu){
      const childrenValues = props.currentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }

    let data = {};
    if (props.edit && props.currentNode) {
      data = props.currentNode.value;
    }
    data.title = values.title;
    data.menuAudio = values.menuAudio;
    data.menuAudioScript = values.menuAudioScript;
    data.isKeyInterupted = values.isKeyInterupted;
    data.type = NodeTypes.menu;
    if (!data.childrenMenu) {
      data.childrenMenu = [];
    }
    if (!data.childrenValues) {
      data.childrenValues = [];
    }

    data.menuKey = values.menuKey;
    data.menuOption = "NEXT";

    const node = {
      key: Date.now(),
      value: data,
      parent: null,
      children: [],
    };

    if (props.edit && props.currentNode) {
      console.log(props.currentNode);
      node.parent = props.currentNode.parent;
      node.children = props.currentNode.children;
      node.key = props.currentNode.key;
    }
    props.onSubmitHandler(node);
  };
  const onReset = () => {
    form.resetFields();
  };

  const renderMenuForm = () => {
    if (props.viewOnly) {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    if (!props.edit) {
      if (props.currentNode && props.currentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    } else {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    return null;
  };

  return (
    <div className="mt-5">
      <Form
        className=""
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onMenuSubmited}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              type: "string",
              message: "Title is required",
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item
          name="menuAudio"
          label="Menu Audio"
          rules={[
            {
              required: true,
              type: "string",
              message: "Menu Audio is required",
            },
            {
              validator: validateFileName
            }
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item name="menuAudioScript" label="Menu Audio Script (optional)">
          <Input.TextArea disabled={props.viewOnly} rows={5} />
        </Form.Item>
        <div className="flex justify-between">
          <Form.Item
            name="isKeyInterupted"
            isKeyInterupted
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox disabled={props.viewOnly}>Is Key Interupted ?</Checkbox>
          </Form.Item>
        </div>
        {renderMenuForm()}
        <Form.Item className="flex justify-end">
          {!props.viewOnly && (
            <Button className="bg-slate-700 text-white" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default MenuForm;
