import React, { useEffect, useState } from "react";
import {
  getUserKeysForAdmin,
  handleKeyApprovement,
  handleKeyReject,
  handleKeySuspend,
  updateUserKeyForAdmin,
  updateUserShortCode,
  updateUserShortCodeForAdmin,
} from "../../services/codeService";
import {
  Button,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
} from "antd";
import ManageShortCodeModal from "../ManageShortCode/ManageShortCodeModal";
import { useLocation, useParams } from "react-router-dom";
import AddCodeModal from "../AddCodeModal/AddCodeModal";
import axios from "axios";
import { deleteSingleOutKey } from "../../services/smsKeyService.js";
import { IoMdCheckboxOutline } from "react-icons/io";
import { ImCheckboxUnchecked } from "react-icons/im";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { BiDotsHorizontalRounded } from "react-icons/bi";

const AdminManageKeys = () => {
  const [shortCode, setShortCode] = useState([]);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [autoReply, setAutoReply] = useState(false);
  const [keyBased, setKeyBased] = useState(false);
  const [codeModalVisible, setCodeModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shortCodeData, setShortCodeData] = useState({});
  const [refresh, setRefresh] = useState(false);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 6,
    },
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { userId } = useParams();

  useEffect(() => {
    getUserShortCodes();
  }, [
    refresh,
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
  ]);

  const getUserShortCodes = () => {
    const status = ["ACTIVE", "PENDING", "INACTIVE"];

    getUserKeysForAdmin(tableParams.pagination, userId).then((resp) => {
      const short_codes = resp.data.userKeys;
      const _total = resp.data.total;
      setShortCode(short_codes);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: _total,
        },
      });
    });
  };

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };
  const openModal = (record) => {
    setShortCodeData(record);
    setVisible(true);
  };

  const statusHandler = (record) => {
    updateUserKeyForAdmin(record).then((resp) => {
      const res = resp.data;
      if (res) {
        const msg = res?.message;
        const success = res?.success;
        const err = res?.error;

        if (success && msg) {
          message.success(msg);
          setRefresh(!refresh);
        } else if (!success && err) {
          setErrorMessage(err);
        }
      }
    });
  };

  const handleApprovement = (record) => {
    console.log("record", record);

    handleKeyApprovement(record).then((resp) => {
      const res = resp.data;
      console.log("approvement", resp);
      const success = resp.data.success;
      const msg = resp.data.message;
      const errMsg = resp.data.error;

      if (success) {
        message.success(msg);
        setRefresh(!refresh);
      } else {
        setErrorMessage(errMsg);
        message.error(errMsg);
      }
      // if (res) {
      //   const msg = res?.message
      //   const success = res?.success
      //   const err = res?.error

      //   if (success && msg) {

      //   } else if (!success && err) {

      //   }
      // }
    });
  };

  const handleSuspend = (record) => {
    handleKeySuspend(record).then((resp) => {
      const res = resp.data;
      console.log("approvement", resp);
      const success = resp.data.success;
      const msg = resp.data.message;
      const errMsg = resp.data.error;

      if (success) {
        message.success(msg);
        setRefresh(!refresh);
      } else {
        setErrorMessage(errMsg);
        message.error(errMsg);
      }
      // if (res) {
      //   const msg = res?.message
      //   const success = res?.success
      //   const err = res?.error

      //   if (success && msg) {

      //   } else if (!success && err) {

      //   }
      // }
    });
  };

  const handleReject = (record) => {
    handleKeyReject(record).then((resp) => {
      const res = resp.data;
      if (res) {
        const msg = res?.message;
        const success = res?.success;
        const err = res?.error;

        if (success && msg) {
          message.success(msg);
          setRefresh(!refresh);
        } else if (!success && err) {
          setErrorMessage(err);
        }
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const updateShortCode = (values) => {
    setLoading(true);

    updateUserShortCode(shortCodeData._id, values)
      .then((resp) => {
        const res = resp.data;
        if (res) {
          const msg = res?.message;
          const success = res?.success;
          const err = res?.error;

          if (success && msg) {
            message.success(msg);
            setVisible(false);
            setRefresh(!refresh);
          } else if (!success && err) {
            setErrorMessage(err);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error);
        setLoading(false);
      });
  };

  const confirm = (record) => {
    console.log("record", record);
    deleteSingleOutKey(record.id).then((data) => {
      if (data.data) {
        message.success(data.data.message);
        setRefresh(!refresh);
      }
    });
  };

  const cancel = (e) => {
    console.log(e);
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  const updateCodes = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/short_codes${id ? "?id=" + id : ""}`
      )
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "ShortCode",
      dataIndex: "value",
      key: "value",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Key Name",
      dataIndex: "key",
      key: "key",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Code Id",
      dataIndex: "codeId",
      key: "codeId",
      render: (text) => <a>{text}</a>,
    },

    // {
    //   title: 'status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (status) => (
    //     <span className='uppercase flex justify-center'>

    //       {status === "WAITING" ? "WATING FOR APPROVAL" : status}
    //     </span>
    //   ),
    // },
    {
      title: "status",
      dataIndex: ["isActive", "status"],
      key: "isActive",
      render: (text, row) => (
        <a title={row["status"]}>
          {row["isActive"] === 1
            ? row["status"] === "WAITING"
              ? "WAITING FOR APPROVAL"
              : row["status"]
            : "SUSPEND"}
          {/* {row["status"]}  {String(row["isActive"])} */}
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Dropdown
            className="-mr-6"
            overlay={() => menu(record)}
            trigger={["click"]}
          >
            <Button
              className="flex justify-center items-center"
              type="link"
              onClick={(e) => e.preventDefault()}
            >
              ...
              {/* <DownOutlined /> */}
            </Button>
          </Dropdown>
          {/* <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => confirm(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >

            <Button danger>Delete</Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  const menu = (record) => (
    <Menu>
      {record.status === "APPROVED" && record.isActive === true ? (
        ""
      ) : (
        <Menu.Item key="1">
          <Button
            onClick={() => {
              const { id } = record;
              handleApprovement({ id, status: "APPROVED" });
            }}
            type="link"
          >
            APPROVED
          </Button>
        </Menu.Item>
      )}

      {record.isActive === false ? (
        ""
      ) : (
        <Menu.Item key="1">
          <Button
            onClick={() => {
              const { id } = record;
              handleApprovement({ id, status: "SUSPENDED" });
            }}
            type="link"
          >
            SUSPEND
          </Button>
        </Menu.Item>
      )}

      {record.status === "REJECTED" && record.isActive === true ? (
        ""
      ) : (
        <Menu.Item key="2">
          <Button
            onClick={() => {
              const { id } = record;
              handleApprovement({ id, status: "REJECTED" });
            }}
            type="link"
          >
            REJECT
          </Button>
        </Menu.Item>
      )}

      <Menu.Item key="3">
        <Popconfirm
          placement="bottomRight"
          title={`Are you sure to delete the key?`}
          okButtonProps={{
            style: {
              backgroundColor: "transparent",
              borderColor: "#e5e7eb",
              color: "black",
            },
          }}
          cancelButtonProps={{
            style: {
              backgroundColor: "transparent",
              borderColor: "#e5e7eb",
              color: "black",
            },
          }}
          // description={description}
          onConfirm={() => confirm(record)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link">DELETE</Button>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="w-full h-full flex flex-col  p-4">
      <h2 className="text-2xl text-gray-700 capitalize m-2">manage keys </h2>

      {visible && (
        <ManageShortCodeModal
          visible={visible}
          handleOk={updateShortCode}
          handleCancel={handleCancel}
          onFinishFailed={onFinishFailed}
          setKeyBased={setKeyBased}
          keyBased={keyBased}
          autoReply={autoReply}
          setAutoReply={setAutoReply}
          loading={loading}
          shortCodeData={shortCodeData}
        />
      )}

      <div className="w-full h-full my-5 p-3">
        {
          /* add code modal */
          <AddCodeModal
            updateCodes={updateCodes}
            visible={codeModalVisible}
            setVisible={setCodeModalVisible}
          />
        }
        <Table
          columns={columns}
          dataSource={shortCode}
          bordered
          onChange={handleTableChange}
          pagination={tableParams.pagination}
        />
      </div>
    </div>
  );
};

export default AdminManageKeys;
