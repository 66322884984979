import React, { useState } from "react";
import { NodeTypes } from "./IVRDesignerScreen";
import {
  AddIcon,
  CheckIcon,
  CopiedIcon,
  CopyIcon,
  EditIcon,
  EyeIcon,
  GroupIcon,
  RecordIcon,
  RemoveIcon,
  RepeatIcon,
  UpIcon,
} from "./forms/icons";

const NodeUI = (props) => {
  const handleNodeClick = props.handleNodeClick;
  const nodeDatum = props.nodeDatum;

  const [isKeyCopied, setIsKeyCopied] = useState(false);

  const displayNodeTitle = (node) => {
    switch (node.value.type) {
      case NodeTypes.record:
        return RecordIcon;
      case NodeTypes.listenMenuAgain:
        return RepeatIcon;
      case NodeTypes.conference:
        return GroupIcon;
      case NodeTypes.back:
        return UpIcon;
    }

    return node.value.title;
  };

  const displayAddButton = (nodeDatum) => {
    let canAdd = false;
    switch (nodeDatum.value.type) {
      case NodeTypes.menu: {
        canAdd = true;
        break;
      }
      case NodeTypes.greeting: {
        if (
          (nodeDatum.children && nodeDatum.children.length == 0) ||
          !nodeDatum.children
        ) {
          canAdd = true;
        }
      }
    }
    if (!canAdd) return null;
    return (
      <button onClick={() => handleNodeClick("ADD", nodeDatum)}>
        {AddIcon}
      </button>
    );
  };

  const getNodeNameFromType = (type) => {
    switch (type) {
      case NodeTypes.menu:
        return "Menu";
      case NodeTypes.greeting:
        return "Greeting";
      case NodeTypes.info:
        return "Information";
      case NodeTypes.changeLanguage:
        return "Change Language";
      case NodeTypes.confirmation:
        return "Confirmation";
      case NodeTypes.record:
        return "Record";
      case NodeTypes.transfer:
        return "Transfer";
      case NodeTypes.listenMenuAgain:
        return "Listen Menu Again";
      case NodeTypes.conference:
        return "Conference";
      case NodeTypes.back:
        return "Back";
    }
  };

  return (
    <g>
      <foreignObject x={-150} width={300} height={150} className="">
        <div className="flex group justify-center ">
          <div className="flex shadow-lg justify-around items-center px-8 py-2  bg-[#0099ff] text-white   rounded-md ">
            <div>
              <h3 className="font-bold capitalize text-white">
                {displayNodeTitle(nodeDatum)}
              </h3>

              <div className=" text-white text-xs mt-1 ">
                {getNodeNameFromType(nodeDatum.value.type)}
              </div>
              <div className=" text-white text-xs mt-1 ">
                {nodeDatum.value.menuKey && `Key : ${nodeDatum.value.menuKey}`}
              </div>
            </div>
            <div>
              {!props.viewOnly && (
                <div className="ml-10 flex gap-2 ">
                  {displayAddButton(nodeDatum)}
                  <button onClick={() => handleNodeClick("EDIT", nodeDatum)}>
                    {EditIcon}
                  </button>
                  {
                    <button
                      onClick={() => handleNodeClick("REMOVE", nodeDatum)}
                    >
                      {RemoveIcon}
                    </button>
                  }
                </div>
              )}

              {props.viewOnly && (
                <div className="ml-10 flex gap-2 ">
                  <button
                    onClick={() => {
                      props.handleNodeClick("VIEW", nodeDatum);
                    }}
                  >
                    {EyeIcon}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {!props.viewOnly && (
          <div className="flex items-center justify-center mt-3">
            <div className=" text-xs mt-1">{nodeDatum.key}</div>
            <button
              className="ml-3  flex items-center"
              onClick={() => {
                navigator?.clipboard?.writeText(nodeDatum.key).then(() => {
                  setIsKeyCopied(true);
                  setTimeout(() => {
                    setIsKeyCopied(false);
                  }, 1000);
                });
              }}
            >
              {CopyIcon}
              {isKeyCopied && (
                <div className="flex items-center text-xs">Copied!</div>
              )}
            </button>
          </div>
        )}
      </foreignObject>
    </g>
  );
};

export default NodeUI;
