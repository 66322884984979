import { useCallback, useEffect, useState } from "react";

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2.3, y: 1  });
    }
  }, []);
  return [translate, containerRef];
};

export const useWindowSize = () => {
  const [screen, setScreen] = useState(() => {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  });

  const handleResize = () => {
    setScreen({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    document.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("resize", handleResize);
    };
  }, []);

  return screen;
};
