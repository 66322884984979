import { Button, Form, Input, Table, Upload, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { values } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import * as ivrService from "../../../services/ivrService";
import { MdOutlineDelete } from "react-icons/md";

const UploadAudios = ({ record, onCancelHandler }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 2;
  const [count, setCount] = useState(0);

  const onFinish = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("file", values.file);
    formData.append("filePath", record.audioFilePath);
    ivrService
      .uploadAudio(formData)
      .then((resp) => {
        const success = resp.data.success;
        if (!success) {
          message.error(resp.data.error);
        } else {
          form.setFieldValue("name", "");
          form.setFieldValue("file", null);
          setFileList([]);
          message.success(resp.data.message);
          loadUploadedAudios();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChange = (info) => {
    setFileList(info.fileList);
  };

  const [form] = useForm();
  const uploadRef = useRef(null);

  const columns = [
    {
      title: "Name",
      render: (_, record) => {
        return <span>{record.name}</span>;
      },
    },
    {
      title: "Status",
      render: (_, record) => {
        return <span>{record.monoStatus}</span>;
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <span>
            <Button
              onClick={() => {
                ivrService.deleteAudioUploaded(record._id).then((resp) => {
                  const success = resp.data.success;
                  if (success) {
                    message.success(resp.data.message);
                    loadUploadedAudios();
                  }
                });
              }}
              danger
              icon={<MdOutlineDelete />}
              className="flex items-center gap-x-2"
            >
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    loadUploadedAudios();
  }, [page]);

  const loadUploadedAudios = () => {
    ivrService.getUploadedAudios(record._id, page - 1, limit).then((resp) => {
      const success = resp.data.success;
      if (success) {
        setData(resp.data.data.audios);
        setCount(resp.data.data.count);
      }
    });
  };
  return (
    <div>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="name"
          name="name"
          rules={[
            {
              required: true,
              message: "name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="file"
          rules={[
            {
              required: true,
              message: "Audio is required",
            },
          ]}
        >
          <Upload
            // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
            listType="text"
            accept=".wav"
            multiple={false}
            maxCount={1}
            ref={uploadRef}
            onChange={handleChange}
            beforeUpload={(file) => {
              console.log("file", file);
              form.setFieldsValue({ file }); // Set the file to the form field
              return false;
            }}
            onRemove={(file) => {
              form.setFieldsValue({ file: null });
              setFileList([]);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>{" "}
          <div className="flex justify-end mt-4">
            <Form.Item className=" ">
              <Button loading={loading} type="primary" htmlType="submit">
                {loading ? "Uploading..." : "Submit"}
              </Button>
            </Form.Item>
          </div>
        </Form.Item>
      </Form>

      <Table
        pagination={{
          pageSize: limit,
          total: count,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
        }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default UploadAudios;
