import React from 'react'
import Routing from './Routing'
import AuthHeader from './components/AuthHeader/AuthHeader'
import AdminHeader from './components/AdminHeader/AdminHeader'
import AdminRouting from './AdminRouting'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const RoutingLayout = () => {
  const location = useLocation();
  const _user = JSON.parse(localStorage.getItem("user"));
  const user = _user?.user
  console.log("user role....: ", user)
  let userRole = user?.role
  return (
    <div>
      {
        userRole === "IVR_USER" &&
        <div>
          <AuthHeader />
          <Routing />
        </div>
      }
      {
        userRole === "IVR_ADMIN" &&
        <div>
          <AdminHeader />
          <AdminRouting />
        </div>

      }


      {/* <AdminHeader>
        <AdminRouting />
      </AdminHeader> */}


    </div>
  )
}

export default RoutingLayout