import axiosDefault from "./axiosDefault";

const API_URL = process.env.REACT_APP_API_URL;

export const createIVRHandler = (body) => {
  return axiosDefault.post(API_URL + "/ivr_tree", body);
};
export const updateIVRHandler = (id, body) => {
  return axiosDefault.put(API_URL + "/ivr_tree" + `/${id}`, body);
};
export const getSingleIVRHAndler = (id) => {
  return axiosDefault.get(API_URL + `/ivr_tree/single/${id}`);
};
export const deleteIVRHandler = (id) => {
  return axiosDefault.delete(API_URL + `/ivr_tree/${id}`);
};
export const getUserShortCodes = (userId) => {
  return axiosDefault.get(API_URL + `/ivr_tree/ivr_code/${userId}`);
};

export const approveIVR = (ivrId, action) => {
  return axiosDefault.put(
    API_URL + `/ivr_tree/approve/${ivrId}?action=${action}`
  );
};
export const closeIVR = (ivrId) => {
  return axiosDefault.put(API_URL + `/ivr_tree/close/${ivrId}`);
};

export const cloneIVR = (ivrId) => {
  return axiosDefault.put(API_URL + `/ivr_tree/clone/${ivrId}`);
};
export const getIVRsHandler = (userId, page, limit) => {
  const query = [];
  if (page !== undefined) {
    query.push(`page=${page}`);
  }
  if (limit !== undefined) {
    query.push(`limit=${limit}`);
  }

  return axiosDefault.get(API_URL + `/ivr_tree/${userId}?${query.join("&")}`);
};

export const uploadAudio = (data) => {
  return axiosDefault.post(API_URL + `/ivr_tree/upload`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getUploadedAudios = (id, page, limit) => {
  const query = [];
  if (page !== undefined) {
    query.push(`page=${page}`);
  }
  if (limit !== undefined) {
    query.push(`limit=${limit}`);
  }
  return axiosDefault.get(
    API_URL + `/ivr_tree/${id}/upload?${query.join("&")}`
  );
};

export const deleteAudioUploaded = (id) => {
  return axiosDefault.delete(API_URL + `/ivr_tree/${id}/upload`);
};
