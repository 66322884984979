import { Button, Form, Input, message } from "antd";
import React, { useEffect } from "react";
import { NodeTypes } from "../IVRDesignerScreen";
import { RenderNavigationKeyOnMenu } from "./common";

const ConfirmationForm = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if ((props.edit || props.viewOnly) && props.currentNode) {
      const currentNode = props.currentNode;
      form.setFieldValue("title", currentNode.value.title);
      if (currentNode.value.menuKey) {
        form.setFieldValue("menuKey", currentNode.value.menuKey);
      }
      if (currentNode.value.nextId) {
        form.setFieldValue("nextId", currentNode.value.nextId);
      }
    }
  }, [props.edit, props.viewOnly]);

  const onSubmit = (values) => {

    if(props.edit && props.parentNode && props.parentNode.value.type == NodeTypes.menu){
      const childrenValues = props.parentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.id != props.currentNode.key && item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }
    if(props.currentNode && props.currentNode.value.type == NodeTypes.menu){
      const childrenValues = props.currentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }

    let data = {};
    let _keyId = Date.now();

    if (props.edit && props.currentNode) {
      data = props.currentNode.value;
      _keyId = props.currentNode.key;
    }

    data.title = values.title;
    data.type = NodeTypes.confirmation;

    data.menuKey = values.menuKey;
    data.menuOption = "NEXT";
    data.nextId = values.nextId;

    const node = {
      key: _keyId,
      value: data,
      parent: null,
      children: [],
    };

    if (props.edit && props.currentNode) {
      node.parent = props.currentNode.parent;
      node.children = props.currentNode.children;
      node.key = props.currentNode.key;
    }
    props.onSubmitHandler(node);
  };

  const onReset = () => {
    form.resetFields();
  };

  const renderMenuForm = () => {
    if (props.viewOnly) {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    if (!props.edit) {
      if (props.currentNode && props.currentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    } else {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    return null;
  };

  return (
    <div className="mt-5">
      <Form
        className=""
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              type: "string",
              message: "Title is required",
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>

        {renderMenuForm()}
        <Form.Item name="nextId" label="Next ID (optional)">
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item className="flex justify-end">
          {!props.viewOnly && (
            <Button className="bg-slate-700 text-white" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConfirmationForm;
