import React from 'react'
import SignUp from '../../components/SignUp/SignUp'
function SignUpScreen(props) {
  return (
    <div className=''>
      <SignUp></SignUp>
    </div>
  )
}

SignUpScreen.propTypes = {}

export default SignUpScreen
