import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'antd/lib/modal/Modal'
import { Input, Form, Button, message, Select, Checkbox } from 'antd'
import { addCode } from '../../services/codeService'


function AddCodeModal(props) {

  const [usageType, setUsageType] = useState('')
  const [isShareableClicked,setIsShareableClicked] = useState(false)
  const [isAplhanumericClicked,setISAlphanumericClicked] = useState(false)
  const [usageTypes,setUsageTypes] = useState('')
  const [isAutoReplyClicked,setIsAutoReplyClicked] = useState(false)
  const [errorMessage,setErrorMessage] = useState("")
  const handleOk = () => {
    props.setVisible(false)
  }

  const handleCancel = () => {
    props.setVisible(false)
    setErrorMessage('')
  }


  const uasageTypeHandler = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("values",values)
    if(isShareableClicked === true && (usageTypes ==='BOTH' || usageTypes==='IVR_ONLY')){
      setErrorMessage( "you can not add a shareable number with usage type IVR" )
      return
  }
    if((!isAplhanumericClicked && isShareableClicked) ){
      setErrorMessage('you can not add a shareable number that does not support alphanumberic')
      return
    }
   
    addCode({ ...values, type: 'SHORT_CODE' })
      .then((res) => {

        const msg = res.data.message
        const success = res.data.success
        const error = res.data.error
        if (success) {
          message.success(msg)
          props.updateCodes()
          form.resetFields();
          props.setVisible(false)
          props.setRefresh(!props.refresh)
          setErrorMessage('')
        } else {
          // message.error(error)
          setErrorMessage(error)
        }
      })
      .catch((err) => console.log(err))
  }

  // const   handleChange = (value) => {
  //   console.log("value",value)
  //   if(value === 'IVR_ONLY'){
  //     console.log("call")
  //     // we set disable alphnm , shareable ,is auto
  //     setIsShareableClicked(false)
  //     setISAlphanumericClicked(false)
  //     setIsAutoReplyClicked(false)
  //     document.getElementById("myCheck").disabled = true;
  //   }else{
  //     // enable all
  //   }
  //   setUsageType(value)
  // }

  const shareableChangeHandler = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setIsShareableClicked(e.target.checked)
  };

  const autoReplyChangeHandler = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setIsAutoReplyClicked(e.target.checked)
  };

  

  const AplaNumberChangeHandler = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setISAlphanumericClicked(e.target.checked)
  };

  const msgChangeHandler = (e) => {
    console.log(`checked = ${e}`);
    // setMsg(e.target.checked)
    setUsageTypes(e)
  };


  const [form] = Form.useForm();

  return (
    <div>
      <Modal
        destroyOnClose={true}
        title="Add Short Code"
        visible={props.visible}
        setVisible={props.setVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        
      >
        <Form
          name="basic"
          layout="vertical"
          onFinishFailed={() => { }}
          form={form}
          preserve={false}
          onFinish={onFinish}
          initialValues={{ usageType: 'MESSAGE_ONLY' ,isAlphaNumeric:false,autoReply:false,isShareable:false}}
          className="flex-col justify-between"
        >

          {errorMessage && <span className='text-red-400'>{errorMessage}</span>}
          <div className="w-full my-1">
            <Form.Item
              name="value"
              label="Code"
              rules={[{ required: true, message: 'Code is required' }]}
            >
              <Input placeholder="Add Short Code" maxLength={13} />
            </Form.Item>
          </div>
          <div className="w-full my-1">
            <Form.Item name="usageType" label="Usage Type">
              <Select
                // onChange={handleChange}
                onChange={msgChangeHandler}
              >
                <Select.Option value="">Select Code Type</Select.Option>
                <Select.Option value="MESSAGE_ONLY">Message Only</Select.Option>
                <Select.Option value="IVR_ONLY">IVR Only</Select.Option>
                <Select.Option value="BOTH">Both</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className='flex flex-col gap-3 '>


            <div className='flex justify-around '>
              <div className='flex gap-3'>
                <p className='dark:text-white'> Is Alphanumberic?</p>
                <Form.Item
                  layout="horizontal"
                  className=" mr-1 flex "
                  valuePropName="checked"
                  name="isAlphaNumeric"
                  
                >
                  <Checkbox
                  onChange={AplaNumberChangeHandler}
                    className="flex ml-4"
                    id='myCheck'
                  />
                </Form.Item>
              </div>
              <div className='flex gap-3 '>
                <p className='dark:text-white'> Is Shareable?</p>
                <Form.Item
                  layout="horizontal"
                  className=" mr-1 flex "
                  valuePropName="checked"
                  name="isShareable"
                 
                >
                  <Checkbox
                   onChange={shareableChangeHandler}
                    className="flex ml-4"
                    id='myCheck'
                  />
                </Form.Item>
              </div>


            </div>
            <div className='flex gap-10 pl-11'>
              <p className='dark:text-white'> Is AutoReply?</p>
              <Form.Item
                layout="horizontal"
                className=" mr-1 flex "
                valuePropName="checked"
                name="autoReply"
                >
                <Checkbox
                onChange={autoReplyChangeHandler}
               
                  className="flex ml-4"
                  id='myCheck'
                />
              </Form.Item>
            </div>


          </div>


          <Form.Item className="w-full my-1">
            <Button
              type="primary"
              htmlType="submit"
              placeholder="Add Short Code"
              className="w-full dark:border dark:border-gray-300"
              style={{
                backgroundColor: "#131D34",
                borderColor: "#131D34",
                borderRadius: "10px",
                color: "white"
              }}
            >
              Save Code
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

AddCodeModal.propTypes = {
  updateCodes: PropTypes.func,
  setVisible: PropTypes.func,
  visible: PropTypes.bool
}

export default AddCodeModal
