import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createRoot } from 'react-dom/client';
import Loader from './components/Loader/Loader';

import ReactLoading from 'react-loading';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
   
      <App />
    
  </React.StrictMode>
);


reportWebVitals()
