import { Button, Divider, Dropdown, Menu, Space, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  FaLink,
  FaPoll,
  FaPowerOff,
  FaRegUser,
  FaUserCircle,
  FaUserFriends,
  FaBars,
  FaTimesCircle,
  FaMoon,
  FaChartBar,
  FaInfo,
  FaSms,
  FaRegFileAudio,
  FaBoxTissue,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import "./AuthHeader.css";
import NavContext from "../../contexts/NavContext";
import ThemeContext from "../../contexts/ThemeContext";
import {
  BiCategoryAlt,
  BiEnvelope,
  BiEnvelopeOpen,
  BiMessageRoundedCheck,
  BiMessageRoundedDots,
  BiPhoneCall,
  BiSend,
} from "react-icons/bi";
import ListBorder from "../ListBorder/ListBorder";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { DownOutlined } from "@ant-design/icons";
import { FaKey } from "react-icons/fa";
import { ImKey2 } from "react-icons/im";
import BillingMenu from "./BillingMenu";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardVoice,
  MdOutlineVpnKey,
  MdRecordVoiceOver,
} from "react-icons/md";
import { TbMessage2Share } from "react-icons/tb";
import { GrContactInfo } from "react-icons/gr";
import { AiOutlineComment } from "react-icons/ai";
import { RiCustomerService2Line, RiLockPasswordLine } from "react-icons/ri";
import { IoMdBarcode } from "react-icons/io";
import { getProfile } from "../../services/profileService";
import TaskDisbaledModal from "../TaskDisabledModal/TaskDisbaledModal";

const getItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

function AuthHeader(props) {
  const history = useHistory();
  const location = useLocation();
  const _user = JSON.parse(localStorage.getItem("user"));
  const user = _user?.user;
  const [activeMenu, setActiveMenu] = useState(location.pathname);
  const { navValue, setNavValue } = useContext(NavContext);
  const { theme, setTheme } = useContext(ThemeContext);
  const [current, setCurrent] = useState("SubMenu");
  const [showBillingMenu, setShowBillingMenu] = useState(false);
  const [profile, setProfile] = useState()
  const [openIssueDiabled, setOpenIssueDiabled] = useState(false)

  console.log("profile", profile)

  useEffect(() => {
    setActiveMenu(location.pathname);
    fetchProfile()
  }, [location.pathname]);

  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  const fetchProfile = () => {
    getProfile()
      .then((data) => {
        console.log("data : ", data.data)
        setProfile(data.data)

      })
      .catch((err) => console.log(err))
  }



  const mainMenu = (
    <Menu
      defaultSelectedKeys={[activeMenu]}
      mode="vertical"
      theme="light"
      className="w-screen my-6 px-4 bg-blue-100 text-md"
    >
      {/* <Menu.Item className="text-lg" key="/short_codes_graphs">
        <Link to="/short_codes_graphs"> IVR Designs</Link>
      </Menu.Item>
      <Menu.Item className="text-lg" key="/short_codes">
        <Link to="/short_codes"> IVR Designs</Link>
      </Menu.Item> */}

      <Menu.Item
        className="text-lg"
        key="/dashboard"
        icon={<FaChartBar className="inline mr-4" />}
      >
        <Link to="/dashboard"> Dashboard</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/sentmessage"
        icon={<BiMessageRoundedCheck className="inline mr-4" />}
      >
        <Link to="/sentmessage"> Sent Messages</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/bulk_message"
        icon={<BiEnvelope className="inline mr-4" />}
      >
        <Link to="/bulk_message"> Bulk Message</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/manage-audio"
        icon={<FaRegFileAudio className="inline mr-4" />}
      >
        <Link to="/manage-audio" className="capitalize">
          {" "}
          manage audio
        </Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/audio-message"
        icon={<FaRegFileAudio className="inline mr-4" />}
      >
        <Link to="/audio-message"> Audio Message</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/upload-audio"
        icon={<BiEnvelope className="inline mr-4" />}
      >
        <Link to="/upload-audio"> Upload Audio</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/call_log"
        icon={<BiPhoneCall className="inline mr-4" />}
      >
        <Link to="/call_log"> Call Log</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/ctmr-ivr-lng"
        icon={<RiCustomerService2Line className="inline mr-4" />}
      >
        <Link to="/ctmr-ivr-lng"> Callers</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/category_config"
        icon={<BiCategoryAlt className="inline mr-4" />}
      >
        <Link to="/category_config"> Category Config</Link>
      </Menu.Item>

      {/* <Menu.Item
        className="text-lg"
        key="/customer_info"
        icon={<FaInfo className="inline mr-4" />}
      >
        <Link to="/customer_info"> Customer Info</Link>
      </Menu.Item> */}

      <Menu.Item
        className="text-lg"
        key="/user_comment"
        icon={<AiOutlineComment className="inline mr-4" />}
      >
        <Link to="/user_comment">User Comment</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/manage_short_codes"
        icon={<IoMdBarcode className="inline mr-4" />}
      >
        <Link to="/manage_short_codes"> Manage SMS Short Code</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/csv-category"
        icon={<IoMdBarcode className="inline mr-4" />}
      >
        <Link to="/csv-category"> Category CSV</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/assign_ivr_short_codes"
        icon={<MdRecordVoiceOver className="inline mr-4" />}
      >
        <Link to="/assign_ivr_short_codes"> Assign Ivr Short Code</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/watingmessage"
        icon={<BiMessageRoundedDots className="inline mr-4" />}
      >
        <Link to="/waitingmessage"> Waiting Messages</Link>
      </Menu.Item>

      {/* <Menu.Item
        className="text-lg"
        key="/message"
        icon={<BiSend className="inline mr-4" />}
      >
        <Link to="/message"> Send Message</Link>
      </Menu.Item> */}
      <Menu.Item
        className="text-lg"
        key="/incomingmessage"
        icon={<BiEnvelopeOpen className="inline mr-4" />}
      >
        <Link to="/incomingmessage"> Incoming Messages</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/poll"
        icon={<FaPoll className="inline mr-4" />}
      >
        <Link to="/poll">Polls</Link>
      </Menu.Item>
      <Menu.Item
        className="text-lg"
        key="/redirect"
        icon={<FaLink className="inline mr-4" />}
      >
        <Link to="/redirect">Redirect</Link>
      </Menu.Item>
      <Menu.Item
        className="text-lg"
        key="/conference"
        icon={<FaUserFriends className="inline mr-4" />}
      >
        <Link to="/conference">Conference</Link>
      </Menu.Item>

      <Menu.Item
        className="text-lg"
        key="/report"
        icon={<HiOutlineDocumentReport className="inline mr-4" />}
      >
        <Link to="/report">Report</Link>
      </Menu.Item>

      {/* <Menu.Item
        className="text-lg"
        key="/profile"
        icon={<FaRegUser className="inline mr-4" />}
      >
        <Link to="/profile">Account Management</Link>
      </Menu.Item> */}
      <Menu.Item
        className="text-lg"
        onClick={() => {
          localStorage.clear("user");
          history.push("/login");
        }}
        key="1"
      >
        Log Out
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu className="dark:bg-primary_color dark:text-white w-56 ">
      <Menu.Item
        className="py-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color hover:bg-gray-300 dark:hover:text-blueGray-500"
        key="/profile"
        icon={<FaRegUser className="inline mr-4" />}
      >
        <Link to="/profile">Profile</Link>
      </Menu.Item>

      <Divider className="my-1" />

      <Menu.Item
        onClick={() => {
          history.push("/api-key");
        }}
        key="20"
        className="py-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<FaKey className="inline mr-4" />}
      >
        Api token and Key
      </Menu.Item>
      <Divider className="my-1 " />
      <Menu.Item
        onClick={() => {
          if (!profile) {
            setOpenIssueDiabled(true)
            return
          }
          if (profile && !profile.isTaskManagementEnabled) {
            setOpenIssueDiabled(true)
            return
          }
          history.push("/issue-board");
        }}
        key="412"
        className="py-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<FaBoxTissue className="inline mr-4" />}
      >
        Issue board
      </Menu.Item>
      <Divider className="my-1 " />

      <Menu.Item
        onClick={() => {
          history.push("/sms-compaign");
        }}
        key="20"
        className="py-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<FaSms className="inline mr-4" />}
      >
        SMS Campaign
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          history.push("/csv-category");
        }}
        key="20"
        className="py-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<FaSms className="inline mr-4" />}
      >
        Category import logs
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          history.push("/obd-compaign");
        }}
        key="20"
        className="py-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<FaRegFileAudio className="inline mr-4" />}
      >
        OBD Campaign
      </Menu.Item>
      <Divider className="my-1 " />

      <Menu.Item
        onClick={() => {
          history.push("/incomming_key");
        }}
        key="21"
        className="pt-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<ImKey2 className="inline mr-4" />}
      >
        Manage Incoming key
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          history.push("/outgoing_key");
        }}
        key="22"
        className="pt-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<MdOutlineVpnKey className="inline mr-4" />}
      >
        Manage Outgoing key
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          history.push("/audio-upload-log");
        }}
        key="22"
        className="pt-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<MdOutlineVpnKey className="inline mr-4" />}
      >
        OBD Audio Upload Logs
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          history.push("/change_password");
        }}
        key="23"
        className="pt-2 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color  hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<RiLockPasswordLine className="inline mr-4" />}
      >
        Change Password
      </Menu.Item>

      <Divider className="my-3 " />

      <Menu.Item
        onClick={() => {
          localStorage.removeItem("user");
          history.push("/login");
        }}
        key="24"
        className="pb-4 dark:text-white dark:hover:bg-primary_color dark:bg-primary_color hover:bg-gray-300 dark:hover:text-blueGray-500"
        icon={<FaPowerOff className="inline mr-4" />}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );

  const toggleBillingMenu = () => {
    setShowBillingMenu(!showBillingMenu);
    console.log(showBillingMenu);
  };
  return (
    <div className={`h-full self-stretch`}>
      <TaskDisbaledModal open={openIssueDiabled} setOpen={setOpenIssueDiabled} />
      <div
        className={`dark:bg-primary_color relative w-full px-4 sm:px-6 ${theme === "dark" ? "border-b border-gray-600" : "shadow-md"
          } bg-gradient-to-l`}
      >
        {showBillingMenu && <BillingMenu />}
        <div className="flex justify-between items-center  py-6 md:justify-start md:space-x-10">
          <div className="flex items-center justify-start lg:w-0 lg:flex-1">
            <a href="/">
              {/* <span className="sr-only">Workflow</span> */}
              {/* <img
                className="h-8 w-auto sm:h-10"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                alt=""
              /> */}
            </a>{" "}
            <span className="text-primary_color dark:text-white text-xl uppercase ml-4 font-bold">
              YEGNATELE
            </span>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Dropdown trigger={["click"]} overlay={mainMenu}>
              <Button
                className="rounded-md p-2 inline-flex items-center justify-center text-primary_color hover:text-blueGray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded="false"
              >
                <span className="sr-only">Open menu</span>
                <FaBars className="text-xl" />
              </Button>
            </Dropdown>
          </div>

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <div
              className="flex justify-between items-center w-20 p-1 cursor-pointer"
              onClick={toggleBillingMenu}
            >
              <h2 className="text-base font-medium text-primary_color dark:text-white hover:text-blueGray-500 dark:hover:text-blueGray-300">
                Billing
              </h2>
              <span className="w-4 h-4 flex items-center justify-center">
                {showBillingMenu ? (
                  <MdOutlineKeyboardArrowUp className="w-4 h-4" />
                ) : (
                  <MdOutlineKeyboardArrowDown className="w-4 h-4" />
                )}
              </span>
            </div>

            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                type="text"
                className="flex focus:border-primary_color dark:border-primary_color items-center justify-between dark:focus:border-white focus:border whitespace-nowrap text-base font-medium text-primary_color dark:text-white hover:text-blueGray-500 dark:hover:text-blueGray-300"
              >
                <FaUserCircle className="text-2xl ml-4 pr-2" />
                <span className="capitalize">{user.name}</span>
                <DownOutlined className="pl-2" />
              </Button>
            </Dropdown>

            <div className="flex items-center p-1">
              <FaMoon className="mr-1 text-lg dark:text-white" />
              <Switch
                onClick={(value) => {
                  if (value) setTheme("dark");
                  else setTheme("light");
                }}
                checkedChildren="On"
                unCheckedChildren="Off"
                defaultChecked={theme === "dark" ? true : false}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`bg-white dark:bg-primary_color absolute hidden md:block ${theme === "dark" ? "shadow-xlw" : "shadow-xl"
          }`}
      >
        <ul
          className={`${navValue.navOpen ? "w-64" : "w-16"
            } self-stretch  h-full min-h-screenlg py-4  text-sm text-primary_color dark:text-white transition-all duration-150`}
        >
          <div className="mx-6 h-8 pt-2 text-xl uppercase font-bold">
            {navValue.navOpen ? (
              <span className="flex items-center">
                Dashboard{" "}
                <FaTimesCircle
                  onClick={() => setNavValue({ navOpen: false })}
                  className="ml-4 cursor-pointer hover:text-blueGray-500"
                />
              </span>
            ) : (
              <FaBars
                title="Open Side Bar"
                className="cursor-pointer hover:text-blueGray-500"
                onClick={() => setNavValue({ navOpen: true })}
              />
            )}
          </div>
          <Divider className="bg-gray-300 h mx-auto" />

          <Link
            to="/dashboard"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/dashboard"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/dashboard" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaChartBar title="dashboard" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Dashboard"}
            </div>
            {activeMenu === "/dashboard" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          {/* <Link
            to="/short_codes"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/short_codes"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/short_codes" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaUikit title="Short Code Config" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Short Code Config"}
            </div>
            {activeMenu === "/short_codes" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}

          <Link
            to="/sentmessage"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/sentmessage"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/sentmessage" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <BiMessageRoundedCheck
                title="Sent Messages"
                className="mr-4 text-xl"
              />{" "}
              {navValue.navOpen && "Sent Messages"}
            </div>
            {activeMenu === "/sentmessage" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/waitingmessage"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/waitingmessage"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/waitingmessage" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <BiMessageRoundedDots
                title="Waiting Messages"
                className="mr-4 text-xl"
              />{" "}
              {navValue.navOpen && "Waiting Messages"}
            </div>
            {activeMenu === "/waitingmessage" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/bulk_message"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/bulk_message"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/bulk_message" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <BiEnvelope title="Bulk Message" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Bulk Message"}
            </div>
            {activeMenu === "/bulk_message" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          {/* <Link
            to="/upload-audio"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/upload-audio"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/upload-audio" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <BiEnvelope title="Bulk Message" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Dial Outbound"}
            </div>
            {activeMenu === "/upload-audio" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}

          <Link
            to="/voice-record-list"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/voice-record-list"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/voice-record-list" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <MdOutlineKeyboardVoice
                title="Bulk Message"
                className="mr-4 text-xl"
              />{" "}
              {navValue.navOpen && "OBD Audio List"}
            </div>
            {activeMenu === "/voice-record-list" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/audio-message"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/audio-message"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/audio-message" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaRegFileAudio title="OBD Call Logs " className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "OBD Call Logs"}
            </div>
            {activeMenu === "/audio-message" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/manage-audio"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/manage-audio"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/manage-audio" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaRegFileAudio
                title="Upload OBD Audio"
                className="mr-4 text-xl"
              />{" "}
              {navValue.navOpen && "Upload OBD Audio"}
            </div>
            {activeMenu === "/manage-audio" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/call_log"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/call_log"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/call_log" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <BiPhoneCall title="Call Log" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Call Log"}
            </div>
            {activeMenu === "/call_log" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/ctmr-ivr-lng"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/ctmr-ivr-lng"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/ctmr-ivr-lng" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <RiCustomerService2Line
                title="Callers"
                className="mr-4 text-xl"
              />{" "}
              {navValue.navOpen && "Callers"}
            </div>
            {activeMenu === "/ctmr-ivr-lng" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/manage_short_codes"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/manage_short_codes"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/manage_short_codes" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <IoMdBarcode title="Manage Short Code" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Manage SMS Short Code"}
            </div>
            {activeMenu === "/manage_short_codes" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/assign_ivr_short_codes"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/assign_ivr_short_codes"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/assign_ivr_short_codes" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <MdRecordVoiceOver
                title="Assign Ivr Short Code"
                className="mr-4 text-xl"
              />{" "}
              {navValue.navOpen && "Assign Ivr Short Code"}
            </div>
            {activeMenu === "/assign_ivr_short_codes" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/category_config"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/category_config"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/category_config" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <BiCategoryAlt title="Category Config" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Category Config"}
            </div>
            {activeMenu === "/category_config" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          {/* <Link
            to="/customer_info"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/customer_info"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/customer_info" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaInfo title="Customer Info" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Customer Info"}
            </div>
            {activeMenu === "/customer_info" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}

          <Link
            to="/user_comment"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/user_comment"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/user_comment" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <AiOutlineComment title="User Comment" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "User Comment"}
            </div>
            {activeMenu === "/user_comment" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          {/* <Link
            to="/message"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === '/message'
                ? 'bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color'
                : 'hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white'
            }`}
          >
            {activeMenu === '/message' && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <BiSend title="Send Message" className="mr-4 text-xl" />{' '}
              {navValue.navOpen && 'Send Message'}
            </div>
            {activeMenu === '/message' && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}

          <Link
            to="/incomingmessage"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/incomingmessage"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/incomingmessage" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <BiEnvelopeOpen
                title="Incoming Message"
                className="mr-4 text-xl"
              />{" "}
              {navValue.navOpen && "Incoming Message"}
            </div>
            {activeMenu === "/incomingmessage" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>
          {/* <Link
            to="/rating"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === '/rating'
                ? 'bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color'
                : 'hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white'
            }`}
          >
            {activeMenu === '/rating' && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaRegStar title="Ratings" className="mr-4 text-xl" />{' '}
              {navValue.navOpen && 'Ratings'}
            </div>
            {activeMenu === '/rating' && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}
          <Link
            to="/poll"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/poll"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/poll" && <ListBorder theme={theme} type="top" />}
            <div className="flex items-center">
              <FaPoll title="Polls" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Polls"}
            </div>
            {activeMenu === "/poll" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>
          <Link
            to="/redirect"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/redirect"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/redirect" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaLink title="Redirects" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Redirects"}
            </div>
            {activeMenu === "/redirect" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>
          <Link
            to="/conference"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/conference"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/conference" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaUserFriends title="Conferences" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Conferences"}
            </div>
            {activeMenu === "/conference" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          <Link
            to="/report"
            className={`mb-10 border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${activeMenu === "/report"
              ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
              : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
              }`}
          >
            {activeMenu === "/report" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <HiOutlineDocumentReport
                title="report"
                className="mr-4 text-xl"
              />{" "}
              {navValue.navOpen && "Reports"}
            </div>
            {activeMenu === "/report" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link>

          {/* <Link
            to="/profile"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === "/profile"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
            }`}
          >
            {activeMenu === "/profile" && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaRegUser title="Profiles" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Profile"}
            </div>
            {activeMenu === "/profile" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}

          {/* <Link
            to="/audio_files"
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === '/audio_files'
                ? 'bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color'
                : 'hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white'
            }`}
          >
            {activeMenu === '/audio_files' && (
              <ListBorder theme={theme} type="top" />
            )}
            <div className="flex items-center">
              <FaFileAudio title="Global Audio Text" className="mr-4 text-xl" />{' '}
              {navValue.navOpen && 'Global Audio Text'}
            </div>
            {activeMenu === '/audio_files' && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}

          {/* <Divider className="bg-gray-300 h mx-auto" /> */}
          {/* <Link
            to=""
            onClick={() => {
              localStorage.removeItem("user");
              history.push("/login");
            }}
            className={`border border-white focus:border-primary_color dark:border-primary_color dark:focus:border-white relative my-4 ml-2 pl-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
              activeMenu === "/login"
                ? "bg-primary_color dark:bg-gray-300 hover:text-gray-300 border-r-0 text-white dark:text-primary_color"
                : "hover:text-blueGray-500 dark:hover:text-blueGray-500 text-primary_color dark:text-white"
            }`}
          >
            {activeMenu === "/login" && <ListBorder theme={theme} type="top" />}
            <div className="flex items-center">
              <FaPowerOff title="Sign Out" className="mr-4 text-xl" />{" "}
              {navValue.navOpen && "Sign Out"}
            </div>
            {activeMenu === "/login" && (
              <ListBorder theme={theme} type="bottom" />
            )}
          </Link> */}
        </ul>
      </div>
    </div>
  );
}

AuthHeader.propTypes = {};

export default AuthHeader;
