import React, { useEffect, useState } from 'react'
import { deleteShortCode, deleteUserShortCode, getUserCodes, getUserShortcodeForAdmin, updateUserShortCode, updateUserShortCodeForAdmin } from '../../services/codeService'
import { Button, message, Popconfirm, Space, Table, Tag } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import ManageShortCodeModal from '../ManageShortCode/ManageShortCodeModal';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GrFormAdd } from 'react-icons/gr';
import AddCodeModal from '../AddCodeModal/AddCodeModal';
import axios from 'axios'
import { BsCheckLg } from "react-icons/bs"
import { IoCloseSharp } from "react-icons/io5"


const AdminUserShortcodes = () => {

  const [shortCode, setShortCode] = useState([])
  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [autoReply, setAutoReply] = useState(false);
  const [keyBased, setKeyBased] = useState(false)
  const [codeModalVisible, setCodeModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shortCodeData, setShortCodeData] = useState({})
  const [refresh, setRefresh] = useState(false)

  const [localResponse, setLocalResponse] = useState([])

  const [total, setTotal] = useState(0)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 6
    }
  })
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const id = query.get('id')

  const { userId } = useParams()
  console.log()


  useEffect(() => {
    getUserShortCodes()
  }, [refresh, tableParams.pagination.current, tableParams.pagination.pageSize])


  const getUserShortCodes = () => {
    const status = ["ACTIVE", "PENDING", "INACTIVE"]

    getUserShortcodeForAdmin(tableParams.pagination, userId).then((resp) => {
      console.log("resp", resp)
      const short_codes = resp.data.shortCodes
      const _total = resp.data.total
      setShortCode(short_codes)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: _total
        }
      })
    })
  }


  const handleTableChange = (pagination) => {
    setTableParams({
      pagination
    })
  }
  const openModal = (record) => {
    console.log(record)
    setShortCodeData(record)
    setVisible(true)
  }

  const statusHandler = (record) => {
    updateUserShortCodeForAdmin(record).then((resp) => {
      const res = resp.data
      console.log("response", resp)
      if (res) {
        const msg = res?.message
        const success = res?.success
        const err = res?.error

        if (success && msg) {
          message.success(msg)
          setRefresh(!refresh)
        } else if (!success && err) {
          setErrorMessage(err)
        }
      }
    })
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const updateShortCode = (values) => {

    setLoading(true)
    console.log("", shortCodeData)
    updateUserShortCode(shortCodeData._id, values).then((resp) => {
      const res = resp.data
      if (res) {
        const msg = res?.message
        const success = res?.success
        const err = res?.error

        if (success && msg) {
          message.success(msg)
          setVisible(false)
          setRefresh(!refresh)
        } else if (!success && err) {
          setErrorMessage(err)

        }
      }
      setLoading(false)
    }).catch(error => {
      setErrorMessage(error)
      setLoading(false)
    })

  }

  const confirm = (record) => {
    console.log("record", record)
    deleteUserShortCode(record._id).then((data) => {
      console.log("data", data)
      if (data.data) {
        message.success(data.data.message)
        setRefresh(!refresh)
      }
    })

  };

  const cancel = (e) => {
    console.log(e);

  };

  const onFinishFailed = (error) => {
    console.log(error)
  }


  const updateCodes = () => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/short_codes${id ? '?id=' + id : ''}`
      )
      .then((res) => {
        setLocalResponse(res.data)
      })
      .catch((err) => console.log(err))
  }



  const columns = [
    {
      title: 'ShortCode',
      dataIndex: 'value',
      key: 'value',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'TYPE',
      dataIndex: 'usageType',
      key: 'usageType',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Is Autoreply',
      dataIndex: 'autoReply',
      key: 'autoReply',
      render: (autoReply) => (
        <span className='uppercase flex justify-center'>

          {autoReply ? <BsCheckLg className='text-green-500 text-md' /> : <IoCloseSharp className='text-red-600 text-md' />}
        </span>
      ),
    },
    {
      title: 'Is Shareable',
      dataIndex: 'isShareable',
      key: 'isShareable',
      render: (isShareable) => (
        <span className='uppercase flex justify-center'>

          {isShareable ? <BsCheckLg className='text-green-500 text-md' /> : <IoCloseSharp className='text-red-600 text-md' />}
        </span>
      ),
    },
    {
      title: 'Is Alphanumeric',
      dataIndex: 'isAlphaNumeric',
      key: 'isAlphaNumeric',
      render: (isAlphaNumeric) => (
        <span className='uppercase flex justify-center'>

          {isAlphaNumeric ? <BsCheckLg className='text-green-500 text-md' /> : <IoCloseSharp className='text-red-600 text-md' />}
        </span>
      ),
    },
    // {
    //   title: 'Auto Reply Message',
    //   dataIndex: 'autoReplyMessage',
    //   key: 'autoReplyMessage',
    //   render: (autoReplyMessage) => (<span className='flex justify-center'>{autoReplyMessage ? autoReplyMessage : "- - -"}</span>)
    // },
   
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (<span className='flex justify-center'>{isActive === 1? "Active" : "Suspend"}</span>)
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          
          <Button onClick={() => {
            statusHandler(record)
            console.log("record", record.status)
          }}>
            {`${record.isActive === 1 ?
              "Suspend"
              : "Activate"}`}
          </Button>

          {/* <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => confirm(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >

            <Button danger>Delete</Button>
          </Popconfirm> */}

        </Space>
      ),
    },
  ];

  return (
    <div className='w-full h-full flex flex-col  p-4'>
      <h2 className="text-2xl text-gray-700 capitalize m-2 dark:text-white">short codes </h2>


      {
        visible &&
        <ManageShortCodeModal
          visible={visible}
          handleOk={updateShortCode}
          handleCancel={handleCancel}
          onFinishFailed={onFinishFailed}
          setKeyBased={setKeyBased}
          keyBased={keyBased}
          autoReply={autoReply}
          setAutoReply={setAutoReply}
          loading={loading}
          shortCodeData={shortCodeData}
        />

      }

      {/* <div className="w-full flex justify-end">
        <Button
          onClick={() => setCodeModalVisible(true)}
          type="secondary"
          className="flex justify-center items-center"
        >
          Add <GrFormAdd className="text-2xl text-gray-200" />
        </Button>
      </div> */}
      <div className='w-full h-full my-5 p-3'>

        {
          /* add code modal */
          <AddCodeModal
            updateCodes={updateCodes}
            visible={codeModalVisible}
            setVisible={setCodeModalVisible}
          />
        }
        <Table
          columns={columns}
          dataSource={shortCode}
          bordered
          onChange={handleTableChange}
          pagination={tableParams.pagination}
        />
      </div>
    </div>
  )
}


export default AdminUserShortcodes;