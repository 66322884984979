import { Form, Input, Button, message, Spin } from 'antd'
import React, { useState } from 'react'
import { BiShieldQuarter } from 'react-icons/bi'
import { useHistory, useLocation } from 'react-router-dom'
import dot from '../../assets/img/dots.svg'
import { resendOTPByEmail, resendOTPCode, verifyOTPCode, verifyOTPCodeByEmail } from '../../services/userService'
import { LoadingOutlined } from '@ant-design/icons';

export default function OTPVerification() {
  const location = useLocation()
  const history = useHistory()

  const query = new URLSearchParams(location.search)
  const phoneNum = query.get('phoneNumber')
  const phoneNumber = "******" + phoneNum
  const email = query.get('email')
  const [errorMessage, setErrorMessage] = useState('')


  const [loading, setLoading] = useState(false)

  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;



  const onFinish = (values) => {
    
    setLoading(true)
    let code = ''
    Object.values(values).map(item => { return code = code + item })
    console.log(code)
    if (code.length !== 6){
      setLoading(false)
      return setErrorMessage("Invalid confirmation code.")
      // message.error('Invalid confirmation code.')
    } 

    verifyOTPCode({ phoneNumber, code }).then(res => {
      setLoading(false)
      const _success = res.data?.success
      const _message = res.data?.message
      
      if (_success) {
        message.success(_message, 1, () => {
          history.push('/login')
        })
      }

      else {
        setErrorMessage(_message)
      }

    })
      .catch((err) => {
        if (err && err.response && err.response.data.message) {
          setErrorMessage(err.response.data.message)
          // message.error(err.response.data.message)
        }
        setLoading(false)
      })
  }


  const resendOtp = () => {
    resendOTPCode({ phoneNumber }).then(resp => {
      const info = resp.data?.message
        message.success(info)
      // localStorage.setItem('user', JSON.stringify(resp.data))
      // if (resp.data.role === 'IVR_ADMIN') history.push('/admin')
      // else history.push('/')

     
    }).catch(err => {
      const errorInfo = err.response?.message
      setErrorMessage(err.response.data.message)
      // message.error(errorInfo) 
    })
  }

  const onFinishFailed = () => { }
  return (
    <div className="min-h-screen py-8">
      <div className="bg-blue-50 w-full max-w-lg  flex flex-col items-center justify-center p-4 shadow-xl rounded-xl z-20">
        <BiShieldQuarter className='text-gray-600 w-12 h-12' />
        <h1 className="text-xl font-bold text-gray-800 my-2">Confirmation</h1>
        {
          errorMessage && <p className="text-red-400 py-2">{errorMessage}</p>
        }
        <p className=" mx-8 text-gray-600 my-3 ">
          Please enter the verification code sent to your phone number 
           <span className="mx-2 text-gray-700">
            {
              phoneNumber ? phoneNumber: null
            }
           </span>
          <div onClick={(e) => resendOtp()} className='text-blue-500 cursor-pointer'>
            Resend again
          </div>
        </p>

        <div className="absolute bottom-0 left-2/3 opacity-30 z-10">
          <img className="w-40 z-0" alt="pattern" src={dot} />
        </div>
        <Form
          className="w-3/4"
          name="vertical"
          layout="vertical"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className='flex'>
            <Form.Item
              type="text"
              name="digit1"
              className='w-8 mx-2 text-xl'

            >
              <Input className="rounded-sm" maxLength='1' minLength='1' />
            </Form.Item>
            <Form.Item
              type="text"
              name="digit2"
              className='w-8 mx-2 text-xl'

            >
              <Input className="rounded-sm" maxLength='1' minLength='1' />
            </Form.Item>
            <Form.Item
              type="text"
              name="digit3"
              className='w-8 mx-2 text-xl'

            >
              <Input className="rounded-sm" maxLength='1' minLength='1' />
            </Form.Item>
            <Form.Item
              type="text"
              name="digit4"
              className='w-8 mx-2 text-xl'

            >
              <Input className="rounded-sm" maxLength='1' minLength='1' />
            </Form.Item>
            <Form.Item
              type="text"
              name="digit5"
              className='w-8 mx-2 text-xl'

            >
              <Input className="rounded-sm" maxLength='1' minLength='1' />
            </Form.Item>
            <Form.Item
              type="text"
              name="digit6"
              className='w-8 mx-2 text-xl'

            >
              <Input className="rounded-sm" maxLength='1' minLength='1' />
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              className="w-full dark:border dark:border-gray-300"
              type="primary"
              htmlType="submit"
            >
              <span className="flex items-center">
                {loading && <Spin indicator={antIcon} className="mr-2" />}
                Confirm
              </span>
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="absolute hidden md:block top-64 -left-10 opacity-40">
        <img className="w-32" alt="pattern" src={dot} />
      </div>
    </div>
  )
}
