import { Button, Checkbox, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  NavigationKeyOnMenu,
  RecordMenus,
  RenderNavigationKeyOnMenu,
  displayMenuItems,
  getRecordMenuLabel,
} from "./common";
import { NodeTypes } from "../IVRDesignerScreen";
import KeyAssigningForm from "./KeyAssigningForm";
import { validateFileName } from "../../../_helpers/validateFileName";

const RecordForm = (props) => {
  const [form] = Form.useForm();
  const [validateRecord, setValidateRecord] = useState(false);
  const [hasRecordMenuAudio, setHasRecordMenuAudio] = useState(false);
  const [recordMenuList, setRecordMenuList] = useState([]);
  const [isTouched, setTouched] = useState(false);

  const _validateRecordMenu = () => {
    // if (hasRecordMenuAudio && RecordMenus.length !== recordMenuList.length) {
    //   return "All record menus need to be added";
    // }
    if (hasRecordMenuAudio && recordMenuList.length < 4) {
      return "At least 4 menus are required";
    }
  };

  useEffect(() => {
    if ((props.edit || props.viewOnly) && props.currentNode) {
      const currentNode = props.currentNode;
      form.setFieldValue("recordCmdAudio", currentNode.value.recordCmdAudio);
      form.setFieldValue(
        "recordCmdAudioScript",
        currentNode.value.recordCmdAudioScript
      );

      form.setFieldValue("recordMenuAudio", currentNode.value.recordMenuAudio);
      form.setFieldValue(
        "recordMenuAudioScript",
        currentNode.value.recordMenuAudioScript
      );

      form.setFieldValue(
        "confirmationAudio",
        currentNode.value.confirmationAudio
      );

      form.setFieldValue(
        "confirmationAudioScript",
        currentNode.value.confirmationAudioScript
      );
      if (currentNode.value.recordMenuAudio) {
        setHasRecordMenuAudio(true);
      }

      if (currentNode.value.menuKey) {
        form.setFieldValue("menuKey", currentNode.value.menuKey);
      }
      if (currentNode.value.nextId) {
        form.setFieldValue("nextId", currentNode.value.nextId);
      }

      setRecordMenuList(currentNode.value.recordMenuValues);
    }
  }, [props.edit, props.viewOnly]);

  const onRecordSubmitted = (values) => {
    if (_validateRecordMenu()) return;
   
    if(props.edit && props.parentNode && props.parentNode.value.type == NodeTypes.menu){
      const childrenValues = props.parentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.id != props.currentNode.key && item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }
    if(props.currentNode && props.currentNode.value.type == NodeTypes.menu){
      const childrenValues = props.currentNode.value.childrenValues
      for(let item of childrenValues){
        if(item.key == values.menuKey){
          message.error(`menu key ${values.menuKey} is already in use`)
          return 
        }
      }
    }

    let data = {};
    if (props.edit && props.currentNode) {
      data = props.currentNode.value;
    }
    data.recordCmdAudio = values.recordCmdAudio;
    data.recordCmdAudioScript = values.recordCmdAudioScript;

    data.recordMenuAudio = values.recordMenuAudio;
    data.recordMenuAudioScript = values.recordMenuAudioScript;

    data.confirmationAudio = values.confirmationAudio;
    data.confirmationAudioScript = values.confirmationAudioScript;

    data.type = NodeTypes.record;

    data.recordMenuValues = [];

    recordMenuList.forEach((recordMenuItem) => {
      data.recordMenuValues.push(recordMenuItem);
    });

    const keys = data.recordMenuValues.map((recordMenu) => {
      return recordMenu.key
    })
    data.recordMenu = `[${keys.join(",")}]`

    data.hasMenuOption = hasRecordMenuAudio;

    data.menuKey = values.menuKey;
    data.menuOption = "NEXT";
    data.nextId = values.nextId;

    const node = {
      key: Date.now(),
      value: data,
      parent: null,
      children: [],
    };

    if (props.edit && props.currentNode) {
      node.parent = props.currentNode.parent;
      node.children = props.currentNode.children;
      node.key = props.currentNode.key;
    }
    props.onSubmitHandler(node);
  };
  const onReset = () => {
    form.resetFields();
  };

  const renderMenuForm = () => {
    if (props.viewOnly) {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    if (!props.edit) {
      if (props.currentNode && props.currentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    } else {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    return null;
  };

  const onRemoveRecordMenu = (index) => {
    const _recordMenuList = [...recordMenuList];
    _recordMenuList.splice(index, 1);
    setRecordMenuList(_recordMenuList);
  };

  const onAddRecordMenuHandler = async () => {
    try {
      setValidateRecord(true);
      await form.validateFields(["key", "menuOption"]);

      const key = form.getFieldValue("key");
      const menuOption = form.getFieldValue("menuOption");
      const data = {
        id: Date.now(),
        key,
        value: menuOption,
      };
      //check if key not duplicated
      const index = recordMenuList.findIndex((value) => {
        return value.key == key;
      });
      if (index !== -1) return;
      //check if the value not duplicated
      const _index = recordMenuList.findIndex((value) => {
        return value.value == menuOption;
      });
      if (_index !== -1) return;
      setRecordMenuList([...recordMenuList, data]);
      form.setFieldValue("key", "");
      form.setFieldValue("menuOption", "");
    } catch (error) {}
  };
  const displayRecordMenus = () => {
    const title = "Add Sub-Menu Options";
    const menus = RecordMenus;
    const menuRules = [
      {
        required: true,
        message: "Menu Option is required",
      },
    ];
    const menuLabel = "Menu Option";
    const menuName = "menuOption";

    const keyRules = [
      {
        required: true,
        message: "Key is required",
      },
    ];
    const applyRules = validateRecord;
    const onAddHandler = onAddRecordMenuHandler;

    return (
      <KeyAssigningForm
        title={title}
        menus={menus}
        menuRules={menuRules}
        menuLabel={menuLabel}
        menuName={menuName}
        keyRules={keyRules}
        applyRules={applyRules}
        onAddHandler={onAddHandler}
        viewOnly={props.viewOnly}
      />
    );
  };

  return (
    <div className="mt-5">
      <Form
        className=""
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onRecordSubmitted}
      >
        <Form.Item
          name="recordCmdAudio"
          label="Record CMD Audio"
          rules={[
            {
              required: true,
              type: "string",
              message: "Record CMD Audio is required",
            },
            {
              validator: validateFileName,
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item
          name="recordCmdAudioScript"
          label="Record CMD Audio Script (optional)"
        >
          <Input.TextArea disabled={props.viewOnly} rows={5} />
        </Form.Item>

        <Form.Item
          name="confirmationAudio"
          label="Thank You Audio"
          rules={[
            {
              required: true,
              type: "string",
              message: "Confirmation Audio is required",
            },
            {
              validator: validateFileName,
            },
          ]}
        >
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <Form.Item
          name="confirmationAudioScript"
          label="Thank You Audio Script (optional)"
        >
          <Input.TextArea disabled={props.viewOnly} rows={5} />
        </Form.Item>
        {renderMenuForm()}
        <Form.Item name="nextId" label="Next ID (optional)">
          <Input disabled={props.viewOnly} />
        </Form.Item>
        <div className="flex justify-between">
          <Form.Item>
            <Checkbox
              disabled={props.viewOnly}
              checked={hasRecordMenuAudio}
              onChange={(e) => {
                if (!e.target.checked) {
                  setRecordMenuList([]);
                  form.setFieldValue("recordMenuAudio", "");
                }
                setHasRecordMenuAudio(e.target.checked);
              }}
            >
              Do you want to add record menu audio ?
            </Checkbox>
          </Form.Item>
        </div>
        {hasRecordMenuAudio && (
          <>
            <Form.Item
              name="recordMenuAudio"
              label="Record Menu Audio"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Record Menu Audio is required",
                },
                {
                  validator: validateFileName,
                },
              ]}
            >
              <Input disabled={props.viewOnly} />
            </Form.Item>
            <Form.Item
              name="recordMenuAudioScript"
              label="Record Menu Audio Script (optional)"
            >
              <Input.TextArea disabled={props.viewOnly} rows={5} />
            </Form.Item>
            {displayRecordMenus()}
            {displayMenuItems(
              recordMenuList,
              onRemoveRecordMenu,
              getRecordMenuLabel,
              props.viewOnly
            )}
            {isTouched && (
              <div className="text-red-500 my-3">{_validateRecordMenu()}</div>
            )}
          </>
        )}
        <Form.Item className="flex justify-end mt-5">
          {!props.viewOnly && (
            <Button
              onClick={() => {
                setValidateRecord(false);
                setTouched(true);
              }}
              className="bg-slate-700 text-white"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default RecordForm;
