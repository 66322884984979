import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Form, Input, Modal, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { isUnicode } from '../../_helpers/checkUnicode'

const ManageShortCodeModal = ({
    visible,
    handleOk,
    handleCancel,
    onFinishFailed,
    errorMessage,
    setAutoReply,
    setKeyBased,
    autoReply,
    keyBased,
    loading,
    shortCodeData
}) => {


    const [form] = Form.useForm()
    const [disable, setDisable] = React.useState(shortCodeData.autoReply)
    const [unicodeError, setUnicodeError] = useState("")
    const [messageValue,setMessageValue] = useState('')
    const [maxLength,setMaxLength] = useState(800)

    useEffect(() => {
        if(shortCodeData.autoReply){
            setDisable(false)
        }else{
            setDisable(true)
        }
    }, [])

    const handleDisable = (e) => {
        const checkd_value = e.target.checked
        if (checkd_value) {
            setDisable(false)
        }else{
            setDisable(true)
        }
    }


    const handleMessageChange = (e) =>{
        const isUni = isUnicode(e.target.value)
       if(isUni){
         setMaxLength(70 * 5)
       }else{
         setMaxLength(160 * 5)
       }
       setMessageValue(e.target.value)
     }
   

    return (
        <div className='w-full h-full'>
            <Modal
                title="Update Short code"
                open={visible}
                onOk={form.submit}
                onCancel={handleCancel}
                okText="Update"
                confirmLoading={loading}
                okButtonProps={{
                    style: { 
                        backgroundColor:"#131D34",
                        borderColor:"#131D34",
                        borderRadius:"10px",
                        color:"white"
                     }
                }}
            >
                <Form

                    name="basic"
                    layout="vertical"
                    onFinish={handleOk}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="flex-col justify-between"
                    form={form}
                    
                    initialValues={shortCodeData }
                >

                    <div className="flex flex-col w-full justify-center ">

                        {errorMessage && <span className="flex justify-center text-red-400 ">{errorMessage}</span>}
                        <Form.Item
                            name="value"
                            label="shortCode"
                        >
                            <Input placeholder="Short Code"
                            disabled={true}
                            />
                        </Form.Item>
                    
                    </div>
                    <div className="flex w-full my-1 ">
                        <Form.Item
                            layout="horizontal"
                            className=" mr-1"
                            valuePropName="checked"
                            name="autoReply"
                            label="Has Auto Reply?"
                        >
                            <Checkbox
                                onChange={(e) => handleDisable(e)}
                            />
                        </Form.Item>

                        <Form.Item
                            className="flex-1 ml-2"
                            name="autoReplyMessage"
                            label="Auto Reply Message"
                        >
                            <TextArea
                                disabled={disable}
                                placeholder="auto Reply Message"
                                type="text"
                                rows={4}
                                showCount 
                                maxLength={maxLength}
                                onChange={handleMessageChange}
                            />

                        </Form.Item>

                    </div>

                </Form>
            </Modal>
        </div>
    )
}

export default ManageShortCodeModal