import axiosDefault from './axiosDefault'

const API_URL = process.env.REACT_APP_API_URL
export const addCode = (body) => {
  return axiosDefault.post(API_URL + '/short_codes', body)
}

export const updateCode = (code, body) => {
  return axiosDefault.put(API_URL + `/short_codes/${code}`, body)
}

export const getCode = (code) => {
  return axiosDefault.get(API_URL + `/short_codes/${code}`)
}

export const assignCode = (body) => {
  return axiosDefault.post(API_URL + '/short_codes/assign', body)
}

export const getShortcodeForAdmin = async(id) => {
  return await axiosDefault.get(API_URL + `/short_codes/admin/${id}`)
}


export const updateSingleShortCode = (id,body) => {

  return axiosDefault.put(API_URL + `/short_codes/admin/${id}`,body)

}


export const getUserCodes = async(pagination, isActive) => {
  return await axiosDefault.get(API_URL + `/short_codes/user_short_codes?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}&${isActive.map((n,index)=>`isActive[${index}]=${n}`).join('&')}`)
}

export const getUserIvrCodes = async(pagination, isActive) => {
  return await axiosDefault.get(API_URL + `/short_codes/user_ivr_short_codes?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}&${isActive.map((n,index)=>`isActive[${index}]=${n}`).join('&')}`)
}


export const getActiveUserCodes = async() => {
  return await axiosDefault.get(API_URL + `/short_codes/active_user_short_codes`)
}

export const getUnshareableUserCodes = async() => {
  return await axiosDefault.get(API_URL + `/short_codes/unshareable_user_short_codes`)
}









export const getUserShortcodeForAdmin = async(pagination, userId) => {
  return await axiosDefault.get(API_URL + `/short_codes/user/${userId}/shortcodes?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
}

export const getUserKeysForAdmin = async(pagination, userId) => {
  return await axiosDefault.get(API_URL + `/outgoing_key/user/${userId}/manage_Key?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
}



export const getAllUserShortCodes = async(userId) => {
  return await axiosDefault.get(API_URL + `/short_codes?userId=${userId}`)
}

export const getAllShortcodeForAdmin = async(pagination) => {
  return await axiosDefault.get(API_URL + `/short_codes/allcodes/?pageIndex=${pagination.current}&pageSize=${pagination.pageSize}`)
}



export const deleteShortCode  = async(id) =>{
    return axiosDefault.delete(API_URL + `/short_codes/${id}`)
}

export const updateUserShortCode = (id, body) => {
  return axiosDefault.put(API_URL + `/short_codes/user_shortCode_update/${id}`, body)
}

export const deleteUserShortCode = (id) => {
  return axiosDefault.delete(API_URL + `/short_codes/user_shortCode_delete/${id}`)
}

export const updateUserShortCodeForAdmin = (body) => {
  return axiosDefault.put(API_URL + `/short_codes/user_shortCode_Status_update`,body)
}

export const updateUserKeyForAdmin = (body) => {
  return axiosDefault.put(API_URL + `/outgoing_key/key_isactive_update`,body)
}


export const handleKeyApprovement = (body) => {
  return axiosDefault.put(API_URL + `/outgoing_key/key_approve`,body)
}

export const handleKeyReject = (body) => {
  return axiosDefault.put(API_URL + `/outgoing_key/key_reject`,body)
}


export const handleKeySuspend = (body) => {
  return axiosDefault.put(API_URL + `/outgoing_key/key_suspend`,body)
}

