import { Form, Input, Button, message, Spin } from 'antd'
import React, { useState } from 'react'
import { BiShieldQuarter } from 'react-icons/bi'
import { useHistory, useLocation } from 'react-router-dom'
import dot from '../../assets/img/dots.svg'
import { accountApprovement, resendOTPCode, verifyOTPCode } from '../../services/userService'
import { LoadingOutlined } from '@ant-design/icons';


export default function UserApprovement() {

    const location = useLocation()
    const history = useHistory()

    const query = new URLSearchParams(location.search)
    const _phoneNumber = query.get('phoneNumber')
    const userId = query.get('id')
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)


    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;




    const onFinish = () => {

        history.push('/login')
    }



    return (
        <div className="min-h-screen py-8">
            <div className="bg-blue-50 w-full max-w-lg  flex flex-col items-center justify-center p-4 shadow-xl rounded-xl z-20">
                <BiShieldQuarter className='text-gray-600 w-12 h-12' />
                <h1 className="text-xl font-bold text-gray-800 my-2">Account Approvement</h1>

                {
                    errorMessage && <p className="text-red-400 py-2">{errorMessage}</p>
                }
               
                <h1>
                Welcome to YEGNATELE. A member of our team will get in touch with you and approve your account shortly.
                </h1>
               <Button onClick={onFinish} className='text-white bg-green-800 rounded-lg w-32 mt-4 hover:bg-green-800'>Ok</Button>
            </div>

            <div className="absolute hidden md:block top-64 -left-10 opacity-40">
                <img className="w-32" alt="pattern" src={dot} />
            </div>
        </div>
    )
}
