import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Form, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { NodeTypes } from "../IVRDesignerScreen";
import { RemoveIcon } from "./icons";
import KeyAssigningForm from "./KeyAssigningForm";
import {
  LanguageMenus,
  NavigationKeyOnMenu,
  RenderNavigationKeyOnMenu,
  displayMenuItems,
  getLanguageLabel,
} from "./common";
import { validateFileName } from "../../../_helpers/validateFileName";

const GreetingForm = (props) => {
  const [form] = Form.useForm();

  const [languages, setLanguages] = useState([]);
  const [validateLanguge, setValidateLanguage] = useState(false);
  const [isTouched, setTouched] = useState(false);

  const onFinish = (values) => {
    if (props.isMultiLingual && getLanguageLengthError()) return;

    let data = {};
    let _keyId = Date.now();
    if (props.edit && props.currentNode) {
      data = props.currentNode.value;
      _keyId = props.currentNode.key;
    }
    data.type = NodeTypes.greeting;
    data.isWelcomeFirst = values.isWelcomeFirst;
    data.title = values.title;

    data.languageMenu = [];
    data.languageMenuValues = [];
    data.greetingAudio = values.greetingAudio;
    data.greetingAudioScript = values.greetingAudioScript;

    data.menuKey = values.menuKey;
    data.menuOption = "NEXT";

    languages.forEach((languge) => {
      data.languageMenu.push(languge.key);
      data.languageMenuValues.push(languge);
    });
    data.languageMenu = `[${data.languageMenu}]`
    data.languageMenuAudio = values.languageMenuAudio;
    data.languageMenuScript = values.languageMenuScript;

    const node = {
      key: _keyId,
      value: data,
      parent: null,
      children: [],
    };
    props.onSubmitHandler(node);
  };
  const onReset = () => {
    form.resetFields();
    setLanguages([]);
  };

  const getLanguageLengthError = () => {
    if (languages.length <= 1) return "Atleast two languages are required";
  };

  useEffect(() => {
    console.log(props.currentNode);
    if ((props.edit || props.viewOnly) && props.currentNode) {
      const currentNode = props.currentNode;
      // const isMultiLingual =
      //   currentNode.value.languageMenuValues &&
      //   currentNode.value.languageMenuValues.length > 0;
      // props.setIsMultiLingual(isMultiLingual);
      form.setFieldValue("isWelcomeFirst", currentNode.value.isWelcomeFirst);
      form.setFieldValue("title", currentNode.value.title);
      form.setFieldValue(
        "languageMenuAudio",
        currentNode.value.languageMenuAudio
      );
      form.setFieldValue(
        "languageMenuScript",
        currentNode.value.languageMenuScript
      );
      form.setFieldValue("greetingAudio", currentNode.value.greetingAudio);
      form.setFieldValue(
        "greetingAudioScript",
        currentNode.value.greetingAudioScript
      );

      // form.setFieldValue("multiLingual", isMultiLingual);
      if (props.isMultiLingual) {
        setLanguages(currentNode.value.languageMenuValues);
      }
      if (currentNode.value.nextId) {
        form.setFieldValue("nextId", currentNode.value.nextId);
      }
    }
  }, [props.edit, props.viewOnly]);
  const onAddLanguageHandler = async () => {
    try {
      setValidateLanguage(true);
      await form.validateFields(["key", "language"]);
      const key = form.getFieldValue("key");
      const language = form.getFieldValue("language");
      const data = {
        id: Date.now().toString(),
        key,
        value: language,
      };
      //check if key not duplicated
      const index = languages.findIndex((value) => {
        return value.key == key;
      });
      if (index !== -1) return;
      //check if value not duplicated
      const _index = languages.findIndex((value) => {
        return value.value == language;
      });
      if (_index !== -1) return;

      setLanguages([...languages, data]);
      form.setFieldValue("key", "");
      form.setFieldValue("language", "");
    } catch (error) {}
  };

  const onRemoveLanguageHandler = (index) => {
    const _languages = [...languages];
    _languages.splice(index, 1);
    console.log(_languages);
    console.log(index);
    setLanguages(_languages);
  };

  const displayLanguageForm = () => {
    const title = "";
    const menus = LanguageMenus;
    const menuRules = [
      {
        required: true,
        message: "Language is required",
      },
    ];
    const menuLabel = "Language";
    const menuName = "language";

    const keyRules = [
      {
        required: true,
        message: "Key is required",
      },
    ];
    const applyRules = validateLanguge;
    const onAddHandler = onAddLanguageHandler;

    return (
      <KeyAssigningForm
        title={title}
        menus={menus}
        menuRules={menuRules}
        menuLabel={menuLabel}
        menuName={menuName}
        keyRules={keyRules}
        applyRules={applyRules}
        onAddHandler={onAddHandler}
        viewOnly={props.viewOnly}
      />
    );
  };
  const renderMenuForm = () => {
    if (props.viewOnly) {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    if (!props.edit) {
      if (props.currentNode && props.currentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    } else {
      if (props.parentNode && props.parentNode.value.type == NodeTypes.menu) {
        return <RenderNavigationKeyOnMenu viewOnly={props.viewOnly} />;
      }
    }
    return null;
  };

  return (
    <Form
      className=""
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      form={form}
      onFinish={onFinish}
    >
      <div className="my-5 font-bold uppercase" />
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            type: "string",
            message: "Title is required",
          },
        ]}
      >
        <Input disabled={props.viewOnly} />
      </Form.Item>
      <Form.Item
        name="greetingAudio"
        label="Greeting Audio"
        rules={[
          {
            required: true,
            type: "string",
            message: "Greeting audio is required",
          },
          {
            validator: validateFileName,
          },
        ]}
      >
        <Input disabled={props.viewOnly} />
      </Form.Item>
      <Form.Item
        name="greetingAudioScript"
        label="Greeting Audio Script (optional)"
      >
        <Input.TextArea disabled={props.viewOnly} rows={5} />
      </Form.Item>

      <div className="flex justify-between">
        <Form.Item
          name="isWelcomeFirst"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox disabled={props.viewOnly}>Welcome First ?</Checkbox>
        </Form.Item>
        <Form.Item
          name="multiLingual"
          valuePropName="checked"
          initialValue={props.isMultiLingual}
        >
          <Checkbox
            disabled
            onChange={(e) => {
              props.setIsMultilingual(e.target.checked);
            }}
          >
            Multi Lingual ?
          </Checkbox>
        </Form.Item>
      </div>
      {props.isMultiLingual && (
        <>
          <div className="flex justify-between items-center mb-5">
            <div className="font-bold uppercase">Manage Languages</div>
          </div>
          <Divider />

          <div>
            <Form.Item
              name="languageMenuAudio"
              label="Language Menu Audio"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Language Menu Audio is required",
                },
                {
                  validator: validateFileName,
                },
              ]}
            >
              <Input disabled={props.viewOnly} />
            </Form.Item>
            <Form.Item
              name="languageMenuScript"
              label="Language Menu Script (optional)"
            >
              <Input.TextArea disabled={props.viewOnly} rows={5} />
            </Form.Item>
          </div>
        </>
      )}
      {props.isMultiLingual && displayLanguageForm()}
      {displayMenuItems(
        languages,
        onRemoveLanguageHandler,
        getLanguageLabel,
        props.viewOnly
      )}

      {renderMenuForm()}
      {props.isMultiLingual && isTouched && (
        <div className="text-red-500 my-3 ">{getLanguageLengthError()}</div>
      )}
      <Form.Item className="flex justify-end mt-5">
        {!props.viewOnly && (
          <Button
            disabled={props.viewOnly}
            onClick={() => {
              setTouched(true);
              setValidateLanguage(false);
            }}
            className="bg-slate-700 text-white"
            htmlType="submit"
          >
            Submit
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default GreetingForm;
